import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUsers } from '../../redux/users/actions';
import Button from '../../shared/components/Button';
import DisplayError from '../../shared/components/DisplayError';
import Loader from '../../shared/components/Loader';
import { User, UserRole } from '../../shared/ts/User';
import { RootState } from '../../store';

interface PermitAccessProps {
  weddingId: number;
}

const PermitAccess: React.FC<PermitAccessProps> = ({ weddingId }) => {
  const dispatch = useDispatch();
  const [updateError, setUpdateError] = useState<string>('');
  const [updatingId, setUpdatingId] = useState<number | null>(null!);

  const { isLoading, users, error } = useSelector(
    (state: RootState) => state.users
  );

  const togglePermission = async (user: User) => {
    let newPermittedWeddings;
    let index;
    if (user.permittedWeddings?.includes(weddingId)) {
      index = user.permittedWeddings.indexOf(weddingId);
      newPermittedWeddings = [...user.permittedWeddings];
      user.permittedWeddings.splice(index, 1);
      newPermittedWeddings.splice(index, 1);
    } else {
      newPermittedWeddings = [...user.permittedWeddings, weddingId];
      user.permittedWeddings.push(weddingId);
    }

    try {
      setUpdatingId(user.id);
      await axios.patch(`/users/${user.id}`, {
        permittedWeddings: newPermittedWeddings,
      });
      setUpdatingId(null);
    } catch (error) {
      setUpdatingId(null);
      user.permittedWeddings?.includes(weddingId)
        ? user.permittedWeddings.splice(index, 1)
        : user.permittedWeddings.push(weddingId);

      setUpdateError(error);
    }
  };

  useEffect(() => {
    dispatch(getUsers(UserRole.EMPLOYEE));
  }, [dispatch]);

  return (
    <StyledAccess>
      {isLoading && <Loader />}
      {error && <DisplayError error={error} />}
      {users &&
        !isLoading &&
        users.map((user) => (
          <Button
            text={user.username}
            key={user.id}
            color='secDark'
            disabled={updatingId === user.id}
            bgColor={
              user.permittedWeddings?.includes(weddingId) ? 'arrived' : 'third'
            }
            handleClick={() => togglePermission(user)}
          />
        ))}
      {updateError && <DisplayError error={updateError} />}
    </StyledAccess>
  );
};

const StyledAccess = styled.div`
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;

  button {
    margin-top: 0;
  }
`;

export default PermitAccess;
