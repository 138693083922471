import React from 'react';
import { WeddingStatus } from '../../shared/ts/Wedding';
import { v4 as uuidv4 } from 'uuid';

interface SelectStatusProps {
  status: string | undefined | WeddingStatus;
  setStatus:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | ((status: string) => void);
  text: string;
}

const SelectStatus: React.FC<SelectStatusProps> = ({
  status,
  setStatus,
  text,
}) => {
  const options = [
    { value: WeddingStatus.unbegun, label: 'לא התחיל' },
    { value: WeddingStatus.hasStarted, label: 'פעיל' },
    { value: WeddingStatus.isFinished, label: 'נגמר' },
  ];

  return (
    <>
      <select onChange={(e) => setStatus(e.target.value)} value={status}>
        <option hidden>{text}</option>
        {options.map((option) => (
          <option value={option.value} key={uuidv4()}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectStatus;
