import React, { useState } from 'react';
import styled from 'styled-components';
import { colorsVariables } from '../GlobalStyles';
import ManageGuests from '../OngoingWeddings/Manage/ManageGuests';
import useCountTotalArrival from '../shared/hooks/useCountTotalArrival';
import { Wedding } from '../shared/ts/Wedding';
import Chart from 'react-apexcharts';

interface WeddingSummaryProps {
  wedding: Wedding;
}

const WeddingSummary: React.FC<WeddingSummaryProps> = ({ wedding }) => {
  const [totalArrival, setTotalArrival] = useState<number>(0);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);
  useCountTotalArrival(
    wedding.id!,
    setTotalArrival,
    shouldUpdate,
    setShouldUpdate
  );

  const donutData = {
    series: [totalArrival, wedding.committedToVenue - totalArrival],

    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['הגיעו', 'לא הגיעו'],
      legend: { position: 'left', offsetX: 1, fontWeight: 'bold' },
      colors: [colorsVariables.arrived, colorsVariables.error],
      fill: {
        colors: [colorsVariables.arrived, colorsVariables.error],
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.8,
          },
        },
      },
      responsive: [
        {
          breakpoint: 350,
          options: {
            chart: {
              width: 280,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };
  return (
    <StyledWeddingSummary>
      <p>
        <span> הגעה סופית משוערת:</span> {totalArrival} מתוך{' '}
        {wedding.committedToVenue}
      </p>

      {totalArrival > wedding.committedToVenue && (
        <>
          <p>
            לאירוע הגיעו {totalArrival - wedding.committedToVenue} אורחים יותר
            מההתחייבות הסופית לאולם
          </p>
        </>
      )}
      {totalArrival > 0 && (
        <div id='chart'>
          <Chart
            options={donutData.options}
            series={donutData.series}
            type='pie'
            width={340}
          />
        </div>
      )}
      <ManageGuests
        committedToVenue={wedding.committedToVenue}
        usage='wedding-summary'
        linkTo={`/couple/wedding/${wedding.id}`}
      />
    </StyledWeddingSummary>
  );
};

const StyledWeddingSummary = styled.div`
  margin-top: 1rem;

  p span {
    font-weight: bold;
  }

  .apexcharts-legend-text {
    margin-right: 0.3rem;
  }
`;

export default WeddingSummary;
