import React from 'react';
import styled from 'styled-components';
import { Wedding } from '../../ts/Wedding';

interface WeddingInfoProps {
  wedding: Wedding;
  usage?: string;
}

const WeddingInfo: React.FC<WeddingInfoProps> = ({ wedding, usage }) => {
  return (
    <InfoWrapper>
      <Info>
        <p>
          <span>מיקום:</span> {wedding.location}
        </p>
        <p>
          <span>תאריך:</span> {wedding.date}
        </p>
      </Info>
      <Info>
        <p>
          <span>כמות אורחים משוערת:</span> {wedding.numberOfGuests}
        </p>
        <p>
          <span>התחייבות מול האולם:</span> {wedding.committedToVenue}
        </p>
      </Info>
    </InfoWrapper>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  width: 90%;

  div + div {
    margin-right: 1rem;
  }

  p {
    white-space: pre-line;

    span {
      font-weight: bold;
    }
  }

  @media (min-width: 900px) {
    max-width: 40%;
    p {
      white-space: nowrap;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default WeddingInfo;
