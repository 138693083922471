import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ManageTables from '../../shared/components/Tables/ManageTables';

const Tables: React.FC = () => {
  const { weddingId } = useParams<{ weddingId: string }>();

  return (
    <StyledOngoingWeddingTables>
      <h2>ניהול שולחנות:</h2>
      <ManageTables
        weddingId={+weddingId}
        linkTo={`/ongoing-weddings/manage/${weddingId}/tables`}
      />
    </StyledOngoingWeddingTables>
  );
};

const StyledOngoingWeddingTables = styled.div`
  margin-top: 2rem;
  h2 {
    border-bottom: 1px solid black;
    padding-bottom: 0.3rem;
  }
`;

export default Tables;
