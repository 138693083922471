import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { getTables } from '../../redux/tables/actions';
import DisplayError from '../../shared/components/DisplayError';
import Loader from '../../shared/components/Loader';
import { RootState } from '../../store';
import TableGuestsCard from './TableGuestsCard';
import {
  breakpointColumnsObj,
  colorsVariables,
  StyledLink,
} from '../../GlobalStyles';
import { LoopableTableFilters } from '../../shared/ts/Table';
import FilterTables from '../../shared/components/Tables/FilterTables';
import useTableFilter from '../../shared/hooks/useTableFilter';
import Button from '../../shared/components/Button';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import useCountTotalArrival from '../../shared/hooks/useCountTotalArrival';
import Pagination from '../../shared/components/Pagination';
import Masonry from 'react-masonry-css';

interface Props {
  committedToVenue: number;
  usage: 'wedding-summary' | 'manage-wedding';
  linkTo: string;
}

type StyleProps = {
  allSeatsOccupied: boolean;
};

const ManageGuests: React.FC<Props> = ({ committedToVenue, usage, linkTo }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { weddingId } = useParams<{ weddingId: string }>();
  const { number, name, guest, relation, page } = useParams<{
    number: string;
    name: string;
    guest: string;
    relation: string;
    page: string;
  }>();

  const { isLoading, error, tables, pagination } = useSelector(
    (state: RootState) => state.tables
  );
  useIsTokenExpired(error);

  const [filters, setFilters] = useTableFilter();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [totalArrival, setTotalArrival] = useState<number>(0);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);

  useCountTotalArrival(
    +weddingId,
    setTotalArrival,
    shouldUpdate,
    setShouldUpdate
  );

  const getFilteredTables = useCallback(
    (e?: FormEvent): void => {
      e?.preventDefault();
      if (filters.number && !/^[0-9]*$/.test(filters.number)) {
        return;
      }
      history.push(
        `${linkTo}/number=${filters.number}/name=${filters.name}/guest=${filters.guestName}/relation=${filters.relation}/page=1`
      );
    },
    [history, filters, linkTo]
  );

  const resetSearch = (setFilters: LoopableTableFilters): void => {
    for (let value in setFilters) {
      setFilters[value]('');
    }
    history.push(`${linkTo}/number=/name=/guest=/relation=/page=1`);
  };

  const getUpdatedTotalArrival = (): void => {
    setShouldUpdate(true);
  };

  useEffect(() => {
    dispatch(getTables(+weddingId, number, name, relation, guest, +page));
  }, [dispatch, number, name, relation, guest, page, weddingId]);

  return (
    <StyledManageGuests>
      {usage === 'manage-wedding' && (
        <>
          <h2>ניהול אורחים:</h2>
          <StyledTotalArrival
            allSeatsOccupied={totalArrival > committedToVenue}
          >
            סה"כ תפוסה: {committedToVenue} / {totalArrival}
            {totalArrival > committedToVenue && (
              <p>צפי רזרבה: {totalArrival - committedToVenue} כסאות</p>
            )}
          </StyledTotalArrival>
          <StyledLink onClick={getUpdatedTotalArrival}>עדכון תפוסה</StyledLink>
        </>
      )}
      <Button
        text='סינון אורחים/שולחנות'
        color='mainDark'
        bgColor='secDark'
        handleClick={() => setIsFiltering(!isFiltering)}
      />
      {isFiltering && (
        <FilterTables
          filters={filters}
          setFilters={setFilters}
          getFilteredTables={getFilteredTables}
          resetSearch={resetSearch}
        />
      )}
      {isLoading ? (
        <Loader message='טוען אורחים...' />
      ) : error ? (
        <DisplayError error={error} />
      ) : !isLoading && tables ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'
        >
          {tables.length >= 1
            ? tables.map((table, i) => (
                <TableGuestsCard
                  table={table}
                  key={i}
                  keyword={filters.guestName}
                  usage={usage}
                />
              ))
            : tables.length === 0 && (
                <StyledNotFound>
                  לא נמצאו שולחנות תחת החיפוש הנוכחי
                  <StyledLink onClick={() => resetSearch(setFilters)}>
                    איפוס חיפוש
                  </StyledLink>
                </StyledNotFound>
              )}
        </Masonry>
      ) : (
        ''
      )}
      {pagination && pagination.totalPages > 1 && (
        <Pagination
          totalPages={pagination.totalPages}
          link={`${linkTo}/number=${filters.number}/name=${filters.name}/guest=${filters.guestName}/relation=${filters.relation}/`}
        />
      )}
    </StyledManageGuests>
  );
};

const StyledManageGuests = styled.div`
  h2 {
    border-bottom: 1px solid black;
    padding-bottom: 0.3rem;
  }

  button {
    margin-top: 0.5rem;
  }
`;

const StyledTotalArrival = styled.h3<StyleProps>`
  background: ${(props) => props.allSeatsOccupied && colorsVariables.error};
  color: ${(props) => props.allSeatsOccupied && colorsVariables.white};
  font-weight: ${(props) => props.allSeatsOccupied && 'bold'};
  width: max-content;
  margin-top: 0.3rem;
  padding: 0 0.2rem;
  white-space: nowrap;
`;

const StyledNotFound = styled.h3`
  margin-top: 0.5rem;
`;

export default ManageGuests;
