import React from 'react';
import Masonry from 'react-masonry-css';
import { breakpointColumnsObj } from '../../../GlobalStyles';
import { Table, TableInfo } from '../../ts/Table';
import Loader from '../Loader';
import TableCard from './Table';

interface TablesProps {
  tables: Table[];
  isLoadingTables: boolean;
  editHandler: (tableId: number, editInfo: TableInfo) => void;
  tableEditPending: boolean;
  tableEditError: string;
  deleteHandler: (tableId: number) => void;
  tableDeletePending: boolean;
  tableDeleteError: string;
}

const Tables: React.FC<TablesProps> = ({
  tables,
  isLoadingTables,
  editHandler,
  tableEditPending,
  tableEditError,
  deleteHandler,
  tableDeletePending,
  tableDeleteError,
}) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className='my-masonry-grid'
      columnClassName='my-masonry-grid_column'
    >
      {isLoadingTables ? (
        <Loader message='טוען שולחנות...' />
      ) : tables && !isLoadingTables ? (
        tables.map((table, i) => (
          <TableCard
            table={table}
            deleteHandler={deleteHandler}
            tableDeletePending={tableDeletePending}
            editHandler={editHandler}
            tableEditPending={tableEditPending}
            tableEditError={tableEditError}
            tableDeleteError={tableDeleteError}
            key={i}
          />
        ))
      ) : (
        ''
      )}
    </Masonry>
  );
};

export default Tables;
