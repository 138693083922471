import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Wedding } from '../ts/Wedding';

export default function useEmployeeAuth(wedding: Wedding): boolean | void {
  const history = useHistory();

  const checkIsWeddingStarted = useCallback((): boolean => {
    return wedding.status === 'HAS_STARTED';
  }, [wedding.status]);

  useEffect(() => {
    if (wedding?.status) {
      if (checkIsWeddingStarted()) {
        return;
      } else {
        history.replace('/');
      }
    }
  }, [history, wedding, checkIsWeddingStarted]);
}
