import { Table } from '../ts/Table';
import calculateTakenSeats from './calculateTakenSeats';

export default function calculateWeddingGuestsArrival(
  weddingTables: Table[] | undefined
): number {
  if (weddingTables) {
    let sum: number = 0;

    for (let table of weddingTables) {
      sum += calculateTakenSeats(table.guests);
    }

    return sum;
  } else return 0;
}
