import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SelectRelationProps {
  relation: string | undefined;
  setRelation: React.Dispatch<React.SetStateAction<string | undefined>>;
  text: string | undefined;
  isFiltering?: boolean;
}

const SelectRelation: React.FC<SelectRelationProps> = ({
  relation,
  setRelation,
  text,
  isFiltering,
}) => {
  const options = [
    { value: 'צד חתן', label: 'צד חתן' },
    { value: 'צד כלה', label: 'צד כלה' },
    { value: 'משותף', label: 'משותף' },
  ];
  const checkIsFilters = () => {
    if (isFiltering) options.unshift({ value: '', label: 'הכל' });
  };

  checkIsFilters();

  return (
    <>
      <select onChange={(e) => setRelation(e.target.value)} value={relation}>
        <option hidden>{text}</option>
        {options.map((option) => (
          <option value={option.value} key={uuidv4()}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectRelation;
