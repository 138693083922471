import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { postWedding } from '../../redux/weddings/actions';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  GlobalPageInit,
  StyledLongForm,
  StyledLink,
  StyledInputs,
  fadeIn,
} from '../../GlobalStyles';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { User, UserRole } from '../../shared/ts/User';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import useScrollTop from '../../shared/hooks/useScrollTop';
import {
  useWeddingErrors,
  useWeddingInfo,
} from '../../shared/hooks/useWedding';
import {
  validateForm,
  clearFormInputs,
  areInputsValid,
} from '../../shared/helpers/WeddingValidation';
import { Inputs } from './Inputs';
import Input from '../../shared/components/Input';
import Button from '../../shared/components/Button';
import DisplayError from '../../shared/components/DisplayError';

const NewWedding: React.FC = () => {
  useAuthorizedRoles([UserRole.ADMIN]);
  useScrollTop();
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null!);

  const [users, setUsers] = useState<User[]>([]);
  const [usersError, setUsersError] = useState<string>('');
  useIsTokenExpired(usersError);

  const [newWedding, setNewWedding] = useWeddingInfo();
  const [newWeddingErrors, setNewWeddingErrors] = useWeddingErrors();

  const [postedWeddingCouple, setPostedWeddingCouple] = useState<string>('');
  const [postedWeddingId, setPostedWeddingId] = useState<
    number | null | undefined
  >(null!);

  const { postedWedding, isLoading, error, success } = useSelector(
    (state: RootState) => state.weddingPost
  );
  useIsTokenExpired(error);

  const getCouplesWithNoWedding = useCallback(async () => {
    try {
      const { data } = await axios.get('/users/unassigned-wedding');
      setUsers(data);
    } catch (error) {
      setUsersError(error);
    }
  }, []);

  const clearForm = useCallback((): void => {
    clearFormInputs(setNewWedding);
    formRef.current.reset();
  }, [setNewWedding]);

  const postWeddingHandler = (e: FormEvent): void => {
    e.preventDefault();
    console.log(newWedding.remarks);
    if (areInputsValid(newWeddingErrors, newWedding)) {
      dispatch(postWedding(newWedding));
    }
  };

  useEffect(() => {
    getCouplesWithNoWedding();
  }, [getCouplesWithNoWedding]);

  useEffect(() => {
    validateForm(newWedding, setNewWeddingErrors);
  }, [newWedding, setNewWeddingErrors]);

  useEffect(() => {
    if (success) {
      setPostedWeddingCouple(postedWedding?.couple);
      setPostedWeddingId(postedWedding?.id);
      clearForm();
      getCouplesWithNoWedding();
      dispatch({ type: 'WEDDING_POST_RESET' });
      window.scrollTo(0, 0);
    }
  }, [success, clearForm, dispatch, getCouplesWithNoWedding, postedWedding]);

  return (
    <StyledNewWedding>
      <StyledLongForm onSubmit={postWeddingHandler} ref={formRef}>
        <h2>יצירת אירוע חדש</h2>
        {postedWeddingCouple && (
          <PostedWeddingLink>
            <Link
              to={`/couple/wedding/${postedWeddingId}/number=/name=/guest=/relation=/page=1`}
            >
              <p>האירוע של {postedWeddingCouple} נוצר בהצלחה</p>
              <StyledLink>מעבר לאירוע</StyledLink>
            </Link>
          </PostedWeddingLink>
        )}
        <StyledInputs>
          {Inputs(newWedding, setNewWedding).map((input, i) => (
            <Input
              label={input.label}
              placeholder={input.placeholder}
              onChange={input.onChange}
              value={input.value}
              required={input.required}
              type={input.type || 'text'}
              key={i}
            />
          ))}
          <textarea
            onChange={(e) => setNewWedding.setRemarks(e.target.value)}
            placeholder='הערות'
          />
          {users.length >= 1 ? (
            <>
              <select
                onChange={(e) => setNewWedding.setUserId(+e.target.value)}
              >
                <option hidden>המשתמש של הזוג</option>
                {users.map((user) => (
                  <option value={user.id} key={uuidv4()}>
                    {user.username}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <DisplayError error='נא ליצור משתמש לפני יצירת האירוע' />
          )}
          {newWeddingErrors &&
            newWeddingErrors
              .filter((error) => error)
              .map((error) => <DisplayError error={error} key={uuidv4()} />)}
          {error && <DisplayError error={error} />}
          {usersError && <DisplayError error={usersError} />}
        </StyledInputs>
        <Button
          text={isLoading ? 'יוצר אירוע...' : 'יצירת אירוע'}
          color='mainDark'
          bgColor='secDark'
          disabled={isLoading}
          type='submit'
        />
      </StyledLongForm>
    </StyledNewWedding>
  );
};

const StyledNewWedding = styled(GlobalPageInit)`
  @media (min-width: 600px) {
    h2 {
      text-align: center;
    }
  }
`;

const PostedWeddingLink = styled.div`
  margin-top: 0.5rem;
  margin: 0 auto;
  border: 1px solid black;
  padding: 1rem;
  animation: ${fadeIn};
  animation-duration: 1s;
  p {
    white-space: nowrap;
  }
`;

export default NewWedding;
