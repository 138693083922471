import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, deleteUser } from '../../redux/users/actions';
import { RootState } from '../../store';
import styled from 'styled-components';
import { CardsWrapper, GlobalPageInit, StyledLink } from '../../GlobalStyles';
import { UserRole } from '../../shared/ts/User';
import useScrollTop from '../../shared/hooks/useScrollTop';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';

import Loader from '../../shared/components/Loader';
import Input from '../../shared/components/Input';
import Button from '../../shared/components/Button';
import User from './User';
import DisplayError from '../../shared/components/DisplayError';
import SelectRole from './SelectRole';
import { useHistory, useParams } from 'react-router';
import Pagination from '../../shared/components/Pagination';

const Users: React.FC = () => {
  const { pageNumber, name, role } = useParams<{
    pageNumber: string;
    name: string;
    role: string;
  }>();

  const loggedUser = useAuthorizedRoles([UserRole.ADMIN]);
  useScrollTop();
  const dispatch = useDispatch();
  const history = useHistory();
  const filterRef = useRef<HTMLFormElement>(null!);

  const { isLoading, users, error, pagination } = useSelector(
    (state: RootState) => state.users
  );
  useIsTokenExpired(error);

  const {
    isLoading: isDeletionPending,
    error: deletionError,
    success: deletionSuccess,
  } = useSelector((state: RootState) => state.userDelete);
  useIsTokenExpired(deletionError);

  const [roleFilter, setRoleFilter] = useState<string | undefined>(role || '')!;
  const [nameFilter, setNameFilter] = useState<string | undefined | UserRole>(
    name || ''
  );
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const filterSearch = useCallback((): void => {
    history.push(`/admin/users/name=${nameFilter}/role=${roleFilter}/page=1`);
  }, [history, nameFilter, roleFilter]);

  const resetSearch = () => {
    setRoleFilter('');
    setNameFilter('');
    filterRef?.current?.reset();
    history.push(`/admin/users/name=/role=/page=1`);
  };

  const userDeleteHandler = (userId: number): void => {
    if (userId !== loggedUser.id) {
      dispatch(deleteUser(userId));
    }
  };

  useEffect(() => {
    dispatch(getUsers(role, name, +pageNumber, 35));
  }, [dispatch, role, name, pageNumber]);

  useEffect(() => {
    if (deletionSuccess) {
      dispatch({ type: 'USER_DELETE_RESET' });
      dispatch(getUsers(role, name, +pageNumber));
    }
  }, [dispatch, deletionSuccess, filterSearch, role, name, pageNumber]);

  return (
    <StyledUsersPage>
      {isLoading ? (
        isLoading && <Loader size={80} message='טוען משתמשים...' />
      ) : !isLoading && users ? (
        <>
          <ButtonWrapper>
            <Button
              text={
                isFiltering ? 'הסתרת אפשרויות סינון' : 'הצגת אפשרויות סינון'
              }
              color='secDark'
              bgColor='mainDark'
              handleClick={() => setIsFiltering(!isFiltering)}
            />
          </ButtonWrapper>
          {isFiltering && (
            <Filters ref={filterRef} onSubmit={filterSearch}>
              <Input
                label='סינון לפי שם'
                onChange={setNameFilter}
                placeholder='שם משתמש'
                value={nameFilter}
              />
              <SelectRole
                role={roleFilter}
                setRole={setRoleFilter}
                text='סינון לפי תפקיד'
              />
              <Button
                text='חיפוש'
                handleClick={filterSearch}
                color='mainDark'
                bgColor='secDark'
                type='submit'
              />
              <StyledLink onClick={resetSearch}>איפוס סינון</StyledLink>
            </Filters>
          )}

          <StyledUsers>
            {deletionError && <DisplayError error={deletionError} />}
            {error ? (
              <DisplayError error={error} />
            ) : users.length >= 1 ? (
              users.map((user, i) => (
                <User
                  user={user}
                  userDeleteHandler={userDeleteHandler}
                  isDeletionPending={isDeletionPending}
                  key={i}
                />
              ))
            ) : (
              users.length === 0 &&
              !isLoading && (
                <h3>
                  לא נמצאו משתמשים תחת הסינון הנוכחי
                  <StyledLink onClick={resetSearch}>איפוס סינון</StyledLink>
                </h3>
              )
            )}
          </StyledUsers>
          {pagination.totalPages && pagination.totalPages > 1 && (
            <Pagination
              totalPages={pagination.totalPages}
              link={`/admin/users/name=${nameFilter}/role=${roleFilter}/`}
            />
          )}
        </>
      ) : error ? (
        <DisplayError error={error} />
      ) : (
        ''
      )}
    </StyledUsersPage>
  );
};

const StyledUsersPage = styled(GlobalPageInit)``;

const Filters = styled.form`
  display: flex;
  flex-direction: column;
  width: max-content;

  @media (min-width: 600px) {
    margin: 0 auto;
  }

  div + div {
    margin-top: 0.5rem;
  }

  select {
    margin-top: 0.5rem;
  }
  button {
    margin-top: 0.5rem;
  }
`;

const StyledUsers = styled(CardsWrapper)`
  margin-top: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 1rem 0 0.3rem 0;

  button {
    @media (min-width: 600px) {
      margin: 0 auto;
    }
  }
`;

export default Users;
