import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export default function useIsTokenExpired(errorMessage: string) {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (errorMessage !== 'פג תוקף ההתחברות. נא להתחבר מחדש') {
      return;
    } else {
      history.push('/login');
      window.localStorage.removeItem('loggedUser');
      dispatch({ type: 'USER_LOGIN_RESET' });
    }
  }, [errorMessage, dispatch, history]);
}
