import React, { useState } from 'react';
import styled from 'styled-components';
import { cssVariables } from '../../../GlobalStyles';
import { Table, TableInfo } from '../../ts/Table';
import { useTableErrors, useTableInfo } from '../../hooks/useTable';
import Button from '../Button';
import EditTable from './EditTable';
import { FaArrowAltCircleDown, FaEdit, FaTrash } from 'react-icons/fa';
import DisplayError from '../DisplayError';
import DeleteConfirmation from '../DeleteConfirmation';
import Guests from '../Guests/Guests';
import calculateTotalGuests from '../../helpers/calculateTotalGuests';
import { parseTableNumber } from '../../helpers/parseTableNumber';

interface TableProps {
  table: Table;
  editHandler: (tableId: number, editInfo: TableInfo) => void;
  tableEditPending: boolean;
  tableEditError: string;
  deleteHandler: (tableId: number) => void;
  tableDeletePending: boolean;
  tableDeleteError: string;
}

type TableStyleProps = {
  isWatchingGuests: boolean;
};

const TableCard: React.FC<TableProps> = ({
  table,
  editHandler,
  tableEditPending,
  tableEditError,
  deleteHandler,
  tableDeletePending,
  tableDeleteError,
}) => {
  const [isEditingTable, setIsEditingTable] = useState<boolean>(false);
  const [isAwaitingDeletion, setIsAwaitingDeletion] = useState<boolean>(false);
  const [tableInfo, setTableInfo] = useTableInfo(table);
  const [tableInfoErrors, setTableInfoErrors] = useTableErrors();
  const [isWatchingGuests, setIsWatchingGuests] = useState<boolean>(false);
  const [totalGuests, setTotalGuests] = useState<number>(
    calculateTotalGuests(table.guests)
  );

  return (
    <StyledTable>
      <StyledTableInfo>
        {isEditingTable ? (
          <EditTable
            table={tableInfo}
            setTable={setTableInfo}
            tableErrors={tableInfoErrors}
            setTableErrors={setTableInfoErrors}
            editHandler={editHandler}
            tableEditPending={tableEditPending}
            tableEditError={tableEditError}
            tableId={table.id}
          />
        ) : (
          <>
            <p>
              <span>מספר:</span> {parseTableNumber(table.number)}
            </p>
            <p>
              <span>שם:</span> {table.name}
            </p>
            <p>
              <span>קשר:</span> {table.relation}
            </p>

            <p>
              <span>כיסאות:</span> {table.seats}
            </p>

            {tableDeleteError && <DisplayError error={tableDeleteError} />}
          </>
        )}
        <StyledGuestsToggle
          onClick={() => setIsWatchingGuests(!isWatchingGuests)}
          isWatchingGuests={isWatchingGuests}
        >
          {totalGuests} אורחים {}
          {<FaArrowAltCircleDown />}
        </StyledGuestsToggle>

        {isWatchingGuests && (
          <BottomCard>
            <Guests
              weddingId={table?.weddingId}
              tableId={table?.id}
              setTotalGuests={setTotalGuests}
              usage='manage-tables'
            />
          </BottomCard>
        )}
      </StyledTableInfo>
      <StyledActions>
        <Button
          icon={<FaEdit />}
          color='secDark'
          bgColor='white'
          handleClick={() => setIsEditingTable(!isEditingTable)}
        />
        <Button
          icon={<FaTrash />}
          color='error'
          bgColor='white'
          handleClick={() => setIsAwaitingDeletion(true)}
          disabled={tableDeletePending}
        />
      </StyledActions>
      {isAwaitingDeletion && (
        <DeleteConfirmation
          onCancel={() => setIsAwaitingDeletion(false)}
          onConfirm={() => deleteHandler(table.id)}
          text={`האם אתה בטוח שברצונך למחוק את שולחן מספר ${table.number}? שולחן זה יימחק לצמיתות יחד עם האורחים שבו`}
        />
      )}
    </StyledTable>
  );
};

const StyledTable = styled.div`
  display: flex;
  height: max-content;
  box-shadow: ${cssVariables.boxShadow};
  padding: 0.5rem 0;
`;

const StyledGuestsToggle = styled.h3<TableStyleProps>`
  margin-top: 0.5rem;
  width: 100%;
  cursor: pointer;
  svg {
    transform: ${(props) =>
      props.isWatchingGuests ? 'rotate(180deg)' : 'rotate(0)'};
    transition: transform 0.2s ease-out;
    font-size: 0.8rem;
  }
`;

const StyledTableInfo = styled.div`
  width: 100% !important;
  padding: 0.5rem;
  p span {
    font-weight: bold;
  }
  p + p {
    margin-top: 0.5rem;
  }
`;

const StyledActions = styled.div`
  align-self: flex-start;
  max-width: 25% !important;
  margin-left: 1rem;
  button {
    display: block;
    padding: 0.4rem;
    margin: auto;
    svg {
      vertical-align: middle;
      margin: auto;
    }
  }

  button + button {
    margin-top: 1rem;
  }
`;

const BottomCard = styled.div`
  width: 115% !important;
`;

export default TableCard;
