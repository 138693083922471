import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colorsVariables } from '../../GlobalStyles';

interface PaginationProps {
  totalPages: number;
  link: string;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, link }) => {
  return (
    <StyledPagination>
      {[...Array(totalPages).keys()].map((page, i) => (
        <NavLink to={`${link}page=${page + 1}`} key={i}>
          {page + 1}
        </NavLink>
      ))}
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 1rem;

  a {
    background: ${colorsVariables.secDark};
    color: ${colorsVariables.mainDark};
    padding: 0.2rem 0.4rem;
    font-weight: bold;
  }

  a + a {
    margin-left: 0.7rem;
  }

  .active {
    color: ${colorsVariables.white};
  }
`;

export default Pagination;
