import React from 'react';
import { Route, Switch } from 'react-router';
import useAuthorizedRoles from '../shared/hooks/useAuthorizedRoles';
import { UserRole } from '../shared/ts/User';
import EditUser from './Users/EditUser';
import NewUser from './Users/NewUser';
import Users from './Users/Users';
import ViewAdmin from './ViewAdmin';
import EditWedding from './Weddings/EditWedding';
import NewWedding from './Weddings/NewWedding';
import Weddings from './Weddings/Weddings';

const Admin: React.FC = () => {
  useAuthorizedRoles([UserRole.ADMIN]);

  return (
    <Switch>
      <Route
        path='/admin/weddings/couple=:couple?/location=:location?/remarks=:remarks?/status=:status?/page=:pageNumber'
        exact
        component={Weddings}
      />
      <Route path='/admin/weddings/new' exact component={NewWedding} />
      <Route path='/admin/weddings/:weddingId/edit' component={EditWedding} />
      <Route
        path='/admin/users/name=:name?/role=:role?/page=:pageNumber'
        component={Users}
        exact
      />
      <Route path='/admin/users/new' exact component={NewUser} />
      <Route path='/admin/users/:userId/edit' component={EditUser} />
      <Route path='/admin' exact component={ViewAdmin} />
    </Switch>
  );
};

export default Admin;
