import { useState } from 'react';

export default function useTableFilter(): [TableFilters, SetTableFilters] {
  const [name, setName] = useState<string | undefined>('');
  const [guestName, setGuestName] = useState<string | undefined>('');
  const [number, setNumber] = useState<string | undefined>('');
  const [relation, setRelation] = useState<string | undefined>('');

  const filters = {
    name,
    guestName,
    number,
    relation,
  };

  const setFilters = {
    setName,
    setGuestName,
    setNumber,
    setRelation,
  };

  return [filters, setFilters];
}

export interface TableFilters {
  name: string | undefined;
  guestName: string | undefined;
  number: string | undefined;
  relation: string | undefined;
}

export interface SetTableFilters {
  setName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setGuestName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRelation: React.Dispatch<React.SetStateAction<string | undefined>>;
}
