import { useEffect, useState } from 'react';
import {
  Guest,
  GuestInfo,
  GuestInfoErrors,
  SetGuestInfo,
  SetGuestInfoErrors,
} from '../ts/Guest';

export function useGuest(currentGuest?: Guest): [GuestInfo, SetGuestInfo] {
  const [name, setName] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [phone, setPhone] = useState<number>(null!);
  const [relation, setRelation] = useState<string>('');
  const [totalArrival, setTotalArrival] = useState<number>(1);
  const [tableId, setTableId] = useState<string>('');

  const guest = {
    name,
    nickname,
    phone,
    relation,
    totalArrival,
    tableId,
  };

  const setGuest = {
    setName,
    setNickname,
    setPhone,
    setRelation,
    setTotalArrival,
    setTableId,
  };

  useEffect(() => {
    if (currentGuest) {
      setName(currentGuest.name);
      setNickname(currentGuest.nickname || '');
      setPhone(currentGuest.phone || null!);
      setRelation(currentGuest.relation || '');
      setTotalArrival(currentGuest.totalArrival || 1);
      setTableId(currentGuest.tableId.toString());
    }
  }, [currentGuest]);

  return [guest, setGuest];
}

export function useGuestErrors(): [GuestInfoErrors, SetGuestInfoErrors] {
  const [nameError, setNameError] = useState<string>('');
  const [nicknameError, setNicknameError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [relationError, setRelationError] = useState<string>('');
  const [totalArrivalError, setTotalArrivalError] = useState<string>('');

  const guestErrors = [
    nameError,
    nicknameError,
    phoneError,
    relationError,
    totalArrivalError,
  ];

  const setGuestErrors = {
    setNameError,
    setNicknameError,
    setPhoneError,
    setRelationError,
    setTotalArrivalError,
  };

  return [guestErrors, setGuestErrors];
}
