import React, { useState } from 'react';
import styled from 'styled-components';
import { colorsVariables } from '../../../GlobalStyles';
import ArrivalButtons from '../../../OngoingWeddings/Manage/ArrivalButtons';
import { Guest, GuestInfo } from '../../ts/Guest';
import DeleteConfirmation from '../DeleteConfirmation';
import ActionButtons from './ActionButtons';
import EditGuest from './EditGuest';
import SelectTotalArrival from './SelectTotalArrival';

interface GuestProps {
  guest: Guest;
  guestDeletionPending: boolean;
  guestEditPending: boolean;
  guestEditError: string;
  handleDelete: (guestId: number) => Promise<void>;
  handleUpdate: (guestId: number, guestInfo: GuestInfo) => Promise<void>;
  usage?: 'wedding-summary' | 'manage-wedding' | 'manage-tables';
  updateGuestArrival?: (
    guestId: number,
    status: 'arrived' | 'notarrived',
    totalArrival?: number
  ) => void;
  guestArrivalLoading?: boolean;
  guestArrivalError?: string;
}

interface StyleProps {
  guest: Guest;
  keyword?: string | undefined;
}

type Props = StyleProps & GuestProps;

const GuestCard: React.FC<Props> = ({
  guest,
  handleDelete,
  handleUpdate,
  guestDeletionPending,
  guestEditError,
  guestEditPending,
  usage,
  updateGuestArrival,
  guestArrivalLoading,
  guestArrivalError,
  keyword,
}) => {
  const [isWatchingGuest, setIsWatchingGuest] = useState<boolean>(false);
  const [isAwaitingDeletion, setIsAwaitingDeletion] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [totalArrival, setTotalArrival] = useState<number>(guest.totalArrival);

  const limitChars = (name: string): string => {
    if (name.length >= 16) {
      const shortenedName = name.slice(0, 12);
      return shortenedName + '...';
    }
    return name;
  };

  return (
    <StyledGuest>
      <StyledInfo
        guest={guest}
        keyword={keyword}
        onClick={
          usage !== 'manage-wedding'
            ? () => setIsWatchingGuest(!isWatchingGuest)
            : () => ''
        }
      >
        <p
          onClick={
            usage === 'manage-wedding'
              ? () => setIsWatchingGuest(!isWatchingGuest)
              : () => ''
          }
        >
          {limitChars(guest.name)}
        </p>
        {usage !== 'manage-wedding' && <p>סה"כ {guest.totalArrival}</p>}
        {updateGuestArrival && usage === 'manage-wedding' && (
          <ManagementActions>
            <SelectTotalArrival
              totalArrival={totalArrival}
              setTotalArrival={setTotalArrival}
              arrived={guest.hasArrived}
            />
            <ArrivalButtons
              updateGuestArrival={updateGuestArrival}
              guestId={guest.id}
              totalArrival={totalArrival}
              setTotalArrival={setTotalArrival}
              guestArrivalLoading={guestArrivalLoading}
              guestArrivalError={guestArrivalError}
            />
          </ManagementActions>
        )}
      </StyledInfo>
      {isWatchingGuest &&
        (isEditing && usage !== 'manage-wedding' ? (
          <>
            <EditGuest
              guest={guest}
              handleUpdate={handleUpdate}
              setIsEditing={setIsEditing}
              guestEditError={guestEditError}
            />
            <ActionButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setIsAwaitingDeletion={setIsAwaitingDeletion}
              guestDeletionPending={guestDeletionPending}
              guestEditPending={guestEditPending}
            />
          </>
        ) : (
          <ExtendedGuestInfo>
            <GuestInfoDisplay
              onClick={() => setIsWatchingGuest(!isWatchingGuest)}
            >
              <FlexInfo>
                <p>
                  <span>שם:</span> {guest.name}
                </p>
                {guest.nickname && (
                  <p>
                    <span>כינוי:</span> {guest.nickname}
                  </p>
                )}
              </FlexInfo>
              <FlexInfo>
                {guest.phone && (
                  <p>
                    <span>טלפון:</span> {guest.phone}
                  </p>
                )}
                {guest.relation && (
                  <p>
                    <span>קשר:</span> {guest.relation}
                  </p>
                )}
              </FlexInfo>
            </GuestInfoDisplay>
            {usage === 'manage-tables' ? (
              <StyledActions>
                <p>סה"כ {guest.totalArrival}</p>
                <ActionButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  setIsAwaitingDeletion={setIsAwaitingDeletion}
                  guestDeletionPending={guestDeletionPending}
                  guestEditPending={guestEditPending}
                />
              </StyledActions>
            ) : (
              <p style={{ margin: '.4rem 0' }}>סה"כ {guest.totalArrival}</p>
            )}
          </ExtendedGuestInfo>
        ))}
      {isAwaitingDeletion && (
        <DeleteConfirmation
          text={`האם אתה בטוח ברצונך למחוק את ${guest.name}? `}
          onConfirm={() => handleDelete(guest.id)}
          onCancel={() => setIsAwaitingDeletion(false)}
        />
      )}
    </StyledGuest>
  );
};

const StyledGuest = styled.div``;

const StyledInfo = styled.div<StyleProps>`
  display: flex;
  align-items: flex-end;
  width: 100%;

  background: ${(props) =>
    props.keyword &&
    props.guest.name.includes(' ') &&
    props.guest.name.includes(props.keyword) &&
    colorsVariables.mainLight};

  background: ${(props) =>
    props.keyword &&
    props?.guest?.name?.substr(0, props?.keyword?.length) === props?.keyword &&
    colorsVariables.mainLight};

  background: ${(props) =>
    props.keyword &&
    props?.guest?.nickname?.substr(0, props?.keyword?.length) ===
      props?.keyword &&
    colorsVariables.mainLight};

  background: ${(props) =>
    props.guest.hasArrived === true && colorsVariables.arrived};

  font-weight: ${(props) =>
    props.keyword &&
    props.guest.name.substr(0, props.keyword.length) === props.keyword &&
    'bold'};

  font-weight: ${(props) =>
    props.keyword &&
    props.guest.name.includes(' ') &&
    props.guest.name.includes(props.keyword) &&
    'bold'};

  margin-bottom: 0.3rem;

  max-height: 1.8rem;
  border-bottom: 0.2px solid black;
  padding-bottom: 0.1rem;
  p {
    width: 50%;
    max-height: 1rem;
    overflow: hidden;
  }

  div {
    width: 50%;
  }
`;

const ExtendedGuestInfo = styled.div`
  width: 100%;
  margin-bottom: 0.2rem;

  p + p {
    margin-top: 0.3rem;
  }

  div + div {
    margin-top: 0 !important;
  }
`;

const GuestInfoDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FlexInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    max-width: 100%;
  }

  &:nth-of-type(even) {
    margin-right: 0.5rem;
  }
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;

  p {
    width: 100%;
  }
`;

const ManagementActions = styled.div`
  width: 100% !important;
  display: flex !important;
  justify-content: space-around !important;

  button {
    padding: 0.1rem 0.2rem;
    box-shadow: none;
    height: max-content;
    align-self: flex-end;
    :hover {
      padding: 0.1rem 0.2rem;
      opacity: 0.5;
    }
    svg {
      margin: 0;
      font-size: 0.9rem;
      vertical-align: top;
    }
  }

  select {
    font-size: 0.7rem;
    width: max-content;
    align-self: center;
  }
`;
export default GuestCard;
