import React from 'react';
import styled from 'styled-components';
import { ErrorParagraph } from '../../GlobalStyles';
import { FaExclamationTriangle } from 'react-icons/fa';

interface ErrorProps {
  error: string;
}

const DisplayError: React.FC<ErrorProps> = ({ error }) => {
  return (
    <StyledError>
      <FaExclamationTriangle />
      {error}
    </StyledError>
  );
};

const StyledError = styled(ErrorParagraph)`
  width: 60%;
`;

export default DisplayError;
