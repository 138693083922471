import { Table } from '../../shared/ts/Table';
import {
  TABLES_GET_FAIL,
  TABLES_GET_REQUEST,
  TABLES_GET_SUCCESS,
  TABLE_EDIT_FAIL,
  TABLE_EDIT_REQUEST,
  TABLE_EDIT_RESET,
  TABLE_EDIT_SUCCESS,
  TABLE_DELETE_REQUEST,
  TABLE_DELETE_SUCCESS,
  TABLE_DELETE_FAIL,
  TABLE_DELETE_RESET,
  TABLE_POST_REQUEST,
  TABLE_POST_SUCCESS,
  TABLE_POST_FAIL,
  TABLE_POST_RESET,
} from './actions';

export interface TablesState {
  isLoading: boolean;
  tables: Table[];
  pagination: { totalPages: number };
  error: string;
}

export const InitialTablesState = {
  isLoading: false,
  tables: [],
  pagination: { totalPages: null! },
  error: '',
};

type TablesAction = TABLES_GET_REQUEST | TABLES_GET_SUCCESS | TABLES_GET_FAIL;

export const tablesReducer = (
  state: TablesState = InitialTablesState,
  action: TablesAction
) => {
  switch (action.type) {
    case 'TABLES_GET_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'TABLES_GET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        tables: action.payload,
        pagination: action.pagination,
        error: '',
      };
    case 'TABLES_GET_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export interface TablePostState {
  isLoading: boolean;
  success: boolean;
  error: string;
}

export const InitialTablePostState = {
  isLoading: false,
  success: false,
  error: '',
};

type TablePostAction =
  | TABLE_POST_REQUEST
  | TABLE_POST_SUCCESS
  | TABLE_POST_FAIL
  | TABLE_POST_RESET;

export const tablePostReducer = (
  state: TablePostState = InitialTablePostState,
  action: TablePostAction
) => {
  switch (action.type) {
    case 'TABLE_POST_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'TABLE_POST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'TABLE_POST_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'TABLE_POST_RESET':
      return {
        ...InitialTablePostState,
      };
    default:
      return state;
  }
};

export interface TableEditState {
  isLoading: boolean;
  success: boolean;
  error: string;
}

export const InitialTableEditState = {
  isLoading: false,
  success: false,
  error: '',
};

type TableEditAction =
  | TABLE_EDIT_REQUEST
  | TABLE_EDIT_SUCCESS
  | TABLE_EDIT_FAIL
  | TABLE_EDIT_RESET;

export const tableEditReducer = (
  state: TableEditState = InitialTableEditState,
  action: TableEditAction
) => {
  switch (action.type) {
    case 'TABLE_EDIT_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'TABLE_EDIT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'TABLE_EDIT_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'TABLE_EDIT_RESET':
      return {
        ...InitialTableEditState,
      };
    default:
      return state;
  }
};

export interface TableDeleteState {
  isLoading: boolean;
  success: boolean;
  error: string;
}

export const InitialTableDeleteState = {
  isLoading: false,
  success: false,
  error: '',
};

type TableDeleteAction =
  | TABLE_DELETE_REQUEST
  | TABLE_DELETE_SUCCESS
  | TABLE_DELETE_FAIL
  | TABLE_DELETE_RESET;

export const tableDeleteReducer = (
  state: TableDeleteState = InitialTableEditState,
  action: TableDeleteAction
) => {
  switch (action.type) {
    case 'TABLE_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'TABLE_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'TABLE_DELETE_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'TABLE_DELETE_RESET':
      return {
        ...InitialTableDeleteState,
      };
    default:
      return state;
  }
};
