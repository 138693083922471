export const isValidUsername = (
  username: string,
  setUsernameError: React.Dispatch<React.SetStateAction<string>>
): boolean => {
  if (!username) return false;
  if (username.length && username.length < 3) {
    setUsernameError('שם משתמש חייב להכיל לפחות 3 תווים');
    return false;
  } else {
    setUsernameError('');
    return true;
  }
};

export const isValidRole = (
  role: string | undefined,
  setRoleError: React.Dispatch<React.SetStateAction<string>>
): boolean => {
  if (!role) {
    setRoleError('חובה לבחור תפקיד משתמש מהרשימה');
    return false;
  }
  if (['ADMIN', 'COUPLE', 'EMPLOYEE', 'TEST'].includes(role)) {
    setRoleError('');
    return true;
  } else {
    setRoleError('חובה לבחור תפקיד משתמש מהרשימה');
    return false;
  }
};

export const isValidPassword = (
  password: string,
  setPasswordError: React.Dispatch<React.SetStateAction<string>>
): boolean => {
  var filter = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  if (password.length >= 1 && String(password).search(filter) === -1) {
    setPasswordError('סיסמה חייבת להכיל 8 תווים, אות באנגלית קטנה וגדולה');
    return false;
  } else {
    setPasswordError('');
    return true;
  }
};
