import React, { useCallback, useEffect, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import styled from 'styled-components';
import { colorsVariables } from '../GlobalStyles';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';
import formimage from '../images/formimage.jpg';
import Button from '../shared/components/Button';
import DisplayError from '../shared/components/DisplayError';
import Input from '../shared/components/Input';
import getToday from '../shared/helpers/getToday';

interface FormStyle {
  deviceWidth: number;
}

const ContactUs: React.FC = () => {
  const [today] = useState<string>(getToday());
  const [coupleName, setCoupleName] = useState<string>('');
  const [coupleNameError, setCoupleNameError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [screenWidth] = useState<number>(window.innerWidth);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  init(process.env.REACT_APP_EMAILJS_USER!);

  const validateInputs = useCallback((): void => {
    setCoupleNameError(
      coupleName.length >= 1 && coupleName.length < 3
        ? 'שם הזוג חייב להכיל לפחות 3 תווים'
        : ''
    );

    setPhoneNumberError(
      phoneNumber.length >= 1 &&
        !/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/.test(phoneNumber)
        ? 'נא להזין מספר טלפון תקין בן 10 ספרות'
        : ''
    );
  }, [coupleName.length, phoneNumber]);

  const isFormValid = (): boolean => {
    if (
      !coupleNameError &&
      coupleName.trim().length &&
      !phoneNumberError &&
      phoneNumber
    ) {
      return true;
    }
    return false;
  };

  const clearForm = (e: any): void => {
    e?.target?.reset();

    setCoupleName('');
    setPhoneNumber('');
    setDate('');
    setComments('');
    setFormError('');
  };

  const formSubmitHandler = (e: any): void => {
    e.preventDefault();

    if (isFormValid() && !isFormPending) {
      setIsFormPending(true);
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE!,
          process.env.REACT_APP_EMAILJS_TEMPLATE!,
          e.target,
          process.env.REACT_APP_EMAILJS_USER!
        )
        .then((res) => {
          setIsFormPending(false);
          setFormSuccess(true);
          clearForm(e);
        })
        .catch((error) => {
          setIsFormPending(false);
          setFormError(
            'הייתה בעיה בשליחת הטופס. נסה שוב בבקשה או צרו קשר במייל focusevent25@gmail.com'
          );
        });
    }
  };

  useEffect(() => {
    validateInputs();
  }, [validateInputs]);

  return (
    <ContctUsWrapper deviceWidth={screenWidth}>
      <ContactForm onSubmit={formSubmitHandler}>
        <Input
          label='שם הזוג:'
          name='coupleName'
          required
          onChange={setCoupleName}
          value={coupleName}
        />
        {coupleNameError && <DisplayError error={coupleNameError} />}
        <Input
          label='מספר טלפון:'
          name='phoneNumber'
          required
          onChange={setPhoneNumber}
          value={phoneNumber}
        />
        {phoneNumberError && <DisplayError error={phoneNumberError} />}

        <Input
          type='date'
          name='weddingDate'
          onChange={setDate}
          label='תאריך האירוע:'
          value={date}
          min={today}
        />
        <StyledTextArea>
          <label htmlFor=''>
            <p>הערות:</p>
          </label>
          <textarea
            name='comments'
            placeholder='הערות'
            onChange={(e: any) => setComments(e.target.value)}
            value={comments}
          />
        </StyledTextArea>
        {formSuccess && (
          <p>
            <span>הטופס נשלח בהצלחה. ניצור קשר בהקדם!</span>
          </p>
        )}
        {formError && <StyledFormError>{formError}</StyledFormError>}
        <Button
          text={isFormPending ? 'שולח... ' : 'שליחה'}
          icon={<FaPaperPlane />}
          color='mainDark'
          bgColor='secDark'
          type='submit'
          disabled={isFormPending}
        />
      </ContactForm>
    </ContctUsWrapper>
  );
};

const ContctUsWrapper = styled.div<FormStyle>`
  width: 100%;
  margin-top: 1rem !important;
  background: #eb01a5;
  min-height: 15rem;
  background: ${(props) =>
    props.deviceWidth > 800
      ? `linear-gradient(-125deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 45%, transparent 0px), url(${formimage}) `
      : `linear-gradient(-125deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 70%, transparent 0px), url(${formimage}) `};

  background-position: center;
  background-size: 100%;
  background-size: cover;
  border-radius: 3px;

  p span {
    font-weight: bold;
  }

  @media (min-width: 1200px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const ContactForm = styled.form`
  max-width: 40% !important;
  padding: 1rem;

  p {
    white-space: nowrap;
  }

  input {
    width: 80%;
  }

  @media (max-width: 600px) {
    input {
      min-width: 170%;
    }
    p {
      white-space: normal;
      width: 170% !important;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    input {
      min-width: 100%;
    }
  }

  div + div {
    margin-bottom: 0.5rem;
  }
`;

const StyledTextArea = styled.div`
  p {
    margin-top: 0.3rem;
    font-weight: bold;
  }
  textarea {
    margin-top: 0.3rem;
    border: 1px solid ${colorsVariables.secDark};
    padding-right: 0.6rem;
  }
`;

const StyledFormError = styled.p`
  max-width: 80%;
  white-space: pre-wrap !important;
  margin-bottom: 0.3rem;
`;

export default ContactUs;
