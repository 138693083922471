import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { login } from '../redux/auth/actions';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalPageInit, StyledForm } from '../GlobalStyles';
import useScrollTop from '../shared/hooks/useScrollTop';
import Input from '../shared/components/Input';
import Button from '../shared/components/Button';
import DisplayError from '../shared/components/DisplayError';
import Loader from '../shared/components/Loader';

const Login: React.FC = () => {
  useScrollTop();
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state: RootState) => state.userLogin);
  const { loggedUser, error, isLoading } = userLogin;

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const userLoginHandler = (e: FormEvent): void => {
    e.preventDefault();
    if (email.length > 1 && password.length > 1) {
      dispatch(login(email, password));
    }
  };

  useEffect(() => {
    if (loggedUser) {
      history.push(`/`);
    }
  }, [loggedUser, history]);

  return (
    <StyledLogin>
      {!loggedUser && (
        <StyledForm onSubmit={userLoginHandler}>
          <h3>התחברות למשתמש</h3>
          <Input
            label='אימייל:'
            placeholder='כתובת אימייל'
            onChange={setEmail}
            value={email}
            required
          />
          <Input
            label='סיסמה:'
            placeholder='סיסמה'
            onChange={setPassword}
            type='password'
            value={password}
            required
          />
          {error && <DisplayError error={error} />}
          {isLoading && <Loader message='טוען' size={40} />}

          {isLoading ? (
            <Button
              type='submit'
              text='התחברות'
              color='mainDark'
              bgColor='secDark'
              disabled
            />
          ) : (
            <Button
              type='submit'
              text='התחברות'
              color='mainDark'
              bgColor='secDark'
            />
          )}
        </StyledForm>
      )}
    </StyledLogin>
  );
};

const StyledLogin = styled(GlobalPageInit)`
  margin-top: 1rem;

  h3 {
    margin-bottom: 1rem;
  }

  h4 {
    color: red;
    margin-bottom: 1rem;
  }
`;

export default Login;
