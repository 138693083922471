import React, { useState } from 'react';
import styled from 'styled-components';
import { colorsVariables, cssVariables } from '../../GlobalStyles';
import Guests from '../../shared/components/Guests/Guests';
import calculateTakenSeats from '../../shared/helpers/calculateTakenSeats';
import calculateTotalGuests from '../../shared/helpers/calculateTotalGuests';
import { parseTableNumber } from '../../shared/helpers/parseTableNumber';
import { Table } from '../../shared/ts/Table';

interface TableGuestsCardProps {
  table: Table;
  keyword: string | undefined;
  usage: 'wedding-summary' | 'manage-wedding';
}

type StyledTakenSeatsProps = {
  hasAvailableSeats: boolean;
};

const TableGuestsCard: React.FC<TableGuestsCardProps> = ({
  table,
  keyword,
  usage,
}) => {
  const [totalGuests, setTotalGuests] = useState<number>(
    calculateTotalGuests(table.guests)
  );
  const [takenSeats, setTakenSeats] = useState<number>(
    calculateTakenSeats(table.guests)
  );

  return (
    <StyledTableGuests>
      <StyledTableInfo>
        <p>
          <span>שולחן {parseTableNumber(table.number)}</span>
        </p>
        <FlexInfo>
          <p>
            <span>שם: </span>
            {table.name}
          </p>
          <p>
            <span>קשר: </span>
            {table.relation}
          </p>
        </FlexInfo>
        <FlexInfo>
          <p>
            <span>אורחים: </span>
            {totalGuests}
          </p>
          <StyledTakenSeats hasAvailableSeats={table.seats > takenSeats}>
            <span>תפוסה: </span>
            {table.seats} / {takenSeats}
          </StyledTakenSeats>
        </FlexInfo>
      </StyledTableInfo>
      <Guests
        weddingId={table.weddingId}
        tableId={table.id}
        setTakenSeats={setTakenSeats}
        setTotalGuests={setTotalGuests}
        usage={usage}
        keyword={keyword}
      />
    </StyledTableGuests>
  );
};

const StyledTableGuests = styled.div`
  height: max-content;
  box-shadow: ${cssVariables.boxShadow};
  padding: 0.5rem;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
  p {
    width: 100%;
    span {
      font-weight: bold;
    }
  }

  div + div {
    margin-top: 0.4rem;
  }
`;

const StyledTableInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  p {
    width: initial;
  }
`;

const FlexInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledTakenSeats = styled.p<StyledTakenSeatsProps>`
  background: ${(props) =>
    props.hasAvailableSeats ? colorsVariables.arrived : colorsVariables.error};
`;

export default TableGuestsCard;
