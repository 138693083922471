import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUser, getUser } from '../../redux/users/actions';
import { RootState } from '../../store';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalPageInit, StyledForm } from '../../GlobalStyles';
import { UserRole } from '../../shared/ts/User';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import useScrollTop from '../../shared/hooks/useScrollTop';
import {
  isValidPassword,
  isValidRole,
  isValidUsername,
} from '../../shared/helpers/UserValidation';
import { v4 as uuidv4 } from 'uuid';
import SelectRole from './SelectRole';
import DisplayError from '../../shared/components/DisplayError';
import Input from '../../shared/components/Input';
import Loader from '../../shared/components/Loader';
import Button from '../../shared/components/Button';
import { FaEdit } from 'react-icons/fa';
import PermitAccess from './PermitAccess';
import ManageAccess from '../../shared/components/ManageAccess';

const EditUser: React.FC = () => {
  useAuthorizedRoles([UserRole.ADMIN]);
  useScrollTop();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string }>();
  const [isManagingPermissions, setIsManagingPermissions] =
    useState<boolean>(false);

  const { user, isLoading, error } = useSelector(
    (state: RootState) => state.user
  );
  useIsTokenExpired(error);

  const {
    success,
    isLoading: loadingEdit,
    error: editError,
  } = useSelector((state: RootState) => state.userEdit);
  useIsTokenExpired(editError);

  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [role, setRole] = useState<string | undefined>('');
  const [roleError, setRoleError] = useState<string>('');
  const [permittedWeddings, setPermittedWeddings] = useState<number[]>([]);

  const errors: string[] = [
    usernameError,
    passwordError,
    roleError,
    editError,
    error,
  ];

  const validateForm = useCallback((): void => {
    isValidUsername(username, setUsernameError);
    isValidRole(role, setRoleError);
    isValidPassword(password, setPasswordError);
  }, [username, role, password]);

  const editUserHandler = (e: FormEvent) => {
    e.preventDefault();
    if (!usernameError && !roleError && !passwordError) {
      dispatch(editUser(+userId, username, role, permittedWeddings, password));
    }
  };

  useEffect(() => {
    dispatch(getUser(+userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (user) {
      setRole(user.role);
      setUsername(user.username);
      setPermittedWeddings(user.permittedWeddings);
    }
  }, [user]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    if (success) {
      dispatch({ type: 'USER_EDIT_RESET' });
      history.push('/admin/users/name=/role=/page=1');
    }
  });

  return (
    <StyledEditUser>
      {isLoading && <Loader message='טוען משתמש...' />}
      {!isLoading && user && (
        <StyledForm onSubmit={editUserHandler}>
          <h3>עריכת המשתמש {user.username}</h3>
          <Input
            label='שם המשתמש:'
            onChange={setUsername}
            value={username || ''}
          />
          <Input
            label='סיסמה חדשה:'
            onChange={setPassword}
            type='password'
            value={password}
          />
          <SelectRole
            role={role}
            setRole={setRole}
            text={`תפקיד נוכחי: ${role}`}
          />
          {user.role === 'EMPLOYEE' && (
            <ManageAccess
              setIsManagingPermissions={setIsManagingPermissions}
              isManagingPermissions={isManagingPermissions}
            />
          )}
          {isManagingPermissions && (
            <PermitAccess
              permittedWeddings={permittedWeddings}
              setPermittedWeddings={setPermittedWeddings}
            />
          )}
          {errors
            .filter((error) => error)
            .map((error) => (
              <DisplayError error={error} key={uuidv4()} />
            ))}
          <Button
            text={loadingEdit ? 'עורך...' : 'סיום עריכה'}
            icon={<FaEdit />}
            color='secLight'
            bgColor='mainLight'
            disabled={loadingEdit}
            type='submit'
          />
        </StyledForm>
      )}
    </StyledEditUser>
  );
};

const StyledEditUser = styled(GlobalPageInit)``;

export default EditUser;
