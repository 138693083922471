import React from 'react';
import { UserRole } from '../../shared/ts/User';

interface SelectRoleProps {
  role: string | undefined | UserRole;
  setRole: React.Dispatch<React.SetStateAction<string | undefined>>;
  text: string;
}

const SelectRole: React.FC<SelectRoleProps> = ({ role, setRole, text }) => {
  const options = [
    { value: UserRole.ADMIN, label: 'אדמין' },
    { value: UserRole.EMPLOYEE, label: 'עובד' },
    { value: UserRole.COUPLE, label: 'זוג' },
    { value: UserRole.TEST, label: 'טסט' },
  ];

  return (
    <>
      <select onChange={(e) => setRole(e.target.value)} value={role}>
        <option hidden>{text}</option>
        {options.map((option, i) => (
          <option value={option.value} key={i}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectRole;
