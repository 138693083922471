import { createGlobalStyle, keyframes } from 'styled-components';
import styled from 'styled-components';

export const colorsVariables: ColorObject = {
  text: '#3a3939',
  mainDark: '#d4af37',
  mainLight: '#ffdd00',
  secDark: '#000000',
  secLight: '#333533',
  white: '#fff',
  third: '#d6d6d6',
  error: '#FF6347',
  link: '#28527a',
  arrived: '#7BCC70',
};

interface ColorObject {
  [key: string]: any;
}

export const cssVariables = {
  boxShadow: '0 7px 5px rgba(0, 0, 0, 0.2)',
};

export const GlobalStyles = createGlobalStyle`
:root {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  writing-mode: rtl;
  direction: rtl;
}

html{
  color: ${colorsVariables.text};
}

body {
  overflow-x: hidden;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,h2,h3 {
  font-family: 'Varela Round', sans-serif;

}

h4,h5,h6,p,li {
  letter-spacing: .15px;
}

a {
    text-decoration: none;
    color: inherit;
  }

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
}

button:disabled {
  cursor:not-allowed;
}

&::-moz-focus-inner {
    border: 0;
    padding: 0;
}

ul{
  padding: 0;
  list-style: none;
}

img{
  max-width: 100%;
}

input {
  min-height: 2rem;
}

::selection {
  background: ${colorsVariables.secDark};
  color: ${colorsVariables.mainLight};
}


.my-masonry-grid {
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  margin-left: -30px;
  width: auto;
}


.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 30px;
}
`;

export const GlobalPageInit = styled.main`
  width: 1500px;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 0.2rem;
  min-height: 90vh;
  overflow-x: hidden !important;
`;

export const StyledForm = styled.form`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;

  h3 {
    margin-top: 1rem;
  }
  button {
    margin-top: 1rem;
  }

  input,
  select,
  textarea {
    width: 95%;
  }

  textarea {
    border: 1px solid ${colorsVariables.secDark};
    font-size: 1.4rem;
  }
  p {
    width: 95%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  div,
  textarea {
    margin-top: 1rem;
  }

  textarea {
    padding-right: 0.5rem;
    min-width: 60%;
  }
  input {
    min-height: 2rem;
  }

  select {
    margin-top: 1rem;
  }
  @media (min-width: 600px) {
    width: 35rem;
    input,
    select,
    textarea {
      width: 100%;
      margin-top: 1rem;
    }
  }
`;

export const StyledLongForm = styled(StyledForm)`
  @media (min-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    width: 100%;
    button {
      width: max-content;
      margin: 0 auto;
      margin-top: 1rem;
    }
  }
`;

export const StyledInputs = styled.div`
  @media (min-width: 600px) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: -0.5rem !important;
    width: 100%;
    max-width: 800px;
    input,
    textarea,
    select {
      width: 100%;
    }
    div {
      width: 45%;
    }
    p {
      :not(label p) {
        text-align: center;
      }
    }
  }
`;

export const ErrorParagraph = styled.p`
  padding: 0.2rem 0 0.5rem;
  font-weight: bold;
  width: max-content;
  color: ${colorsVariables.error};
  margin-top: 0.3rem;
  svg {
    margin-left: 0.5rem;
    height: 1rem;
    width: 1.5rem;
    background: white;
    color: black;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;

  div {
    width: 90%;
  }
`;

export const StyledLink = styled.p`
  color: ${colorsVariables.link};
  margin-top: 0.2rem;
  cursor: pointer;
`;

export const StyledTableForm = styled.form`
  margin-top: 0.4rem;
  border-bottom: 1px solid black;
  padding-bottom: 0.4rem;
  button {
    margin-top: 0.5rem;
  }

  select {
    margin-top: 0.5rem;
  }

  div + div {
    margin-top: 0.3rem;
  }
  h3 {
    margin-top: 0.5rem;
  }
`;

export const TableFormInputs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  div {
    width: 50%;
  }

  div + div,
  div + select {
    margin-right: 0.4rem;
  }

  input {
    width: 100%;
  }

  @media (min-width: 600px) {
    div {
      max-width: 25%;
    }
  }
`;

export const breakpointColumnsObj = {
  default: 4,
  1400: 4,
  1250: 3,
  900: 2,
  700: 1,
};

export const slideDown = keyframes`
  0%{transform: translateY(-100%); opacity: 0;}
  100%{transform: translateY(0); opacity: 1;}
`;

export const fadeIn = keyframes`
    0%{opacity: 0;}
  100%{ opacity: 1;}
`;
