import React from 'react';
import styled from 'styled-components';
import Button from './Button';

interface DeleteConfirmationProps {
  text: string;
  onConfirm?: Function;
  onCancel?: Function;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  text,
  onCancel,
  onConfirm,
}) => {
  return (
    <StyledDeleteConfirmation>
      <Content>
        <h3>{text}</h3>
        <Button
          text='ביטול'
          bgColor='secDark'
          color='mainDark'
          handleClick={onCancel}
        />
        <Button
          text='מחיקה'
          bgColor='error'
          color='white'
          handleClick={() => {
            if (onCancel && onConfirm) {
              onConfirm();
              onCancel();
            }
          }}
        />
      </Content>
    </StyledDeleteConfirmation>
  );
};

const StyledDeleteConfirmation = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  width: 80%;
  padding: 3rem 0.5rem;
  text-align: center;
  line-height: 1.5;

  button {
    margin-top: 1rem;
  }
  button + button {
    margin-right: 1rem;
  }
`;
export default DeleteConfirmation;
