import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalPageInit } from '../GlobalStyles';
import useAuthorizedRoles from '../shared/hooks/useAuthorizedRoles';
import { UserRole } from '../shared/ts/User';

const ViewAdmin: React.FC = () => {
  const loggedUser = useAuthorizedRoles([UserRole.ADMIN]);
  return (
    <StyledViewAdmin>
      {loggedUser && (
        <AdminNavigation>
          <h3>ברוך הבא {loggedUser.username}</h3>
          <Link to='/admin/users'>ניהול משתמשים</Link>
          <Link to='/admin/users/new'>משתמש חדש</Link>
          <Link to='/admin/weddings'>ניהול אירועים</Link>
        </AdminNavigation>
      )}
    </StyledViewAdmin>
  );
};

const StyledViewAdmin = styled(GlobalPageInit)`
  margin: 0 auto;
`;

const AdminNavigation = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  h3 {
    margin-bottom: 0.5rem;
  }
  a + a {
    margin-top: 0.5rem;
  }
`;
export default ViewAdmin;
