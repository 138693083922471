import { Guest } from '../../shared/ts/Guest';
import {
  GUESTS_GET_FAIL,
  GUESTS_GET_REQUEST,
  GUESTS_GET_SUCCESS,
  GUEST_DELETE_FAIL,
  GUEST_DELETE_REQUEST,
  GUEST_DELETE_RESET,
  GUEST_DELETE_SUCCESS,
} from './actions';

export interface GuestsState {
  isLoading: boolean;
  guests: Guest[];
  error: string;
}

export const InitialGuestsState = {
  isLoading: false,
  guests: [],
  error: '',
};

type GuestsAction = GUESTS_GET_REQUEST | GUESTS_GET_SUCCESS | GUESTS_GET_FAIL;

export const guestsReducer = (
  state: GuestsState = InitialGuestsState,
  action: GuestsAction
) => {
  switch (action.type) {
    case 'GUESTS_GET_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'GUESTS_GET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        guests: action.payload,
      };
    case 'GUESTS_GET_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export interface GuestsDeleteState {
  isLoading: boolean;
  success: boolean;
  error: string;
}

export const InitialGuestsDeleteState = {
  isLoading: false,
  success: false,
  error: '',
};

type GuestDeleteAction =
  | GUEST_DELETE_REQUEST
  | GUEST_DELETE_SUCCESS
  | GUEST_DELETE_FAIL
  | GUEST_DELETE_RESET;

export const guestsDeleteReducer = (
  state: GuestsDeleteState = InitialGuestsDeleteState,
  action: GuestDeleteAction
) => {
  switch (action.type) {
    case 'GUEST_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'GUEST_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'GUEST_DELETE_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'GUEST_DELETE_RESET':
      return {
        ...InitialGuestsDeleteState,
      };
    default:
      return state;
  }
};
