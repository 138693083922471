import { User } from '../../shared/ts/User';
import {
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_GET_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_RESET,
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_RESET,
} from './actions';

interface usersState {
  isLoading: boolean;
  users: User[];
  error: string;
  pagination: { totalPages: number };
}

export const InitialUsersState = {
  isLoading: false,
  users: [],
  error: '',
  pagination: { totalPages: null! },
};

type UsersAction = USERS_GET_REQUEST | USERS_GET_SUCCESS | USERS_GET_FAIL;

export const usersReducer = (
  state: usersState = InitialUsersState,
  action: UsersAction
) => {
  switch (action.type) {
    case 'USERS_GET_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USERS_GET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        users: action.payload,
        pagination: action.pagination,
      };
    case 'USERS_GET_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

interface UserState {
  isLoading: boolean;
  user: User;
  error: string;
}

export const InitialUserState = {
  isLoading: false,
  user: {} as User,
  error: '',
};

type GetUserActions = USER_GET_REQUEST | USER_GET_SUCCESS | USER_GET_FAIL;

export const userReducer = (
  state: UserState = InitialUserState,
  action: GetUserActions
) => {
  switch (action.type) {
    case 'USER_GET_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_GET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        user: action.payload,
      };
    case 'USER_GET_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

interface EditUserState {
  isLoading: boolean;
  error: string;
  success: boolean;
}

export const InitialEditUserState = {
  isLoading: false,
  success: false,
  error: '',
};

type EditUserAction =
  | USER_EDIT_REQUEST
  | USER_EDIT_SUCCESS
  | USER_EDIT_FAIL
  | USER_EDIT_RESET;

export const userEditReducer = (
  state: EditUserState = InitialEditUserState,
  action: EditUserAction
) => {
  switch (action.type) {
    case 'USER_EDIT_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_EDIT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'USER_EDIT_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'USER_EDIT_RESET':
      return {
        isLoading: false,
        success: false,
        error: '',
      };
    default:
      return state;
  }
};

interface UserDeleteState {
  isLoading: boolean;
  error: string;
}

export const InitialUserDelete = {
  isLoading: false,
  error: '',
};

type UserDeleteActions =
  | USER_DELETE_REQUEST
  | USER_DELETE_SUCCESS
  | USER_DELETE_FAIL
  | USER_DELETE_RESET;

export const userDeleteReducer = (
  state: UserDeleteState = InitialUserDelete,
  action: UserDeleteActions
) => {
  switch (action.type) {
    case 'USER_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'USER_DELETE_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'USER_DELETE_RESET':
      return {};
    default:
      return state;
  }
};
