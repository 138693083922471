import React from 'react';
import styled from 'styled-components';
import { colorsVariables, cssVariables } from '../../GlobalStyles';

interface ButtonProps {
  text?: string;
  icon?: any;
  disabled?: boolean;
  type?: 'submit' | 'button';
  handleClick?: any;
  includeEvent?: boolean;
  args?: any[];
}

interface StyleProps {
  color: string;
  bgColor: string;
}

type Props = StyleProps & ButtonProps;

const determineColor = (color: string) => {
  return colorsVariables[color];
};

const Button: React.FC<Props> = ({
  text,
  icon,
  disabled,
  type,
  color,
  bgColor,
  handleClick,
  args,
  includeEvent,
}) => {
  return (
    <>
      {includeEvent && handleClick && args ? (
        <StyledButton
          color={determineColor(color)}
          bgColor={determineColor(bgColor)}
          disabled={disabled}
          type={type || 'button'}
          onClick={(e) => handleClick(e, ...args)}
        >
          {icon && icon} {text && text}
        </StyledButton>
      ) : !includeEvent && args && handleClick ? (
        <StyledButton
          color={determineColor(color)}
          bgColor={determineColor(bgColor)}
          disabled={disabled}
          type={type || 'button'}
          onClick={() => handleClick(...args)}
        >
          {icon && icon} {text && text}
        </StyledButton>
      ) : includeEvent && handleClick && !args ? (
        <StyledButton
          color={determineColor(color)}
          bgColor={determineColor(bgColor)}
          disabled={disabled}
          type={type || 'button'}
          onClick={(e) => handleClick(e)}
        >
          {icon && icon} {text && text}
        </StyledButton>
      ) : !includeEvent && !args && handleClick ? (
        <StyledButton
          color={determineColor(color)}
          bgColor={determineColor(bgColor)}
          disabled={disabled}
          type={type || 'button'}
          onClick={handleClick}
        >
          {icon && icon} {text && text}
        </StyledButton>
      ) : (
        <StyledButton
          color={determineColor(color)}
          bgColor={determineColor(bgColor)}
          disabled={disabled}
          type={type || 'button'}
        >
          {icon && icon} {text && text}
        </StyledButton>
      )}
    </>
  );
};

const StyledButton = styled.button<StyleProps>`
  box-shadow: ${cssVariables.boxShadow};
  padding: 0.4rem 0.6rem;
  transition: all 0.4s ease-out;
  font-weight: bold;
  &:hover {
    opacity: 0.75;
    padding: 0.4rem 0.9rem;
  }

  color: ${({ color }) => color};

  background: ${({ bgColor }) => bgColor};

  border: ${(props) =>
    props.bgColor === colorsVariables.white &&
    `1px solid ${colorsVariables.secDark}`};

  svg {
    margin-left: 0.3rem;
    vertical-align: middle;
  }
`;

export default Button;
