import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledLink } from '../../GlobalStyles';
import { User } from '../../shared/ts/User';
import Button from '../../shared/components/Button';
import DeleteConfirmation from '../../shared/components/DeleteConfirmation';
import { FaTrash, FaEdit } from 'react-icons/fa';

interface UserRowProps {
  user: User;
  userDeleteHandler: (userId: number) => void;
  isDeletionPending: boolean;
}

const UserRow: React.FC<UserRowProps> = ({
  user,
  userDeleteHandler,
  isDeletionPending,
}) => {
  const [isAwaitingDeletion, setIsAwaitingDeletion] = useState<boolean>(false);

  return (
    <StyledUser>
      <p>שם משתמש: {user.username}</p>
      <p>תפקיד: {user.role}</p>
      <p>אימייל: {user.email}</p>
      {user.weddingId && (
        <Link
          to={`/couple/wedding/${user.weddingId}/number=/name=/guest=/relation=/page=1`}
        >
          <StyledLink>אירוע</StyledLink>{' '}
        </Link>
      )}
      <ActionButtons>
        <Link to={`/admin/users/${user.id}/edit`}>
          <Button
            icon={<FaEdit />}
            color='secDark'
            bgColor='white'
            text='עריכה'
          ></Button>
        </Link>
        <Button
          icon={<FaTrash />}
          text='מחיקה'
          color='error'
          bgColor='white'
          disabled={isDeletionPending}
          handleClick={() => setIsAwaitingDeletion(true)}
        ></Button>
        {isAwaitingDeletion && (
          <DeleteConfirmation
            text={`אתה בטוח? המשתמש ${user.username}  יימחק לצמיתות יחד עם כל המידע הקשור אליו`}
            onConfirm={() => userDeleteHandler(user.id)}
            onCancel={() => setIsAwaitingDeletion(false)}
          />
        )}
      </ActionButtons>
    </StyledUser>
  );
};

const StyledUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  padding-right: 0.2rem;
  box-shadow: 0 1.5px 5px rgba(0, 0, 0, 0.2);

  &:nth-of-type(even) {
    background: #fff6f6;
  }
  p + p {
    margin-top: 0.5rem;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  margin-top: 0.5rem;

  button {
    margin-left: 0.5rem;
  }
`;

export default UserRow;
