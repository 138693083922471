import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { colorsVariables, slideDown } from '../../GlobalStyles';
import { logout } from '../../redux/auth/actions';
import { User, UserRole } from '../ts/User';

interface DropdownProps {
  toggleOnClick: (action: any) => void;
  loggedUser: User | null;
}

const Dropdown: React.FC<DropdownProps> = ({ toggleOnClick, loggedUser }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isWatchingAdminLinks, setIsWatchingAdminLinks] = useState<boolean>(
    false
  );

  const logoutHandler = (): void => {
    dispatch(logout());
    toggleOnClick(false);
    history.replace('/');
  };

  return (
    <OutterDropdown>
      <StyledDropdown>
        <MainNav onClick={() => toggleOnClick(false)}>
          <NavLink to='/' exact>
            <p>דף הבית</p>
          </NavLink>
          <NavLink to={`/discounts`}>
            <p>הטבות ייחודיות למשתמשי הממשק</p>
          </NavLink>
          {loggedUser && loggedUser.role === UserRole.EMPLOYEE && (
            <NavLink to='/ongoing-weddings'>
              <p>ניהול אירועים פעילים</p>
            </NavLink>
          )}
          {loggedUser && loggedUser.role === UserRole.ADMIN && (
            <NavLink to='/ongoing-weddings'>
              <p>ניהול אירועים פעילים</p>
            </NavLink>
          )}

          {loggedUser && loggedUser.role === UserRole.COUPLE && (
            <NavLink
              to={`/couple/wedding/${loggedUser.weddingId}/number=/name=/guest=/relation=/page=1`}
            >
              <p>האירוע שלי</p>
            </NavLink>
          )}

          {!loggedUser && (
            <NavLink to='/login'>
              <p>התחברות</p>
            </NavLink>
          )}
        </MainNav>

        {loggedUser && <p onClick={logoutHandler}>התנתקות</p>}
        <AdminNav>
          {loggedUser && loggedUser.role === UserRole.ADMIN && (
            <p onClick={() => setIsWatchingAdminLinks(!isWatchingAdminLinks)}>
              {isWatchingAdminLinks ? 'הסתרת קישורי אדמין' : 'אדמין'}
            </p>
          )}
          {isWatchingAdminLinks && (
            <AdminExtended onClick={() => toggleOnClick(false)}>
              <NavLink to={`/admin/users/name=/role=/page=1`}>
                <p>ניהול משתמשים</p>
              </NavLink>
              <NavLink to={`/admin/users/new`}>
                <p>משתמש חדש</p>
              </NavLink>
              <NavLink
                to={`/admin/weddings/couple=/location=/remarks=/status=/page=1`}
              >
                <p>ניהול אירועים</p>
              </NavLink>
              <NavLink to={`/admin/weddings/new`}>
                <p>אירוע חדש</p>
              </NavLink>
            </AdminExtended>
          )}
        </AdminNav>
      </StyledDropdown>
    </OutterDropdown>
  );
};

const OutterDropdown = styled.div`
  width: 100%;
  background: ${colorsVariables.secDark};
  z-index: 0;
  width: 1500px;
  max-width: 96.5%;
  margin: 0 auto;
  margin-top: 0.25rem;
`;

const StyledDropdown = styled.div`
  width: 1500px;
  max-width: 96.5%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-top: -0.25rem;

  background: ${colorsVariables.secDark};
  color: ${colorsVariables.mainDark};

  transition: all 0.5s ease-out;
  animation-name: ${slideDown};
  animation-duration: 1s;
  p {
    margin-top: 1rem;
    margin-right: 0.5rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    cursor: pointer;
  }

  p:hover {
    color: ${colorsVariables.white};
  }
`;

const MainNav = styled.div``;

const AdminNav = styled.div`
  margin-bottom: 1.5rem;
`;

const AdminExtended = styled.div``;

export default Dropdown;
