import React, { useEffect, useState } from 'react';
import GuestCard from './Guest';
import Loader from '../Loader';
import useIsTokenExpired from '../../hooks/useIsTokenExpired';
import axios from 'axios';
import useFetchTableGuests from '../../hooks/useFetchTableGuests';
import { GuestInfo } from '../../ts/Guest';
import calculateTotalGuests from '../../helpers/calculateTotalGuests';
import NewGuest from './NewGuest';
import Button from '../Button';
import { FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import styled from 'styled-components';
import calculateTakenSeats from '../../helpers/calculateTakenSeats';

interface GuestsProps {
  weddingId: number;
  tableId: number;
  setTotalGuests: React.Dispatch<React.SetStateAction<number>>;
  setTakenSeats?: React.Dispatch<React.SetStateAction<number>>;
  usage?: 'wedding-summary' | 'manage-wedding' | 'manage-tables';
  updateGuestArrival?: (
    guestId: number,
    status: 'arrived' | 'notarrived'
  ) => void;
  guestArrivalLoading?: boolean;
  guestArrivalError?: string;
  guestArrivalSuccess?: boolean;
  setGuestArrivalSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  keyword?: string | undefined;
}

const Guests: React.FC<GuestsProps> = ({
  weddingId,
  tableId,
  setTotalGuests,
  setTakenSeats,
  usage,
  keyword,
}) => {
  const [isAddingGuest, setIsAddingGuest] = useState<boolean>(false);
  const [guestAddPending, setGuestAddPending] = useState<boolean>(false);
  const [guestAddSuccess, setGuestAddSuccess] = useState<boolean>(false);
  const [guestAddError, setGuestAddError] = useState<string>('');
  useIsTokenExpired(guestAddError);

  const [guestEditPending, setGuestEditPending] = useState<boolean>(false);
  const [guestEditSuccess, setGuestEditSuccess] = useState<boolean>(false);
  const [guestEditError, setGuestEditError] = useState<string>('');
  useIsTokenExpired(guestEditError);

  const [guestDeletionPending, setGuestDeletionPending] = useState<boolean>(
    false
  );
  const [guestDeletionSuccess, setGuestDeletionSuccess] = useState<boolean>(
    false
  );
  const [guestDeletionError, setGuestDeletionError] = useState<string>('');
  useIsTokenExpired(guestDeletionError);

  const [guestArrivalSuccess, setGuestArrivalSuccess] = useState<boolean>(
    false
  );
  const [guestArrivalLoading, setGuestArrivalLoading] = useState<boolean>(
    false
  );
  const [guestArrivalError, setGuestArrivalError] = useState<string>('');
  useIsTokenExpired(guestArrivalError);

  const [
    guests,
    isLoadingGuests,
    guestsError,
    getTableGuests,
  ] = useFetchTableGuests(weddingId, tableId);
  useIsTokenExpired(guestsError);

  const addGuest = async (guestInfo: GuestInfo): Promise<void> => {
    try {
      setGuestAddPending(true);
      await axios.post(
        `/weddings/${weddingId}/tables/${tableId}/guests`,
        guestInfo
      );
      setGuestAddPending(false);
      setGuestAddSuccess(true);
    } catch (error) {
      setGuestAddPending(false);
      setGuestAddError(error?.response?.data?.message);
    }
  };

  const updateGuest = async (
    guestId: number,
    guestInfo: GuestInfo
  ): Promise<void> => {
    try {
      setGuestEditPending(true);
      await axios.patch(`/weddings/${weddingId}/guests/${guestId}`, guestInfo);
      setGuestEditPending(false);
      setGuestEditSuccess(true);
    } catch (error) {
      setGuestEditPending(false);
      setGuestEditError(error?.response?.data?.message);
    }
  };

  const deleteGuest = async (guestId: number): Promise<void> => {
    try {
      setGuestDeletionPending(true);
      await axios.delete(`/weddings/${weddingId}/guests/${guestId}`);
      setGuestDeletionPending(false);
      setGuestDeletionSuccess(true);
    } catch (error) {
      setGuestDeletionPending(false);
      setGuestDeletionError(error?.response?.data?.message);
    }
  };

  const updateGuestArrival = (
    guestId: number,
    status: 'arrived' | 'notarrived',
    totalArrival?: number
  ): void => {
    try {
      setGuestArrivalLoading(true);
      axios.patch(`/weddings/${+weddingId}/guests/${guestId}/${status}`, {
        totalArrival,
      });
      setGuestArrivalLoading(false);
      setGuestArrivalSuccess(true);
    } catch (error) {
      setGuestArrivalLoading(false);
      setGuestArrivalError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    setTotalGuests(calculateTotalGuests(guests));
    setTakenSeats && setTakenSeats(calculateTakenSeats(guests));
  }, [guests, setTotalGuests, setTakenSeats]);

  useEffect(() => {
    getTableGuests();
  }, [getTableGuests]);

  useEffect(() => {
    if (guestAddSuccess) {
      setGuestAddSuccess(false);
      getTableGuests();
    }
  }, [guestAddSuccess, getTableGuests]);

  useEffect(() => {
    if (guestDeletionSuccess) {
      setGuestDeletionSuccess(false);
      getTableGuests();
    }
  }, [guestDeletionSuccess, getTableGuests]);

  useEffect(() => {
    if (guestEditSuccess) {
      setGuestEditSuccess(false);
      getTableGuests();
    }
  }, [guestEditSuccess, getTableGuests]);

  useEffect(() => {
    if (setGuestArrivalSuccess) {
      if (guestArrivalSuccess) {
        setGuestArrivalSuccess(false);
        getTableGuests();
      }
    }
  }, [guestArrivalSuccess, setGuestArrivalSuccess, getTableGuests]);

  return (
    <>
      {usage === 'manage-tables' && (
        <AddGuestButton>
          <Button
            text={isAddingGuest ? 'הסתרת הוספה' : 'הוספת אורח'}
            icon={isAddingGuest ? <FaMinusSquare /> : <FaPlusSquare />}
            color='white'
            bgColor='secDark'
            handleClick={() => setIsAddingGuest(!isAddingGuest)}
          />
        </AddGuestButton>
      )}
      {isAddingGuest && (
        <NewGuest
          guestAddPending={guestAddPending}
          guestAddError={guestAddError}
          guestAddSuccess={guestAddSuccess}
          addGuest={addGuest}
        />
      )}
      {isLoadingGuests && <Loader message='טוען אורחים...' />}
      {guests &&
        guests.length >= 1 &&
        guests.map((guest) => (
          <GuestCard
            guest={guest}
            handleDelete={deleteGuest}
            handleUpdate={updateGuest}
            guestDeletionPending={guestDeletionPending}
            guestEditPending={guestEditPending}
            guestEditError={guestEditError}
            usage={usage}
            updateGuestArrival={updateGuestArrival}
            guestArrivalLoading={guestArrivalLoading}
            guestArrivalError={guestArrivalError}
            keyword={keyword}
            key={guest.id}
          />
        ))}
    </>
  );
};

const AddGuestButton = styled.div`
  button {
    font-weight: 400;
    padding: 0.2rem 0.4rem;
    border: 1px solid black;
    margin: 0.2rem 0 0.4rem 0;
    &:hover {
      padding: 0.2rem 0.6rem;
    }
  }
`;

export default Guests;
