export function parseTableNumber(number: string): string {
  function parseSingleDigit(number: string): string {
    number = number.slice(2);
    return number;
  }

  function parseDoubleDigit(number: string): string {
    number = number.slice(1);
    return number;
  }

  function determineParsing(number: string): string {
    if (+number.slice(1) < 10) {
      return parseSingleDigit(number);
    }

    if (+number.slice(1) >= 10) {
      return parseDoubleDigit(number);
    }

    return number;
  }

  if (number.includes(' ')) {
    const splittedNumber = number.split(' ');

    return splittedNumber.map(determineParsing).join(' ');
  }

  return determineParsing(number);
}
