import { Guest, GuestInfo, SetGuestInfoErrors } from '../ts/Guest';

export const validateGuestForm = (
  guest: GuestInfo,
  setGuestErrors: SetGuestInfoErrors
): void => {
  setGuestErrors.setNameError(
    guest.name.length > 1 ? '' : 'חובה לציין את שם האורח'
  );
};

export const isFormValid = (guestErrors: string[], guest?: Guest): boolean => {
  const error = guestErrors.filter((error) => error);
  if (error.length) {
    return false;
  }

  return true;
};
