import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SelectTotalArrivalProps {
  totalArrival: number;
  setTotalArrival: React.Dispatch<React.SetStateAction<number>>;
  arrived?: boolean;
}

const SelectTotalArrival: React.FC<SelectTotalArrivalProps> = ({
  setTotalArrival,
  totalArrival,
  arrived,
}) => {
  return (
    <>
      <select
        style={{ marginTop: '.5rem' }}
        onChange={(e) => setTotalArrival(Number(e.target.value))}
        value={totalArrival || 1}
      >
        <option hidden>סה"כ</option>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((number) => (
          <option value={number || 1} key={uuidv4()}>
            סה"כ {number}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectTotalArrival;
