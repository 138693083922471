import React from 'react';
import logo from '../../images/logo.png';
import styled from 'styled-components';
import { colorsVariables, fadeIn } from '../../GlobalStyles';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import mit4mit from '../../images/mit4mit.png';

const Footer = () => {
  return (
    <OutterFooter>
      <StyledFooter>
        <Mit4Mit>
          <a
            href='https://www.mit4mit.co.il/biz/90972'
            target='_blank'
            rel='noreferrer'
          >
            <img src={mit4mit} alt='mit4mit logo' />
            להמלצות וחוות דעת מזוגות שכבר נהנו מהשירותים שלנו ניתן לבקר בדף שלנו
          </a>
        </Mit4Mit>

        <img src={logo} alt='logo' />
        <Links>
          <a
            href='https://www.facebook.com/focusevent10/'
            target='_blank'
            rel='noreferrer'
          >
            <FaFacebook /> עשו לנו לייק בפייסבוק
          </a>

          <a
            href='https://www.linkedin.com/in/david-sharki-925892204/
          '
            target='_blank'
            rel='noreferrer'
          >
            <FaLinkedin /> &copy; Built by David Sharki
          </a>
        </Links>
      </StyledFooter>
    </OutterFooter>
  );
};

const OutterFooter = styled.footer`
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: ${colorsVariables.secDark};
  margin-top: 1rem;
  padding: 0.5rem 0;

  @media (max-width: 600px) {
    padding-bottom: 3rem;
  }
`;

const StyledFooter = styled.div`
  width: 1500px;
  max-width: 93%;
  margin: 0 auto;
  color: white;
  text-align: center;
  position: relative;

  p {
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  img {
    width: 8rem;
    display: inline-block;
  }
`;

const Links = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-right: 1rem;
  svg {
    vertical-align: middle;
  }
  a {
    display: block;
    font-size: 0.7rem;
    color: ${colorsVariables.mainDark};

    animation: ${fadeIn};
    animation-duration: 0.8s;
    transition: all 0.5s ease-out;

    &:hover {
      color: ${colorsVariables.white};
    }
    + a {
      margin-top: 0.4rem;
    }
  }

  svg {
    color: ${colorsVariables.white};

    &:first-of-type {
      margin-left: 0.2rem;
    }

    &:nth-of-type(1) {
      margin-right: 0.2rem;
    }

    &:hover {
      color: ${colorsVariables.mainDark};
    }
  }
`;

const Mit4Mit = styled.div`
  display: block;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  a {
    color: ${colorsVariables.mainDark};
    &:hover {
      color: ${colorsVariables.white};
    }
  }

  img {
    color: ${colorsVariables.white};
    margin-left: 0.3rem;
    vertical-align: middle;
    max-height: 1rem;
    max-width: 1rem;

    &:hover {
      color: ${colorsVariables.mainDark};
    }
  }
`;

export default Footer;
