import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation, useParams } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  colorsVariables,
  cssVariables,
  GlobalPageInit,
} from '../../GlobalStyles';
import { getWedding } from '../../redux/weddings/actions';
import DisplayError from '../../shared/components/DisplayError';
import Loader from '../../shared/components/Loader';
import WeddingInfo from '../../shared/components/Wedding/WeddingInfo';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useEmployeeAuth from '../../shared/hooks/useEmployeeAuth';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import useScrollTop from '../../shared/hooks/useScrollTop';
import { UserRole } from '../../shared/ts/User';
import { RootState } from '../../store';
import ManageGuests from './ManageGuests';
import Tables from './Tables';

const ManageOngoingWeddings: React.FC = () => {
  const history = useHistory();

  useAuthorizedRoles([UserRole.ADMIN, UserRole.EMPLOYEE]);
  useScrollTop();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { weddingId } = useParams<{ weddingId: string }>();

  const { isLoading, error, wedding } = useSelector(
    (state: RootState) => state.wedding
  );
  useIsTokenExpired(error);
  useEmployeeAuth(wedding);

  useEffect(() => {
    dispatch(getWedding(+weddingId));
  }, [dispatch, weddingId]);

  useEffect(() => {
    if (error === 'אין לך הרשאה לנהל הושבה באירוע הזה') {
      dispatch({ type: 'WEDDING_GET_RESET' });
      history.replace('/');
    }
  }, [error, history, dispatch]);

  return (
    <StyledManageOngoingWedding>
      {isLoading ? (
        <Loader message='טוען אירוע...' />
      ) : error ? (
        <DisplayError error={error} />
      ) : !isLoading && wedding ? (
        <>
          <StyledWeddingInfo>
            <h2>ניהול האירוע של {wedding.couple}:</h2>
            <WeddingInfo wedding={wedding} usage='manage-ongoing' />
            <StyledRemarks>
              <span>הערות:</span> {wedding.remarks}
            </StyledRemarks>
          </StyledWeddingInfo>
          <StyledTabs>
            <h3>ניהול:</h3>
            <NavLink
              to={`/ongoing-weddings/manage/${weddingId}/tables/number=/name=/guest=/relation=/page=1`}
              isActive={() =>
                pathname.includes(
                  `/ongoing-weddings/manage/${weddingId}/tables`
                )
              }
            >
              שולחנות
            </NavLink>
            <NavLink
              to={`/ongoing-weddings/manage/${weddingId}/guests/number=/name=/guest=/relation=/page=1`}
              isActive={() =>
                pathname.includes(
                  `/ongoing-weddings/manage/${weddingId}/guests`
                )
              }
            >
              אורחים
            </NavLink>
          </StyledTabs>
        </>
      ) : (
        ''
      )}
      <Switch>
        <Route
          path='/ongoing-weddings/manage/:weddingId/tables/number=:number?/name=:name?/guest=:guest?/relation=:relation?/page=:page'
          component={Tables}
        />
        <Route
          path='/ongoing-weddings/manage/:weddingId/guests/number=:number?/name=:name?/guest=:guest?/relation=:relation?/page=:page'
          render={() => (
            <ManageGuests
              committedToVenue={wedding.committedToVenue}
              usage='manage-wedding'
              linkTo={`/ongoing-weddings/manage/${wedding.id}/guests`}
            />
          )}
        />
      </Switch>
    </StyledManageOngoingWedding>
  );
};

const StyledManageOngoingWedding = styled(GlobalPageInit)`
  margin-top: 1rem;
`;

const StyledWeddingInfo = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 0.3rem;

  p {
    white-space: nowrap;
    span {
      font-weight: bold;
    }
  }

  p + p {
    margin-top: 0.4rem;
  }

  div + p {
    margin-top: 0.4rem;
  }
`;

const StyledTabs = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  h3 {
    margin-bottom: 0.5rem;
  }
  a {
    box-shadow: ${cssVariables.boxShadow};
    border: 1px solid black;
    padding: 0.3rem 0.6rem;
    transform: translateY(-4px);
    display: inline-block;
  }

  a + a {
    margin-right: 0.5rem;
  }
  .active {
    color: ${colorsVariables.mainDark};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    transform: translateY(0);
  }
`;

const StyledRemarks = styled.p`
  white-space: pre-wrap !important;
`;
export default ManageOngoingWeddings;
