import { useEffect } from 'react';
import { validateTableForm } from '../helpers/TableValidation';
import { SetTableInfoErrors, TableInfo } from '../ts/Table';

export default function useTableFormValidation(
  table: TableInfo,
  setTableErrors: SetTableInfoErrors
) {
  useEffect(() => {
    validateTableForm(table, setTableErrors);
  }, [table, setTableErrors]);
}
