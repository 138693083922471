import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { isFormValid } from '../../helpers/TableValidation';
import useTableFormValidation from '../../hooks/useTableFormValidation';
import Button from '../Button';
import DisplayError from '../DisplayError';
import Input from '../Input';
import SelectRelation from './SelectRelation';
import { v4 as uuidv4 } from 'uuid';
import {
  SetTableInfo,
  SetTableInfoErrors,
  TableInfo,
  TableInfoErrors,
} from '../../ts/Table';

interface EditTableProps {
  table: TableInfo;
  setTable: SetTableInfo;
  tableErrors: TableInfoErrors;
  setTableErrors: SetTableInfoErrors;
  tableId: number;
  editHandler: (tableId: number, editInfo: TableInfo) => void;
  tableEditPending: boolean;
  tableEditError: string;
}

const EditTable: React.FC<EditTableProps> = ({
  table,
  setTable,
  tableErrors,
  setTableErrors,
  editHandler,
  tableEditPending,
  tableEditError,
  tableId,
}) => {
  const editTableHandler = (e: FormEvent) => {
    e.preventDefault();
    if (isFormValid(tableErrors, table)) {
      editHandler(tableId, table);
    }
  };

  useTableFormValidation(table, setTableErrors);

  return (
    <StyledEdit onSubmit={editTableHandler}>
      <Input
        label='שם השולחן:'
        onChange={setTable.setName}
        value={table.name}
        required
      />
      <Input
        label='מספר שולחן:'
        onChange={setTable.setNumber}
        value={table.number}
        required
      />
      <Input
        label='מספר כסאות:'
        onChange={setTable.setSeats}
        value={table.seats}
        type='number'
        required
      />
      <SelectRelation
        relation={table.relation}
        setRelation={setTable.setRelation}
        text={table.relation}
      />
      <ShowErrors>
        {tableErrors
          .filter((error) => error)
          .map((error) => (
            <DisplayError error={error} key={uuidv4()} />
          ))}
        {tableEditError && <DisplayError error={tableEditError} />}
      </ShowErrors>
      <Button
        text='סיום עריכה'
        color='mainDark'
        bgColor='secDark'
        handleClick={editTableHandler}
        disabled={tableEditPending}
        type='submit'
      />
    </StyledEdit>
  );
};

const StyledEdit = styled.form`
  button {
    margin-top: 0.5rem;
  }

  select {
    margin-top: 0.5rem;
  }

  div + div {
    margin-top: 0.3rem;
  }
`;
const ShowErrors = styled.div`
  p {
    width: 100%;
  }
`;

export default EditTable;
