import { Wedding } from '../../shared/ts/Wedding';
import {
  WEDDINGS_GET_FAIL,
  WEDDINGS_GET_REQUEST,
  WEDDINGS_GET_SUCCESS,
  WEDDING_POST_FAIL,
  WEDDING_POST_REQUEST,
  WEDDING_POST_SUCCESS,
  WEDDING_POST_RESET,
  WEDDING_GET_REQUEST,
  WEDDING_GET_SUCCESS,
  WEDDING_GET_FAIL,
  WEDDING_EDIT_REQUEST,
  WEDDING_EDIT_SUCCESS,
  WEDDING_EDIT_FAIL,
  WEDDING_EDIT_RESET,
  WEDDING_DELETE_REQUEST,
  WEDDING_DELETE_SUCCESS,
  WEDDING_DELETE_FAIL,
  WEDDING_DELETE_RESET,
  WEDDING_GET_RESET,
} from './actions';

export interface WeddingsState {
  isLoading: boolean;
  weddings: Wedding[];
  pagination: { totalPages: number };
  error: string;
}

export const InitialWeddingsState = {
  isLoading: false,
  weddings: [],
  pagination: { totalPages: null! },
  error: '',
};

type WeddingsAction =
  | WEDDINGS_GET_REQUEST
  | WEDDINGS_GET_SUCCESS
  | WEDDINGS_GET_FAIL;

export const weddingsReducer = (
  state: WeddingsState = InitialWeddingsState,
  action: WeddingsAction
) => {
  switch (action.type) {
    case 'WEDDINGS_GET_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'WEDDINGS_GET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        weddings: action.payload,
        pagination: action.pagination,
      };
    case 'WEDDINGS_GET_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

interface PostWeddingState {
  isLoading: boolean;
  postedWedding: Wedding;
  error: string;
  success: boolean;
}

export const initialPostWeddingState = {
  isLoading: false,
  postedWedding: {} as Wedding,
  error: '',
  success: false,
};

type PostWeddingAction =
  | WEDDING_POST_REQUEST
  | WEDDING_POST_SUCCESS
  | WEDDING_POST_FAIL
  | WEDDING_POST_RESET;

export const weddingPostReducer = (
  state: PostWeddingState = initialPostWeddingState,
  action: PostWeddingAction
): PostWeddingState => {
  switch (action.type) {
    case 'WEDDING_POST_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'WEDDING_POST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        postedWedding: action.payload,
      };
    case 'WEDDING_POST_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'WEDDING_POST_RESET':
      return {
        isLoading: false,
        error: '',
        postedWedding: {} as Wedding,
        success: false,
      };
    default:
      return state;
  }
};

export interface WeddingState {
  isLoading: boolean;
  wedding: Wedding;
  error: string;
  success: boolean;
}

export const initialWeddingState = {
  isLoading: false,
  wedding: {} as Wedding,
  error: '',
  success: false,
};

type WeddingAction =
  | WEDDING_GET_REQUEST
  | WEDDING_GET_SUCCESS
  | WEDDING_GET_FAIL
  | WEDDING_GET_RESET;

export const weddingReducer = (
  state: WeddingState = initialWeddingState,
  action: WeddingAction
): WeddingState => {
  switch (action.type) {
    case 'WEDDING_GET_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'WEDDING_GET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        wedding: action.payload,
      };
    case 'WEDDING_GET_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'WEDDING_GET_RESET':
      return {
        ...initialWeddingState,
      };
    default:
      return state;
  }
};

interface WeddingEditState {
  isLoading: boolean;
  error: string;
  success: boolean;
}

export const InitialWeddingEdit = {
  isLoading: false,
  error: '',
  success: false,
};

type WeddingEditAction =
  | WEDDING_EDIT_REQUEST
  | WEDDING_EDIT_SUCCESS
  | WEDDING_EDIT_FAIL
  | WEDDING_EDIT_RESET;

export const weddingEditReducer = (
  state: WeddingEditState = InitialWeddingEdit,
  action: WeddingEditAction
): WeddingEditState => {
  switch (action.type) {
    case 'WEDDING_EDIT_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'WEDDING_EDIT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'WEDDING_EDIT_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'WEDDING_EDIT_RESET':
      return {
        ...InitialWeddingEdit,
      };
    default:
      return state;
  }
};

interface WeddingDeleteState {
  isLoading: boolean;
  error: string;
  success: boolean;
}

export const InitialWeddingDelete = {
  isLoading: false,
  error: '',
  success: false,
};

type WeddingDeleteAction =
  | WEDDING_DELETE_REQUEST
  | WEDDING_DELETE_SUCCESS
  | WEDDING_DELETE_FAIL
  | WEDDING_DELETE_RESET;

export const weddingDeleteReducer = (
  state: WeddingDeleteState = InitialWeddingDelete,
  action: WeddingDeleteAction
) => {
  switch (action.type) {
    case 'WEDDING_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'WEDDING_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case 'WEDDING_DELETE_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'WEDDING_DELETE_RESET':
      return { ...InitialWeddingDelete };
    default:
      return state;
  }
};
