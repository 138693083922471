import React, { FormEvent, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { TableFormInputs, StyledTableForm } from '../../../GlobalStyles';
import { LoopableTable, Table, TableInfo } from '../../ts/Table';
import { useTableErrors, useTableInfo } from '../../hooks/useTable';
import useTableFormValidation from '../../hooks/useTableFormValidation';
import { isFormValid } from '../../helpers/TableValidation';
import { v4 as uuidv4 } from 'uuid';
import DisplayError from '../DisplayError';
import Input from '../Input';
import SelectRelation from './SelectRelation';
import Button from '../Button';
import { FaPlusSquare } from 'react-icons/fa';

interface NewTableProps {
  tablePostPending: boolean;
  tablePostSuccess: boolean;
  postTableHandler: (tableInfo: TableInfo) => void;
  tablePostError: string;
}

const NewTable: React.FC<NewTableProps> = ({
  tablePostPending,
  postTableHandler,
  tablePostError,
  tablePostSuccess,
}) => {
  const [tableInfo, setTableInfo] = useTableInfo({} as Table);
  const [tableInfoErrors, setTableInfoErrors] = useTableErrors();
  const formRef = useRef<HTMLFormElement>(null!);

  const userHasTyped = (): boolean => {
    if (
      tableInfo.name.trim().length ||
      tableInfo?.relation?.trim().length ||
      tableInfo.seats ||
      tableInfo.number
    ) {
      return true;
    }
    return false;
  };

  useTableFormValidation(tableInfo, setTableInfoErrors);

  const validateBeforePosting = async (
    e: FormEvent,
    setTable: LoopableTable
  ) => {
    e.preventDefault();
    if (isFormValid(tableInfoErrors)) {
      postTableHandler(tableInfo);
    }
  };

  const clearForm = (setTable: LoopableTable): void => {
    for (let value in setTable) {
      setTable[value]('');
    }
    formRef.current.reset();
    formRef.current?.querySelector('input')?.focus();
  };

  useEffect(() => {
    if (tablePostSuccess) clearForm(setTableInfo);
  }, [tablePostSuccess, setTableInfo]);

  return (
    <StyledTableForm
      onSubmit={(e: FormEvent) => validateBeforePosting(e, setTableInfo)}
      ref={formRef}
    >
      <h3>הוספת שולחן</h3>
      <TableFormInputs>
        <Input
          label='שם השולחן:'
          onChange={setTableInfo.setName}
          value={tableInfo.name || ''}
          required
          autofocus
        />
        <SelectRelation
          relation={tableInfo.relation}
          setRelation={setTableInfo.setRelation}
          text='בחירת צד'
        />
      </TableFormInputs>
      <NumberInputs>
        <Input
          label='מספר שולחן:'
          onChange={setTableInfo.setNumber}
          value={tableInfo.number || undefined}
          required
        />
        <Input
          label='מספר כסאות:'
          onChange={setTableInfo.setSeats}
          value={tableInfo.seats || undefined}
          type='number'
          required
        />
      </NumberInputs>
      <ShowErrors>
        {tableInfoErrors &&
          userHasTyped() &&
          tableInfoErrors
            .filter((error) => error)
            .map((error) => <DisplayError error={error} key={uuidv4()} />)}
      </ShowErrors>
      {tablePostError.length >= 1 && <DisplayError error={tablePostError} />}
      <Button
        text={tablePostPending ? 'מוסיף...' : 'הוספת שולחן'}
        icon={<FaPlusSquare />}
        color='secLight'
        bgColor='mainLight'
        type='submit'
        disabled={tablePostPending}
      />
    </StyledTableForm>
  );
};

const ShowErrors = styled.div`
  p {
    width: 100%;
  }
`;

const NumberInputs = styled(TableFormInputs)`
  div {
    width: 30%;
  }

  @media (min-width: 700px) {
    div {
      max-width: 15%;
    }
  }
`;
export default NewTable;
