import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { register } from '../../redux/auth/actions';
import styled from 'styled-components';
import {
  GlobalPageInit,
  StyledLongForm,
  StyledInputs,
  StyledLink,
} from '../../GlobalStyles';
import { UserRole } from '../../shared/ts/User';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useScrollTop from '../../shared/hooks/useScrollTop';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import {
  isValidPassword,
  isValidRole,
  isValidUsername,
} from '../../shared/helpers/UserValidation';
import { v4 as uuidv4 } from 'uuid';
import SelectRole from './SelectRole';
import Button from '../../shared/components/Button';
import Input from '../../shared/components/Input';
import DisplayError from '../../shared/components/DisplayError';
import { Link } from 'react-router-dom';
import PermitAccess from './PermitAccess';
import ManageAccess from '../../shared/components/ManageAccess';

const NewUser: React.FC = () => {
  useAuthorizedRoles([UserRole.ADMIN]);
  useScrollTop();
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null!);

  const {
    registeredUser,
    isLoading,
    error: registerError,
    success,
  } = useSelector((state: RootState) => state.userRegister);
  useIsTokenExpired(registerError);

  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [secondPassword, setSecondPassword] = useState<string>('');
  const [secondPasswordError, setSecondPasswordError] = useState<string>('');
  const [role, setRole] = useState<string | undefined>('');
  const [roleError, setRoleError] = useState<string>('');
  const [isManagingPermissions, setIsManagingPermissions] =
    useState<boolean>(false);
  const [permittedWeddings, setPermittedWeddings] = useState<number[]>([]);

  const errors: string[] = [
    usernameError,
    emailError,
    passwordError,
    secondPasswordError,
    roleError,
    registerError,
  ];

  const isValidEmail = (e: string): boolean => {
    // eslint-disable-next-line
    var filter =
      /^\s*[\w\-_]+(\.[\w\-_]+)*\@[\w\-_]+\.[\w\-_]+(\.[\w\-_]+)*\s*$/;
    if (e.length && String(e).search(filter) === -1) {
      setEmailError('כתובת אימייל אינה תקינה');
      return false;
    } else {
      setEmailError('');
      return false;
    }
  };

  const doesPasswordsMatch = (p1: string, p2: string): boolean => {
    if (p1.length && p2.length && p1 !== p2) {
      setSecondPasswordError('סיסמאות אינן זהות');
      return false;
    } else {
      setSecondPasswordError('');
      return true;
    }
  };

  const validateForm = useCallback((): void => {
    isValidEmail(email);
    isValidPassword(password, setPasswordError);
    doesPasswordsMatch(password, secondPassword);
    isValidUsername(username, setUsernameError);
    isValidRole(role, setRoleError);
  }, [email, password, secondPassword, username, role]);

  const clearForm = useCallback((): void => {
    setUsername('');
    setPassword('');
    setSecondPassword('');
    setEmail('');
    setRole(undefined);
    setPermittedWeddings([]);
    formRef.current?.reset();
  }, []);

  useEffect(() => {
    validateForm();
  }, [username, email, password, secondPassword, role, validateForm]);

  useEffect(() => {
    if (success) {
      clearForm();
    }
  }, [success, clearForm]);

  const resetRegsitrationData = (): void => {
    dispatch({ type: 'USER_REGISTER_RESET' });
  };

  const userRegisterHandler = (e: FormEvent): void => {
    e.preventDefault();
    if (!secondPassword.length) {
      setSecondPasswordError('חובה להזין סיסמה בשנית');
    }
    if (
      username.trim() &&
      email.trim &&
      password.trim() &&
      secondPassword.trim()
    ) {
      if (
        !usernameError &&
        !emailError &&
        !passwordError &&
        !secondPasswordError
      ) {
        dispatch(register(username, email, password, role, permittedWeddings));
      }
    }
  };

  return (
    <StyledNewUser>
      <StyledLongForm onSubmit={userRegisterHandler} ref={formRef}>
        <h3>יצירת משתמש חדש</h3>
        <StyledInputs>
          <Input
            required
            label='שם המשתמש:'
            placeholder='שם'
            onChange={setUsername}
            value={username}
          />
          <Input
            required
            label='אימייל:'
            placeholder='אימייל'
            onChange={setEmail}
            value={email}
          />
          <Input
            required
            type='password'
            label='סיסמה:'
            placeholder='סיסמה'
            onChange={setPassword}
            value={password}
          />
          <Input
            required
            type='password'
            label='סיסמה בשנית:'
            placeholder='סיסמה בשנית'
            onChange={setSecondPassword}
            value={secondPassword}
          />
          <SelectRole role={role} setRole={setRole} text='בחירת תפקיד' />
          <TogglePermissions>
            <ManageAccess
              setIsManagingPermissions={setIsManagingPermissions}
              isManagingPermissions={isManagingPermissions}
            />
          </TogglePermissions>
          {isManagingPermissions && (
            <StyledPermissions>
              <PermitAccess
                permittedWeddings={permittedWeddings}
                setPermittedWeddings={setPermittedWeddings}
              />
            </StyledPermissions>
          )}
          {errors
            .filter((error) => error)
            .map((error) => (
              <DisplayError error={error} key={uuidv4()} />
            ))}
        </StyledInputs>
        {success && registeredUser && (
          <StyledRegisteredUser>
            <h3>משתמש נוצר בהצלחה:</h3>
            <p>שם משתמש: {registeredUser.username}</p>
            <p>אימייל: {registeredUser.email}</p>
            <p>תפקיד: {registeredUser.role}</p>
            <Link to='/admin/weddings/new'>
              <StyledLink>יצירת אירוע חדש</StyledLink>
            </Link>
            <Button
              text='רישום משתמש נוסף'
              handleClick={resetRegsitrationData}
              color='secDark'
              bgColor='mainLight'
            />
          </StyledRegisteredUser>
        )}
        <Button
          text={isLoading ? 'יוצר משתמש...' : 'יצירת משתמש'}
          type='submit'
          bgColor='secDark'
          color='mainDark'
          disabled={isLoading}
        />
      </StyledLongForm>
    </StyledNewUser>
  );
};

const StyledNewUser = styled(GlobalPageInit)`
  h3 {
    @media (min-width: 600px) {
      text-align: center;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }
  }
  select {
    margin-top: 1rem;
    @media (min-width: 600px) {
      width: 80%;
    }
  }
`;

const StyledRegisteredUser = styled.div`
  p {
    width: 100%;
  }
  @media (min-width: 600px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h3 {
      margin-bottom: 0;
    }
  }
`;

const TogglePermissions = styled.div`
  width: 100% !important;
`;

const StyledPermissions = styled.div`
  margin-top: 0 !important;
  width: 100% !important;
`;

export default NewUser;
