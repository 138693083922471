import axios from 'axios';
import { User } from '../../shared/ts/User';
import { AppDispatch } from '../../store';

const appJsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export type USER_REGISTER_REQUEST = {
  type: 'USER_REGISTER_REQUEST';
};

export type USER_REGISTER_SUCCESS = {
  type: 'USER_REGISTER_SUCCESS';
  payload: User;
};

export type USER_REGISTER_FAIL = {
  type: 'USER_REGISTER_FAIL';
  payload: string;
};

export type USER_REGISTER_RESET = {
  type: 'USER_REGISTER_RESET';
};

export const register =
  (
    username: string,
    email: string,
    password: string,
    role: string | undefined,
    permittedWeddings: number[]
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'USER_REGISTER_REQUEST' });
      const { data } = await axios.post(
        '/auth/signup',
        { username, email, password, role, permittedWeddings },
        appJsonConfig
      );

      dispatch({ type: 'USER_REGISTER_SUCCESS', payload: data });
    } catch (error) {
      dispatch({
        type: 'USER_REGISTER_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type USER_LOGIN_REQUEST = {
  type: 'USER_LOGIN_REQUEST';
};

export type USER_LOGIN_SUCCESS = {
  type: 'USER_LOGIN_SUCCESS';
  payload: User;
};

export type USER_LOGIN_FAIL = {
  type: 'USER_LOGIN_FAIL';
  payload: string;
};

export type USER_LOGIN_RESET = {
  type: 'USER_LOGIN_RESET';
};

export const login =
  (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'USER_LOGIN_REQUEST' });
      const { data } = await axios.post(
        'auth/signin',
        { email, password },
        appJsonConfig
      );

      dispatch({ type: 'USER_LOGIN_SUCCESS', payload: data });

      localStorage.setItem('loggedUser', JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: 'USER_LOGIN_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type USER_LOGOUT_REQUEST = {
  type: 'USER_LOGOUT_REQUEST';
};

export type USER_LOGOUT_SUCCESS = {
  type: 'USER_LOGOUT_SUCCESS';
};

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'USER_LOGOUT_REQUEST' });
    await axios.post('/auth/signout');

    dispatch({ type: 'USER_LOGOUT_SUCCESS' });

    localStorage.removeItem('loggedUser');
  } catch (error) {
    dispatch({
      type: 'USER_LOGIN_FAIL',
      payload: error.response.data.message,
    });
  }
};
