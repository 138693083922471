import React from 'react';
import styled from 'styled-components';
import { colorsVariables } from '../../GlobalStyles';

interface InputProps {
  label: string;
  name?: string;
  value?: string | number | readonly string[] | undefined;
  placeholder?: string;
  type?: string;
  onChange: (value: React.SetStateAction<any>) => void;
  required?: boolean;
  autofocus?: boolean;
  min?: any;
}

const Input: React.FC<InputProps> = ({
  label,
  value,
  placeholder,
  type,
  onChange,
  required,
  autofocus,
  min,
  name,
}) => {
  return (
    <StyledInput>
      <label htmlFor={label}>
        {required && <span>*</span>}
        <p>{label}</p>
      </label>
      <input
        autoFocus={autofocus || false}
        type={type || 'text'}
        placeholder={placeholder}
        value={value || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(type === 'number' ? e.target.valueAsNumber : e.target.value)
        }
        min={min || '0'}
        name={name || ''}
      />
    </StyledInput>
  );
};

const StyledInput = styled.div`
  input {
    margin-top: 0.3rem;
    border: 1px solid ${colorsVariables.secDark};
    padding-right: 0.6rem;
  }

  p span {
    color: ${colorsVariables.mainDark};
    font-weight: bold;
    font-size: 0.9rem;
  }

  p {
    margin-top: 0.3rem;
    font-weight: bold;
  }

  label {
    display: flex;
    align-items: flex-end;
    span {
      color: ${colorsVariables.error};
      font-weight: bold;
      margin-left: 0.2rem;
      font-size: 1.1rem;
    }
  }
`;

export default Input;
