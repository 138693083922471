import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router';
import axios from 'axios';
import { Table } from '../../ts/Table';
import Loader from '../Loader';

interface SelectTableProps {
  tableId: string;
  setTableId: React.Dispatch<React.SetStateAction<string>>;
}

const SelectTable: React.FC<SelectTableProps> = ({ tableId, setTableId }) => {
  const [tables, setTables] = useState<Table[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { weddingId } = useParams<{ weddingId: string }>();

  const allTablesOfWedding = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/weddings/${weddingId}/tables?page=1&limit=150`
      );
      setIsLoading(false);
      setTables(data?.data);
    } catch (error) {}
  }, [weddingId]);

  useEffect(() => {
    allTablesOfWedding();
  }, [allTablesOfWedding]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : tables && !isLoading ? (
        <select
          style={{ marginTop: '.5rem' }}
          onChange={(e) => setTableId(e.target.value)}
          value={tableId}
        >
          <option hidden>שינוי מס שולחן</option>
          {tables.map((table) => (
            <option value={table.id} key={uuidv4()}>
              שולחן {table.number}
            </option>
          ))}
        </select>
      ) : (
        ''
      )}
    </>
  );
};

export default SelectTable;
