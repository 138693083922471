import { SetTableInfoErrors, TableInfo } from '../ts/Table';

export const validateTableForm = (
  table: TableInfo,
  setTableErrors: SetTableInfoErrors
): void => {
  setTableErrors.setNumberError(
    table?.number?.length >= 1 ? '' : 'חובה לציין את מספר השולחן'
  );
  setTableErrors.setSeatsError(
    table.seats >= 1 ? '' : 'חובה לציין את מספר הכסאות (כולל רזרבה)'
  );
  setTableErrors.setNameError(
    table.name.length >= 3 ? '' : 'חובה לציין את שם השולחן'
  );
  setTableErrors.setRelationError(
    table.relation ? '' : 'חובה לציין את קשר השולחן לזוג'
  );
};

export const isFormValid = (
  tableErrors: string[],
  table?: TableInfo
): boolean => {
  const error = tableErrors.filter((error) => error);
  if (error.length) {
    return false;
  }
  return true;
};
