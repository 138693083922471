import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { editWedding } from '../../redux/weddings/actions';
import { RootState } from '../../store';
import {
  GlobalPageInit,
  StyledLongForm,
  StyledInputs,
} from '../../GlobalStyles';
import styled from 'styled-components';
import { UserRole } from '../../shared/ts/User';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import useFetchWedding from '../../shared/hooks/useFetchWedding';
import useScrollTop from '../../shared/hooks/useScrollTop';
import {
  useWeddingErrors,
  useWeddingInfo,
} from '../../shared/hooks/useWedding';
import { Inputs } from './Inputs';
import {
  areInputsValid,
  validateForm,
} from '../../shared/helpers/WeddingValidation';
import { v4 as uuidv4 } from 'uuid';
import Input from '../../shared/components/Input';
import DisplayError from '../../shared/components/DisplayError';
import Button from '../../shared/components/Button';
import Loader from '../../shared/components/Loader';
import { FaEdit } from 'react-icons/fa';
import ManageAccess from '../../shared/components/ManageAccess';
import PermitAccess from './PermitAccess';

const EditWedding: React.FC = () => {
  useAuthorizedRoles([UserRole.ADMIN]);
  useScrollTop();
  const dispatch = useDispatch();
  const history = useHistory();
  const { weddingId } = useParams<{ weddingId: string }>();

  const { wedding, error, isLoading } = useFetchWedding(+weddingId);
  useIsTokenExpired(error);

  const {
    error: editWeddingError,
    isLoading: editWeddingPendig,
    success: editWeddingSuccess,
  } = useSelector((state: RootState) => state.weddingEdit);
  useIsTokenExpired(editWeddingError);

  const [weddingInfo, setWeddingInfo] = useWeddingInfo(wedding);
  const [weddingInfoErrors, setWeddingInfoErrors] = useWeddingErrors();
  const [isManagingPermissions, setIsManagingPermissions] =
    useState<boolean>(false);

  const editWeddingHandler = (e: FormEvent) => {
    e.preventDefault();
    if (areInputsValid(weddingInfoErrors, weddingInfo)) {
      dispatch(editWedding(+weddingId, weddingInfo));
    }
  };

  useEffect(() => {
    validateForm(weddingInfo, setWeddingInfoErrors);
  }, [weddingInfo, setWeddingInfoErrors]);

  useEffect(() => {
    if (editWeddingSuccess) {
      dispatch({ type: 'WEDDING_EDIT_RESET' });
      history.push(`/admin/weddings/couple=/location=/remarks=/status=/page=1`);
    }
  }, [editWeddingSuccess, history, weddingId, dispatch]);

  return (
    <StyledEditUserPage>
      {isLoading && <Loader message='טוען פרטי אירוע...' />}
      <StyledLongForm onSubmit={editWeddingHandler}>
        <h2>עריכת האירוע של {wedding.couple || ''}</h2>
        <StyledInputs>
          {Inputs(weddingInfo, setWeddingInfo).map((input, i) => (
            <Input
              label={input.label}
              placeholder={input.placeholder}
              onChange={input.onChange}
              value={input.value}
              required={input.required}
              type={input.type || 'text'}
              key={i}
            />
          ))}
          <textarea
            onChange={(e) => setWeddingInfo.setRemarks(e.target.value)}
            value={weddingInfo.remarks}
          />
          <ManageAccess
            setIsManagingPermissions={setIsManagingPermissions}
            isManagingPermissions={isManagingPermissions}
          />
          {isManagingPermissions && <PermitAccess weddingId={+weddingId} />}
          {weddingInfoErrors
            .filter((error) => error && error !== 'חובה לציין את זהות המשתמש')
            .map((error) => (
              <DisplayError key={uuidv4()} error={error} />
            ))}
        </StyledInputs>
        <Button
          text={editWeddingPendig ? 'עורך...' : 'עריכת אירוע'}
          icon={<FaEdit />}
          color='secDark'
          bgColor='mainLight'
          type='submit'
          disabled={editWeddingPendig}
        />
      </StyledLongForm>
    </StyledEditUserPage>
  );
};

const StyledEditUserPage = styled(GlobalPageInit)`
  @media (min-width: 600px) {
    h2 {
      text-align: center;
      margin: 0;
    }
  }
`;

export default EditWedding;
