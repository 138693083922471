import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cssVariables, StyledLink } from '../../GlobalStyles';
import SelectStatus from './SelectStatus';
import { Wedding } from '../../shared/ts/Wedding';
import Button from '../../shared/components/Button';
import DeleteConfirmation from '../../shared/components/DeleteConfirmation';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaEdit,
  FaTrash,
} from 'react-icons/fa';
import { User } from '../../shared/ts/User';
import { useEffect } from 'react';
import axios from 'axios';
import { useCallback } from 'react';
import { v4 } from 'uuid';
import DisplayError from '../../shared/components/DisplayError';

interface WeddingProps {
  wedding: Wedding;
  changeStatusHandler: (
    weddingId: number | undefined,
    status: string
  ) => Promise<void>;
  deletionHandler: (weddingId: number) => void;
  isDeletionPending: boolean;
}

const WeddingCard: React.FC<WeddingProps> = ({
  wedding,
  deletionHandler,
  isDeletionPending,
  changeStatusHandler,
}) => {
  const [isAwaitingDeletion, setIsAwaitingDeletion] = useState<boolean>(false);
  const [isWatchingManagers, setIsWatchingManagers] = useState<boolean>(false);
  const [managers, setManagers] = useState<User[]>();
  const [managersError, setManagersError] = useState<string>('');

  const getManagers = useCallback(async () => {
    try {
      const { data } = await axios.get(`/users/managed-by/${wedding.id}`);
      setManagers(data);
    } catch (error) {
      setManagersError(error);
    }
  }, [wedding.id]);

  useEffect(() => {
    if (isWatchingManagers && !managers?.length) {
      getManagers();
    }
  }, [isWatchingManagers, managers?.length, getManagers]);

  return (
    <StyledWedding>
      <InfoWrapper>
        <Info>
          <p>
            <span>זוג:</span> {wedding.couple}
          </p>
          <p>
            <span>תאריך:</span> {wedding.date}
          </p>
          <p>
            <span>שעת התחלה:</span> {wedding.startTime}
          </p>
          <p>
            <span>סטטוס:</span>
          </p>
          <SelectStatus
            status={wedding.status}
            text={`ניסוי`}
            setStatus={(status: string) =>
              changeStatusHandler(wedding?.id, status)
            }
          />
        </Info>
        <Info>
          <p>
            <span>מיקום:</span> {wedding.location}
          </p>
          <p>
            <span>מספר אורחים:</span> {wedding.numberOfGuests}
          </p>
          <p>
            <span>כסאות רזרבה:</span> {wedding.reservedSeats}
          </p>
          <p>
            <span>התחייבות לאולם:</span> {wedding.committedToVenue}
          </p>
          <ToggleViewManagers>
            <h4 onClick={() => setIsWatchingManagers(!isWatchingManagers)}>
              צפייה במנהלים{' '}
              {isWatchingManagers ? (
                <FaArrowAltCircleUp />
              ) : (
                <FaArrowAltCircleDown />
              )}
            </h4>
            {managersError && <DisplayError error={managersError} />}
            {managers?.map((manager) => (
              <p key={v4()}>{manager.username}</p>
            ))}
          </ToggleViewManagers>
        </Info>
      </InfoWrapper>
      <Link
        to={`/couple/wedding/${wedding.id}/number=/name=/guest=/relation=/page=1`}
      >
        <StyledLink>צפייה באירוע</StyledLink>
      </Link>
      <StyledRemarks>
        <span>הערות: </span>
        {wedding.remarks}
      </StyledRemarks>
      <ActionButtons>
        <Link to={`/admin/weddings/${wedding.id}/edit`}>
          <Button
            text='עריכה'
            icon={<FaEdit />}
            color='secDark'
            bgColor='white'
          />
        </Link>
        <Button
          icon={<FaTrash />}
          text='מחיקה'
          color='error'
          bgColor='white'
          disabled={isDeletionPending}
          handleClick={() => setIsAwaitingDeletion(true)}
        ></Button>
      </ActionButtons>
      {isAwaitingDeletion && (
        <DeleteConfirmation
          text={`האם אתה בטוח שברצונך למחוק את האירוע של ${wedding.couple}? האירוע יימחק לצמיתות יחד עם כל המידע`}
          onCancel={() => setIsAwaitingDeletion(false)}
          onConfirm={() => {
            wedding?.id && deletionHandler(wedding.id);
          }}
        />
      )}
    </StyledWedding>
  );
};

const StyledWedding = styled.div`
  box-shadow: ${cssVariables.boxShadow};
  padding: 1rem;
  height: max-content;

  p + p {
    margin-top: 0.5rem;
  }
  span {
    font-weight: bold;
  }

  &:nth-of-type(even) {
    background: #fff6f6;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledRemarks = styled.p`
  margin-top: 1rem;
  white-space: pre-wrap;
`;

const Info = styled.div`
  &:nth-of-type(2) {
    margin-right: 1rem;
  }
`;

const ActionButtons = styled.div`
  margin-top: 0.5rem;
  button {
    margin-left: 0.5rem !important;
  }
`;

const ToggleViewManagers = styled.div`
  margin-top: 0.5rem;
  h4 {
    cursor: pointer;
  }
`;

export default WeddingCard;
