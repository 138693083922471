import React from 'react';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import styled from 'styled-components';
import { colorsVariables, fadeIn } from '../GlobalStyles';

type AccordionsProps = {
  isWatchingAccordion: boolean;
  setIsWatchingAccordion: React.Dispatch<React.SetStateAction<boolean>>;
  currentWatchedAccordion: number;
  setCurrentWatchedAccordion: React.Dispatch<React.SetStateAction<number>>;
};

type StyleProps = {
  isCurrentAccordionWatched?: boolean;
};

const Accordions: React.FC<AccordionsProps> = ({
  isWatchingAccordion,
  setIsWatchingAccordion,
  currentWatchedAccordion,
  setCurrentWatchedAccordion,
}) => {
  const currentAccordionHandler = (accordionNumber: number): void => {
    if (isWatchingAccordion && currentWatchedAccordion === accordionNumber) {
      setIsWatchingAccordion(false);
      setCurrentWatchedAccordion(0);
    } else {
      setIsWatchingAccordion(true);
      setCurrentWatchedAccordion(accordionNumber);
    }
  };

  return (
    <StyledAccordions>
      <Accordion
        onClick={() => currentAccordionHandler(1)}
        isCurrentAccordionWatched={currentWatchedAccordion === 1}
      >
        <Header>
          <h3>סגרתי אישורי הגעה - מה הלאה?</h3>
          <FaArrowAltCircleDown />
        </Header>
        {isWatchingAccordion && currentWatchedAccordion === 1 && (
          <ExtendedInfo>
            <p>
              לאחרי שיסתיים שלב אישורי ההגעה ותדעו מי מגיע לחתונה שלכם יתחיל
              השלב המאתגר של חלוקת האורחים לשולחנות – סידור הושבה. סידור הושבה
              לחתונה זאת משימה לא פשוטה כלל. בסידור הושבה משבצים את כל מי שאישר
              הגעה. לא מספיק שהתהליך מורכב הוא גם נעשה תחת לחץ של זמן, בדרך כלל
              חייבים לסיים את תהליך ההושבה עד ארבעה ימים לפני מועד החתונה, כמובן
              במידה ועשיתם אישורי הגעה. לכן חשוב מיד שסגרתם ספק שיטפל באישורי
              ההגעה – לסגור גם ספק שמטפל בהושבה בחתונה. שישריין את התאריך שלכם
              וייתן לכם מענה לכל הצרכים והשאלות לאורך הדרך.
            </p>
          </ExtendedInfo>
        )}
      </Accordion>
      <Accordion
        onClick={() => currentAccordionHandler(2)}
        isCurrentAccordionWatched={currentWatchedAccordion === 2}
      >
        <Header>
          <h3>מהו סידור ההושבה ואיך נכון לגשת אליו?</h3>
          <FaArrowAltCircleDown />
        </Header>
        {isWatchingAccordion && currentWatchedAccordion === 2 && (
          <ExtendedInfo>
            <p>
              סידור ההושבה הוא התהליך שבו אתם משבצים את האורחים שלכם במקום ישיבה
              מוגדר. כך האורח יידע את מקומו ותבטיחו את סדר האירוע.
            </p>
          </ExtendedInfo>
        )}
      </Accordion>
      <Accordion
        onClick={() => currentAccordionHandler(3)}
        isCurrentAccordionWatched={currentWatchedAccordion === 3}
      >
        <Header>
          <h3>למה לבחור בחברת FocusEvent?</h3>
          <FaArrowAltCircleDown />
        </Header>
        {isWatchingAccordion && currentWatchedAccordion === 3 && (
          <ExtendedInfo>
            <p>
              אנחנו מלווים זוגות את כל הדרך לחתונה כבר מעל עשור. הידע שלנו
              בארגון החתונה ובתקשורת מול ספקים וגורמים מהאולם יהפוך את החוויה
              שלכם לנטולת הוצאות מיותרות עם הנאה מקסימלית וללא דאגות.
            </p>
          </ExtendedInfo>
        )}
      </Accordion>
      <Accordion
        onClick={() => currentAccordionHandler(4)}
        isCurrentAccordionWatched={currentWatchedAccordion === 4}
      >
        <Header>
          <h3>למה להשתמש בממשק FocusEvent?</h3>
          <FaArrowAltCircleDown />
        </Header>
        {isWatchingAccordion && currentWatchedAccordion === 4 && (
          <ExtendedInfo>
            <p>
              ממשק FocusEvent מאפשר לכם לארגן את כל ההושבה לקראת האירוע שלכם
              באופן קל ויעיל. ניתן להיכנס למערכת מכל מקום, מכל מחשב ובכל רגע
              נתון באמצעות שם משתמש וסיסמא. מדובר במערכת חדשה ומתקדמת עם
              פונקציות רבות. <br /> ואל דאגה, המערכת מאובטחת לחלוטין, המידע שלכם
              הוא שלכם בלבד ויישמר גם לאחר האירוע עם ניתוח הגעת האורחים.
            </p>
          </ExtendedInfo>
        )}
      </Accordion>
      <Accordion
        onClick={() => currentAccordionHandler(5)}
        isCurrentAccordionWatched={currentWatchedAccordion === 5}
      >
        <Header>
          <h3>איך אנחנו מתחילים להשתמש בממשק FocusEvent וכמה זה עולה?</h3>
          <FaArrowAltCircleDown />
        </Header>
        {isWatchingAccordion && currentWatchedAccordion === 5 && (
          <ExtendedInfo>
            <p>
              צרו איתנו קשר. ברגע שנחליט יחד עם חבילת ההושבה המתאימה לכם תקבלו
              שם משתמש וסיסמא עם מדריך מפורט לממשק.
            </p>
          </ExtendedInfo>
        )}
      </Accordion>
      <Accordion
        onClick={() => currentAccordionHandler(6)}
        isCurrentAccordionWatched={currentWatchedAccordion === 6}
      >
        <Header>
          <h3>יש עוד שירותים שחברת FocusEvent מספקת בתחום החתונות?</h3>
          <FaArrowAltCircleDown />
        </Header>
        {isWatchingAccordion && currentWatchedAccordion === 6 && (
          <ExtendedInfo>
            <p>
              נשמח לעניין אתכם בנושאים נוספים : ניהול אירוע, ניהול אלכוהול,
              ערכות התרעננות ועוד. השאירו פרטים בטופס למטה. מבטיחים שישתלם..
              והמון מזל טוב.
            </p>
          </ExtendedInfo>
        )}
      </Accordion>
    </StyledAccordions>
  );
};

const StyledAccordions = styled.div`
  display: flex;
  flex-direction: column;
  max-height: max-content;
  margin-top: 0.5rem !important;
  margin-bottom: 3rem !important;
  box-shadow: 0 7px 5px rgba(65, 53, 17, 0.3);
  padding: 0.5rem 0.1rem 0.5rem 1rem;

  @media (min-width: 1200px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const Accordion = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: 2rem;
  }
  svg {
    transform: ${(props) =>
      props.isCurrentAccordionWatched === true && 'rotate(180deg)'};
    vertical-align: middle;
    font-size: 1.3rem;
    margin-left: 1rem;
    transition: all 0.3s ease-in;

    background: ${colorsVariables.mainDark};
    color: ${colorsVariables.secDark};
    border-radius: 50%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  font-weight: bold;

  h3 {
    max-width: 80%;
  }
`;

const ExtendedInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.3rem;
  animation: ${fadeIn} 0.3s ease-in;

  p {
    width: 55ch;
    max-width: 90%;
  }
`;
export default Accordions;
