import axios from 'axios';
import { Wedding, WeddingStatus } from '../../shared/ts/Wedding';
import { AppDispatch } from '../../store';

const appJsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export type WEDDINGS_GET_REQUEST = {
  type: 'WEDDINGS_GET_REQUEST';
};

export type WEDDINGS_GET_SUCCESS = {
  type: 'WEDDINGS_GET_SUCCESS';
  payload: Wedding[];
  pagination: { totalPages: number };
};

export type WEDDINGS_GET_FAIL = {
  type: 'WEDDINGS_GET_FAIL';
  payload: string;
};

export type WEDDINGS_GET_RESET = {
  type: 'WEDDINGS_GET_RESET';
};

export const getWeddings =
  (
    couple: string = '',
    location: string = '',
    remarks: string = '',
    status: WeddingStatus | string = '',
    pageNumber: number = 1
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'WEDDINGS_GET_REQUEST' });
      const { data } = await axios.get(
        `/weddings?limit=25&page=${pageNumber}&couple=${couple}&location=${location}&remarks=${remarks}&status=${status}`
      );

      dispatch({
        type: 'WEDDINGS_GET_SUCCESS',
        payload: data.data,
        pagination: { totalPages: data.totalPages },
      });
    } catch (error) {
      dispatch({
        type: 'WEDDINGS_GET_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type WEDDING_POST_REQUEST = {
  type: 'WEDDING_POST_REQUEST';
};

export type WEDDING_POST_SUCCESS = {
  type: 'WEDDING_POST_SUCCESS';
  payload: Wedding;
};

export type WEDDING_POST_FAIL = {
  type: 'WEDDING_POST_FAIL';
  payload: string;
};

export type WEDDING_POST_RESET = {
  type: 'WEDDING_POST_RESET';
};

export const postWedding =
  (newWeddingInfo: Wedding) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'WEDDING_POST_REQUEST' });
      const { data } = await axios.post(
        `/weddings`,
        { ...newWeddingInfo },
        appJsonConfig
      );

      dispatch({ type: 'WEDDING_POST_SUCCESS', payload: data });
    } catch (error) {
      dispatch({
        type: 'WEDDING_POST_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type WEDDING_GET_REQUEST = {
  type: 'WEDDING_GET_REQUEST';
};

export type WEDDING_GET_SUCCESS = {
  type: 'WEDDING_GET_SUCCESS';
  payload: Wedding;
};

export type WEDDING_GET_FAIL = {
  type: 'WEDDING_GET_FAIL';
  payload: string;
};

export type WEDDING_GET_RESET = {
  type: 'WEDDING_GET_RESET';
};

export const getWedding =
  (weddingId: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'WEDDING_GET_REQUEST' });
      const { data } = await axios.get(`/weddings/${weddingId}`);

      dispatch({ type: 'WEDDING_GET_SUCCESS', payload: data });
    } catch (error) {
      dispatch({
        type: 'WEDDING_GET_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type WEDDING_EDIT_REQUEST = {
  type: 'WEDDING_EDIT_REQUEST';
};

export type WEDDING_EDIT_SUCCESS = {
  type: 'WEDDING_EDIT_SUCCESS';
  payload: Wedding;
};

export type WEDDING_EDIT_FAIL = {
  type: 'WEDDING_EDIT_FAIL';
  payload: string;
};

export type WEDDING_EDIT_RESET = {
  type: 'WEDDING_EDIT_RESET';
  payload: string;
};

export const editWedding =
  (weddingId: number, weddingInfo: Wedding) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'WEDDING_EDIT_REQUEST' });
      const { data } = await axios.patch(
        `/weddings/${weddingId}`,
        weddingInfo,
        appJsonConfig
      );

      dispatch({ type: 'WEDDING_EDIT_SUCCESS', payload: data });
    } catch (error) {
      dispatch({
        type: 'WEDDING_EDIT_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type WEDDING_DELETE_REQUEST = {
  type: 'WEDDING_DELETE_REQUEST';
};

export type WEDDING_DELETE_SUCCESS = {
  type: 'WEDDING_DELETE_SUCCESS';
};

export type WEDDING_DELETE_FAIL = {
  type: 'WEDDING_DELETE_FAIL';
  payload: string;
};

export type WEDDING_DELETE_RESET = {
  type: 'WEDDING_DELETE_RESET';
};

export const deleteWedding =
  (weddingId: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'WEDDING_DELETE_REQUEST' });
      const { data } = await axios.delete(`/weddings/${weddingId}`);

      dispatch({ type: 'WEDDING_DELETE_SUCCESS', payload: data });
    } catch (error) {
      dispatch({
        type: 'WEDDING_DELETE_FAIL',
        payload: error.response.data.message,
      });
    }
  };
