import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Wedding } from '../../shared/ts/Wedding';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import DisplayError from '../../shared/components/DisplayError';
import Loader from '../../shared/components/Loader';
import Button from '../../shared/components/Button';

interface PermitAccessProps {
  permittedWeddings: number[];
  setPermittedWeddings: React.Dispatch<React.SetStateAction<number[]>>;
}

const PermitAccess: React.FC<PermitAccessProps> = ({
  permittedWeddings,
  setPermittedWeddings,
}) => {
  const [weddingsToManage, setWeddingsToManage] = useState<Wedding[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const getWeddings = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get('/weddings?limit=200');
      setWeddingsToManage(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  }, []);

  const permitUser = (weddingId: number | undefined) => {
    if (!weddingId) return;
    if (permittedWeddings.includes(weddingId)) {
      const index = permittedWeddings.indexOf(weddingId);
      const newPermittedEvents = [...permittedWeddings];
      newPermittedEvents.splice(index, 1);
      setPermittedWeddings(newPermittedEvents);
    } else {
      setPermittedWeddings([...permittedWeddings, weddingId]);
    }
  };

  useEffect(() => {
    getWeddings();
  }, [getWeddings]);

  return (
    <StyledPermit>
      {isLoading && <Loader />}
      {error && <DisplayError error={error} />}
      {weddingsToManage?.length &&
        weddingsToManage?.map((wedding) => (
          <Button
            text={`${wedding.couple} - ${wedding.date}`}
            color='secDark'
            bgColor={
              wedding.id && permittedWeddings.includes(wedding.id)
                ? 'arrived'
                : 'third'
            }
            key={uuidv4()}
            handleClick={() => permitUser(wedding.id)}
          />
        ))}
    </StyledPermit>
  );
};

const StyledPermit = styled.div`
  width: 80% !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  button {
    margin-top: 0;
    border-radius: 30px;
  }
`;

export default PermitAccess;
