import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CardsWrapper, GlobalPageInit } from '../GlobalStyles';
import { Wedding } from '../shared/ts/Wedding';
import { UserRole } from '../shared/ts/User';
import useAuthorizedRoles from '../shared/hooks/useAuthorizedRoles';
import useIsTokenExpired from '../shared/hooks/useIsTokenExpired';
import useScrollTop from '../shared/hooks/useScrollTop';
import { v4 as uuidv4 } from 'uuid';

import OngoingWeddingCard from './OngoingWeddingCard';
import Loader from '../shared/components/Loader';
import DisplayError from '../shared/components/DisplayError';

const OngoingWeddings: React.FC = () => {
  useAuthorizedRoles([UserRole.EMPLOYEE, UserRole.ADMIN]);
  useScrollTop();

  const [weddings, setWeddings] = useState<Wedding[]>([]);
  const [isLoadingWeddings, setIsLoadingWeddings] = useState<boolean>(false);
  const [weddingsError, setWeddingError] = useState<string>('');
  useIsTokenExpired(weddingsError);

  const getOngoingWeddings = async (): Promise<void> => {
    try {
      setIsLoadingWeddings(true);
      const { data } = await axios.get('/weddings/ongoing');
      setWeddings(data);
      setIsLoadingWeddings(false);
    } catch (error) {
      setIsLoadingWeddings(false);
      setWeddingError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getOngoingWeddings();
  }, []);

  return (
    <OngoingWeddingsWrapper>
      <h3>אירועים פעילים:</h3>
      {isLoadingWeddings ? (
        <Loader message='טוען אירועים...' />
      ) : weddingsError ? (
        <DisplayError error={weddingsError} />
      ) : !isLoadingWeddings && weddings ? (
        <OngoingWeddingsCards>
          {weddings.length === 0 ? (
            <p>אין אירועים פעילים כרגע</p>
          ) : (
            weddings.map((wedding) => (
              <OngoingWeddingCard wedding={wedding} key={uuidv4()} />
            ))
          )}
        </OngoingWeddingsCards>
      ) : (
        ''
      )}
    </OngoingWeddingsWrapper>
  );
};

const OngoingWeddingsWrapper = styled(GlobalPageInit)`
  margin-top: 1rem;
`;

const OngoingWeddingsCards = styled(CardsWrapper)`
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

export default OngoingWeddings;
