import {
  Wedding,
  WeddingErrors,
  WeddingErrorsSetters,
  WeddingSetters,
} from '../ts/Wedding';

export const isValidStringInput = (
  input: string,
  setInputError: React.Dispatch<React.SetStateAction<string>>,
  inputName: string
): boolean => {
  if (!input) return false;
  if (input.length && input.length < 3) {
    setInputError(`${inputName} חייב להכיל לפחות 3 תווים`);
    return false;
  } else {
    setInputError('');
    return true;
  }
};

export const isValidNumberInput = (
  input: number,
  setInputError: React.Dispatch<React.SetStateAction<string>>,
  inputName: string
): boolean => {
  if (!input) {
    setInputError(`חובה לציין את ${inputName}`);
    return false;
  } else {
    setInputError('');
    return true;
  }
};

export const formHasErrors = (errorArray: WeddingErrors) => {
  const error = errorArray.find((error) => error.length);
  return error ? true : false;
};

interface LoopableWedding extends Wedding {
  [key: string]: any;
}

export const hasEmptyInput = (weddingInfo: LoopableWedding): boolean => {
  for (let key in weddingInfo) {
    if (!weddingInfo[key]) {
      if (key !== 'remarks') return true;
    }
    if (typeof weddingInfo[key] === 'number' && weddingInfo[key] < 1) {
      return true;
    } else if (
      typeof weddingInfo[key] === 'string' &&
      weddingInfo[key].length < 1
    ) {
      return true;
    }
  }
  return false;
};

export const validateForm = (
  wedding: Wedding,
  setWeddingError: WeddingErrorsSetters
): void => {
  isValidStringInput(wedding.couple, setWeddingError.setCoupleError, 'שם הזוג');
  isValidStringInput(
    wedding.location,
    setWeddingError.setLocationError,
    'מיקום האירוע'
  );
  isValidStringInput(
    wedding.date,
    setWeddingError.setDateError,
    'תאריך האירוע'
  );
  isValidStringInput(
    wedding.startTime,
    setWeddingError.setStartTimeError,
    'שעת תחילת האירוע'
  );
  isValidNumberInput(
    wedding.numberOfGuests,
    setWeddingError.setNumberOfGuestsError,
    'מספר האורחים'
  );
  isValidNumberInput(
    wedding.committedToVenue,
    setWeddingError.setCommittedToVenueError,
    'ההתחייבות לאולם'
  );
  isValidNumberInput(
    wedding.reservedSeats,
    setWeddingError.setReservedSeatsError,
    'מספר כסאות הרזרבה'
  );
  isValidNumberInput(
    wedding.userId,
    setWeddingError.setUserIdError,
    'זהות המשתמש'
  );
};

export const areInputsValid = (
  weddingErrors: WeddingErrors,
  weddingInfo: Wedding
): boolean => {
  if (!formHasErrors(weddingErrors) && !hasEmptyInput(weddingInfo)) {
    return true;
  } else {
    return false;
  }
};

export const clearFormInputs = (setWedding: WeddingSetters): void => {
  setWedding.setLocation('');
  setWedding.setCouple('');
  setWedding.setNumberOfGuests(null!);
  setWedding.setCommittedToVenue(null!);
  setWedding.setReservedSeats(null!);
  setWedding.setUserId(null!);
  setWedding.setDate('');
  setWedding.setStartTime('');
  setWedding.setRemarks('  ');
};
