import React from 'react';
import styled from 'styled-components';
import { colorsVariables } from '../../GlobalStyles';
import { Link } from 'react-router-dom';
import useComponentVisible from '../hooks/useComponentVisibile';
import { FaBars } from 'react-icons/fa';
import logo from '../../images/logo.png';
import Dropdown from './Dropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Nav = () => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  const toggleOnClick = (action: any) => {
    setIsComponentVisible(action);
  };
  const { loggedUser } = useSelector((state: RootState) => state.userLogin);

  return (
    <Wrapper ref={ref}>
      <OutterNav>
        <StyledNav>
          <SecondaryNav>
            <FaBars
              onClick={() => toggleOnClick((prevOpen: any) => !prevOpen)}
            />
          </SecondaryNav>
          <StyledText onClick={() => toggleOnClick(false)}>
            <Link to='/'>
              <h3>ניהול הושבה חכם</h3>
            </Link>
          </StyledText>
          <Logo onClick={() => toggleOnClick(false)}>
            <Link to='/'>
              <img src={logo} alt='logo' />
            </Link>
          </Logo>
        </StyledNav>
      </OutterNav>
      {isComponentVisible && (
        <Dropdown toggleOnClick={toggleOnClick} loggedUser={loggedUser} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
`;

const OutterNav = styled.div`
  top: 0;
  width: 100%;
  height: max-content;
  z-index: 3;
  padding: 0.5rem 0 0.5rem 0;

  background: ${colorsVariables.secDark};
`;

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 1500px;
  max-width: 93%;
`;

const Logo = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  img {
    width: 5rem;
  }

  @media (min-width: 600px) {
    img {
      width: 8rem;
    }
  }
`;

const StyledText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  h3 {
    color: ${colorsVariables.white};
    font-size: 0.8rem;
    &:hover {
      color: ${colorsVariables.mainDark};
    }
  }

  @media (min-width: 600px) {
    h3 {
      font-size: 1.4rem;
    }
  }
`;

const SecondaryNav = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  svg {
    cursor: pointer;
    font-size: 1.3rem;
    color: ${colorsVariables.white};
    &:hover {
      color: ${colorsVariables.mainDark};
    }
    @media (min-width: 600px) {
      font-size: 2rem;
    }
  }
`;

export default Nav;
