import axios from 'axios';
import { Table, TableInfo } from '../../shared/ts/Table';
import { AppDispatch } from '../../store';

const appJsonConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export type TABLES_GET_REQUEST = {
  type: 'TABLES_GET_REQUEST';
};

export type TABLES_GET_SUCCESS = {
  type: 'TABLES_GET_SUCCESS';
  payload: Table[];
  pagination: { totalPages: number };
};

export type TABLES_GET_FAIL = {
  type: 'TABLES_GET_FAIL';
  payload: string;
};

export const getTables = (
  weddingId: number,
  number: number | string = '',
  name: string = '',
  relation: string = '',
  guestName: string = '',
  pageNumber: number = 1
) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'TABLES_GET_REQUEST' });
    const { data } = await axios.get(
      `/weddings/${weddingId}/tables?limit=25&page=${pageNumber}&number=${number}&name=${name}&relation=${relation}&guestName=${guestName}`
    );

    dispatch({
      type: 'TABLES_GET_SUCCESS',
      payload: data.data,
      pagination: { totalPages: data.totalPages },
    });
  } catch (error) {
    dispatch({
      type: 'TABLES_GET_FAIL',
      payload: error.response.data.message,
    });
  }
};

export type TABLE_POST_REQUEST = {
  type: 'TABLE_POST_REQUEST';
};

export type TABLE_POST_SUCCESS = {
  type: 'TABLE_POST_SUCCESS';
};

export type TABLE_POST_FAIL = {
  type: 'TABLE_POST_FAIL';
  payload: string;
};

export type TABLE_POST_RESET = {
  type: 'TABLE_POST_RESET';
};

export const postTable = (weddingId: number, tableInfo: TableInfo) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: 'TABLE_POST_REQUEST' });
    await axios.post(`/weddings/${weddingId}/tables`, tableInfo, appJsonConfig);

    dispatch({ type: 'TABLE_POST_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'TABLE_POST_FAIL',
      payload: error.response.data.message,
    });
  }
};

export type TABLE_EDIT_REQUEST = {
  type: 'TABLE_EDIT_REQUEST';
};

export type TABLE_EDIT_SUCCESS = {
  type: 'TABLE_EDIT_SUCCESS';
};

export type TABLE_EDIT_FAIL = {
  type: 'TABLE_EDIT_FAIL';
  payload: string;
};

export type TABLE_EDIT_RESET = {
  type: 'TABLE_EDIT_RESET';
};

export const editTable = (
  weddingId: number,
  tableId: number,
  editInfo: TableInfo
) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'TABLE_EDIT_REQUEST' });
    await axios.patch(
      `/weddings/${weddingId}/tables/${tableId}`,
      editInfo,
      appJsonConfig
    );

    dispatch({ type: 'TABLE_EDIT_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'TABLE_EDIT_FAIL',
      payload: error.response.data.message,
    });
  }
};

export type TABLE_DELETE_REQUEST = {
  type: 'TABLE_DELETE_REQUEST';
};

export type TABLE_DELETE_SUCCESS = {
  type: 'TABLE_DELETE_SUCCESS';
};

export type TABLE_DELETE_FAIL = {
  type: 'TABLE_DELETE_FAIL';
  payload: string;
};

export type TABLE_DELETE_RESET = {
  type: 'TABLE_DELETE_RESET';
};

export const deleteTable = (weddingId: number, tableId: number) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: 'TABLE_DELETE_REQUEST' });
    await axios.delete(`/weddings/${weddingId}/tables/${tableId}`);

    dispatch({ type: 'TABLE_DELETE_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'TABLE_DELETE_FAIL',
      payload: error.response.data.message,
    });
  }
};
