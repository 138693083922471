import React, { FormEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useGuest, useGuestErrors } from '../../hooks/useGuest';
import { Guest, GuestInfo } from '../../ts/Guest';
import Button from '../Button';
import Input from '../Input';
import SelectTable from './SelectTable';
import { v4 as uuidv4 } from 'uuid';
import DisplayError from '../DisplayError';
import { isFormValid, validateGuestForm } from '../../helpers/GuestValidation';
import SelectTotalArrival from './SelectTotalArrival';

interface EditGuestProps {
  guest: Guest;
  guestEditError: string;
  handleUpdate: (guestId: number, guestInfo: GuestInfo) => Promise<void>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditGuest: React.FC<EditGuestProps> = ({
  guest,
  handleUpdate,
  guestEditError,
  setIsEditing,
}) => {
  const [guestInfo, setGuestInfo] = useGuest(guest);
  const [guestInfoErrors, setGuestInfoErrors] = useGuestErrors();

  useEffect(() => {
    validateGuestForm(guestInfo, setGuestInfoErrors);
  }, [guestInfo, setGuestInfoErrors]);

  const updateHandler = (e: FormEvent) => {
    e.preventDefault();
    if (isFormValid(guestInfoErrors)) {
      handleUpdate(guest.id, guestInfo);
      setIsEditing(false);
    }
  };

  return (
    <StyledEditUser onSubmit={updateHandler}>
      <h3>עריכת אורח</h3>

      <FormDisplay>
        <GroupedInputs>
          <Input
            label='שם:'
            value={guestInfo.name}
            onChange={setGuestInfo.setName}
            required
            autofocus
          />
          <Input
            label='כינוי:'
            value={guestInfo.nickname || ''}
            onChange={setGuestInfo.setNickname}
          />
        </GroupedInputs>
        <GroupedInputs>
          <Input
            label='טלפון:'
            value={guestInfo.phone || ''}
            onChange={setGuestInfo.setPhone}
            type='number'
          />
          <Input
            label='קשר:'
            value={guestInfo.relation || ''}
            onChange={setGuestInfo.setRelation}
          />
        </GroupedInputs>
        <GroupedInputs>
          <SelectTable
            setTableId={setGuestInfo.setTableId}
            tableId={guestInfo.tableId}
          />
          <SelectTotalArrival
            totalArrival={guestInfo.totalArrival}
            setTotalArrival={setGuestInfo.setTotalArrival}
          />
        </GroupedInputs>
        <ShowErrors>
          {guestInfoErrors
            .filter((error) => error)
            .map((error) => (
              <DisplayError error={error} key={uuidv4()} />
            ))}
          {guestEditError && <DisplayError error={guestEditError} />}
        </ShowErrors>
        <Button
          text='סיום עריכה'
          color='secDark'
          bgColor='mainLight'
          type='submit'
        />
      </FormDisplay>
    </StyledEditUser>
  );
};

const StyledEditUser = styled.form`
  width: 105% !important;
`;

export const FormDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: max-content;
    margin: 1rem 0;
  }
`;

export const GroupedInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  div,
  select {
    width: 50%;
    input,
    select {
      width: 100%;
    }
  }
  select {
    margin-top: 0.4rem;
    font-size: 0.8rem;
  }

  div + div,
  select + select {
    margin-right: 0.8rem;
  }
`;

const ShowErrors = styled.div`
  p {
    width: 100%;
  }
`;

export default EditGuest;
