import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWedding } from '../../redux/weddings/actions';
import { RootState } from '../../store';
import { WeddingState } from '../../redux/weddings/reducers';

export default function useFetchWedding(weddingId: number): WeddingState {
  const dispatch = useDispatch();
  const wedding = useSelector((state: RootState) => state.wedding);

  useEffect(() => {
    dispatch(getWedding(weddingId));
  }, [weddingId, dispatch]);

  return wedding;
}
