import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWedding, getWeddings } from '../../redux/weddings/actions';
import { RootState } from '../../store';
import styled from 'styled-components';
import { CardsWrapper, GlobalPageInit, StyledLink } from '../../GlobalStyles';
import { WeddingStatus } from '../../shared/ts/Wedding';
import { UserRole } from '../../shared/ts/User';
import useAuthorizedRoles from '../../shared/hooks/useAuthorizedRoles';
import useIsTokenExpired from '../../shared/hooks/useIsTokenExpired';
import useScrollTop from '../../shared/hooks/useScrollTop';
import axios from 'axios';

import SelectStatus from './SelectStatus';
import { ButtonWrapper } from '../Users/Users';
import Wedding from './Wedding';
import Input from '../../shared/components/Input';
import Button from '../../shared/components/Button';
import DisplayError from '../../shared/components/DisplayError';
import Loader from '../../shared/components/Loader';
import { useHistory, useParams } from 'react-router';
import Pagination from '../../shared/components/Pagination';

const Weddings: React.FC = () => {
  const { couple, location, remarks, status, pageNumber } = useParams<{
    couple: string;
    location: string;
    remarks: string;
    status: string;
    pageNumber: string;
  }>();
  useAuthorizedRoles([UserRole.ADMIN]);
  useScrollTop();
  const dispatch = useDispatch();
  const history = useHistory();
  const filterRef = useRef<HTMLFormElement>(null!);

  const { isLoading, weddings, error, pagination } = useSelector(
    (state: RootState) => state.weddings
  );
  useIsTokenExpired(error);

  const {
    isLoading: isDeletionPending,
    error: deletionError,
    success: deletionSuccess,
  } = useSelector((state: RootState) => state.weddingDelete);
  useIsTokenExpired(deletionError);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [locationFilter, setLocationFilter] = useState<string | undefined>(
    location || ''
  );
  const [coupleFilter, setCoupleFilter] = useState<string | undefined>(
    couple || ''
  );
  const [remarksFilter, setRemarksFilter] = useState<string | undefined>(
    remarks || ''
  );
  const [statusFilter, setStatusFilter] = useState<
    string | undefined | WeddingStatus
  >(status || '');
  const [statusChangeError, setStatusChangeError] = useState<string>('');
  useIsTokenExpired(statusChangeError);
  const [statusChangeSuccess, setStatusChangeSuccess] = useState<boolean>(
    false
  );

  const filterSearchHandler = useCallback(
    (e: FormEvent): void => {
      e.preventDefault();
      history.push(
        `/admin/weddings/couple=${coupleFilter}/location=${locationFilter}/remarks=${remarksFilter}/status=${statusFilter}/page=1`
      );
    },
    [coupleFilter, history, locationFilter, remarksFilter, statusFilter]
  );

  const resetSearch = (): void => {
    setCoupleFilter('');
    setLocationFilter('');
    setRemarksFilter('');
    setStatusFilter('');
    filterRef?.current?.reset();
    history.push(`/admin/weddings/couple=/location=/remarks=/status=/page=1`);
  };

  const changeWeddingStatus = async (
    weddingId: number | undefined,
    status: string
  ): Promise<void> => {
    const appJsonConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      await axios.patch(
        `/weddings/${weddingId}/status`,
        { status },
        appJsonConfig
      );
      setStatusChangeSuccess(true);
    } catch (error) {
      setStatusChangeError(error);
    }
  };

  const weddingDeletionHandler = (weddingId: number): void => {
    dispatch(deleteWedding(+weddingId));
  };

  useEffect(() => {
    dispatch(getWeddings(couple, location, remarks, status, +pageNumber));
  }, [dispatch, couple, location, remarks, status, pageNumber]);

  useEffect(() => {
    if (deletionSuccess) {
      dispatch({ type: 'WEDDING_DELETE_RESET' });
      dispatch(getWeddings(couple, location, remarks, status, +pageNumber));
    }
    if (statusChangeSuccess) {
      setStatusChangeSuccess(false);
      dispatch(getWeddings(couple, location, remarks, status, +pageNumber));
    }
  }, [
    couple,
    location,
    remarks,
    status,
    pageNumber,
    deletionSuccess,
    dispatch,
    statusChangeSuccess,
    history,
  ]);

  return (
    <StyledWeddingsPage>
      {weddings ? (
        <>
          <ButtonWrapper>
            <Button
              text={
                showFilters ? 'הסתרת אפשרויות סינון' : 'הצגת אפשרויות סינון'
              }
              color='secDark'
              bgColor='mainDark'
              handleClick={() => setShowFilters(!showFilters)}
            />
          </ButtonWrapper>
          {showFilters && (
            <Filters onSubmit={filterSearchHandler} ref={filterRef}>
              <Input
                label='סינון לפי שם הזוג:'
                onChange={setCoupleFilter}
                placeholder='שם הזוג'
                value={coupleFilter}
              />
              <Input
                label='סינון לפי מיקום:'
                onChange={setLocationFilter}
                placeholder='מיקום'
                value={locationFilter}
              />
              <Input
                label='סינון לפי הערות:'
                onChange={setRemarksFilter}
                placeholder='הערות'
                value={remarksFilter}
              />
              <SelectStatus
                status={statusFilter}
                setStatus={setStatusFilter}
                text='סינון לפי סטטוס'
              />
              <Button
                text='חיפוש'
                type='submit'
                color='mainDark'
                bgColor='secDark'
              />
              <StyledLink onClick={resetSearch}>איפוס סינון</StyledLink>
            </Filters>
          )}
          {isLoading && <Loader message='טוען אירועים...' />}
          <StyledWedding>
            {error ? (
              <DisplayError error={error} />
            ) : weddings.length >= 1 ? (
              weddings.map((wedding, i) => (
                <Wedding
                  wedding={wedding}
                  key={i}
                  changeStatusHandler={changeWeddingStatus}
                  deletionHandler={weddingDeletionHandler}
                  isDeletionPending={isDeletionPending}
                />
              ))
            ) : (
              weddings.length === 0 &&
              !isLoading && (
                <h3>
                  לא נמאו חתונות תחת הסינון הנוכחי{' '}
                  <StyledLink onClick={resetSearch}>איפוס סינון</StyledLink>
                </h3>
              )
            )}
          </StyledWedding>
          {pagination.totalPages && pagination.totalPages > 1 && (
            <Pagination
              totalPages={pagination.totalPages}
              link={`/admin/weddings/couple=${coupleFilter}/location=${locationFilter}/remarks=${remarksFilter}/status=${statusFilter}/`}
            />
          )}
        </>
      ) : isLoading ? (
        <Loader message='טוען אירועים...' />
      ) : error ? (
        <DisplayError error={error} />
      ) : (
        ''
      )}
    </StyledWeddingsPage>
  );
};

const StyledWeddingsPage = styled(GlobalPageInit)``;

const StyledWedding = styled(CardsWrapper)`
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  margin-top: 1rem;

  select {
    font-size: 0.8rem;
  }
  h3 {
    margin-top: 0.5rem;
  }
`;

const Filters = styled.form`
  display: flex;
  flex-direction: column;
  width: max-content;

  @media (min-width: 600px) {
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  div + div {
    margin-top: 0.5rem;
  }
  select {
    margin-top: 0.5rem;
  }
  button {
    margin-top: 0.5rem;
  }
`;

export default Weddings;
