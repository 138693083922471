import React from 'react';
import LoaderIcon from 'react-loader-spinner';
import styled from 'styled-components';
import { colorsVariables } from '../../GlobalStyles';

interface LoderProps {
  size?: number;
  message?: string;
}

const Loader: React.FC<LoderProps> = ({ size, message }) => {
  return (
    <StyledLoader>
      <LoaderIcon
        type='Hearts'
        color={colorsVariables.mainDark}
        height={size || 40}
        width={size || 40}
      />
      {message && <h3>{message}</h3>}
    </StyledLoader>
  );
};

const StyledLoader = styled.div``;

export default Loader;
