import React from 'react';
import styled from 'styled-components';
import { slideDown } from '../GlobalStyles';
import hero from '../images/hero.jpg';

const Header: React.FC = () => {
  return (
    <StyledHero>
      <StyledHeader>
        <Text>
          <h1>ניהול הושבה חכם חברת FocusEvent</h1>
          <h2>כל כסא חשוב</h2>
        </Text>
      </StyledHeader>
    </StyledHero>
  );
};

const StyledHero = styled.header`
  position: relative;
  min-height: 18rem;

  @media (min-width: 600px) {
    min-height: 25rem;
  }

  @media (min-width: 1200px) {
    width: 80%;
    margin: 0 auto;
  }

  &::after {
    content: '';
    background: url(${hero}) no-repeat center center/cover;
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    min-height: 18rem;
    box-shadow: 0 7px 2px rgba(0, 0, 0, 0.3);
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 0.5rem;
  justify-content: center;

  animation: ${slideDown} 0.8s ease-in;

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
  }
`;

const Text = styled.div`
  margin-top: 7rem;

  @media (max-width: 600px) {
    margin-top: 5rem;
  }
  h1,
  h2 {
    margin-bottom: 0.3rem;
  }
`;

export default Header;
