import React, { useState } from 'react';
import styled from 'styled-components';
import { GlobalPageInit } from '../GlobalStyles';
import useScrollTop from '../shared/hooks/useScrollTop';
import Accordions from './Accordions';
import Cards from './Cards';
import ContactUs from './ContactUs';
import Header from './Header';

const Home: React.FC = () => {
  useScrollTop();
  const [isWatchingAccordion, setIsWatchingAccordion] = useState<boolean>(
    false
  );
  const [
    currentWatchedAccordion,
    setCurrentWatchedAccordion,
  ] = useState<number>(0);

  return (
    <StyledHome>
      <Header />
      <StyledSectionHeader>
        אצלנו בניהול ההושבה של FocusEvent
      </StyledSectionHeader>
      <Cards />
      <StyledSectionHeader>
        מסדרים את ההושבה בממשק החדיש של FocusEvent
      </StyledSectionHeader>
      <Accordions
        isWatchingAccordion={isWatchingAccordion}
        setIsWatchingAccordion={setIsWatchingAccordion}
        currentWatchedAccordion={currentWatchedAccordion}
        setCurrentWatchedAccordion={setCurrentWatchedAccordion}
      />
      <StyledSectionHeader>
        לקבלת פרטים על הממשק ושירותי FocusEvent הנוספים:
      </StyledSectionHeader>
      <ContactUs />
    </StyledHome>
  );
};

const StyledHome = styled(GlobalPageInit)`
  margin-top: 0;
`;

const StyledSectionHeader = styled.h1`
  margin-top: 3rem !important;
  font-size: 1.5rem;
  @media (min-width: 1200px) {
    width: 80%;
    margin: 2rem auto 0;
  }

  @media (max-width: 900px) {
    text-align: center;
  }
`;

export default Home;
