import React, { FormEvent, useEffect, useRef } from 'react';
import { FaPlusSquare } from 'react-icons/fa';
import styled from 'styled-components';
import { useGuest, useGuestErrors } from '../../hooks/useGuest';
import { GuestInfo, LoopableGuestInfo } from '../../ts/Guest';
import Button from '../Button';
import DisplayError from '../DisplayError';
import Input from '../Input';
import SelectTotalArrival from './SelectTotalArrival';
import { v4 as uuidv4 } from 'uuid';
import { validateGuestForm } from '../../helpers/GuestValidation';
import { isFormValid } from '../../helpers/TableValidation';

interface NewGuestProps {
  guestAddPending: boolean;
  guestAddError: string;
  addGuest: (guestInfo: GuestInfo) => Promise<void>;
  guestAddSuccess: boolean;
}

const NewGuest: React.FC<NewGuestProps> = ({
  guestAddPending,
  guestAddError,
  addGuest,
  guestAddSuccess,
}) => {
  const formRef = useRef<HTMLFormElement>(null!);
  const [guestInfo, setGuestInfo] = useGuest();
  const [guestInfoErrors, setGuestInfoErrors] = useGuestErrors();

  useEffect(() => {
    validateGuestForm(guestInfo, setGuestInfoErrors);
  }, [guestInfo, setGuestInfoErrors]);

  useEffect(() => {
    if (guestAddSuccess) {
      clearForm(setGuestInfo);
    }
  }, [guestAddSuccess, setGuestInfo]);

  const addGuestHandler = (e: FormEvent): void => {
    e.preventDefault();

    if (isFormValid(guestInfoErrors)) {
      addGuest(guestInfo);
    }
  };

  const clearForm = (setLoopableGuest: LoopableGuestInfo) => {
    for (let key in setLoopableGuest) {
      setLoopableGuest[key]('');
    }
    setLoopableGuest.setTotalArrival(1);
    setLoopableGuest.setPhone(null!);
    formRef.current.reset();
    formRef.current?.querySelector('input')?.focus();
  };

  return (
    <FormDisplay onSubmit={addGuestHandler} ref={formRef}>
      <h3>הוספת אורח</h3>
      <GroupedInputs>
        <Input
          label='שם:'
          value={guestInfo.name}
          onChange={setGuestInfo.setName}
          required
          autofocus
        />
        <Input
          label='כינוי:'
          value={guestInfo.nickname || ''}
          onChange={setGuestInfo.setNickname}
        />
      </GroupedInputs>
      <GroupedInputs>
        <Input
          label='טלפון:'
          value={guestInfo.phone}
          onChange={setGuestInfo.setPhone}
          type='number'
        />
        <Input
          label='קשר:'
          value={guestInfo.relation || ''}
          onChange={setGuestInfo.setRelation}
        />
      </GroupedInputs>
      <SelectTotalArrival
        totalArrival={guestInfo.totalArrival}
        setTotalArrival={setGuestInfo.setTotalArrival}
      />
      <ShowErrors>
        {guestInfoErrors
          .filter((error) => error)
          .map((error) => (
            <DisplayError error={error} key={uuidv4()} />
          ))}
        {guestAddError && <DisplayError error={guestAddError} />}
      </ShowErrors>
      <Button
        text={guestAddPending ? 'מוסיף...' : 'הוספת אורח'}
        icon={<FaPlusSquare />}
        color='secDark'
        bgColor='mainLight'
        type='submit'
      />
    </FormDisplay>
  );
};

export const GroupedInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  width: 90% !important;

  div,
  select {
    width: 50%;
    input {
      width: 100%;
    }
  }

  div + div {
    margin-right: 0.8rem;
  }

  button {
    display: block;
  }
`;

const FormDisplay = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: max-content;
    margin: 1rem 0;
  }

  select {
    font-size: 0.8rem;
    width: 50% !important;
  }
`;

const ShowErrors = styled.div`
  p {
    width: 100%;
  }
`;

export default NewGuest;
