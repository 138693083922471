import { Table } from './Table';
import { User } from './User';

export enum WeddingStatus {
  hasStarted = 'HAS_STARTED',
  isFinished = 'IS_FINISHED',
  unbegun = 'UNBEGUN',
}

export interface Wedding {
  id?: number;
  couple: string;
  location: string;
  numberOfGuests: number;
  committedToVenue: number;
  reservedSeats: number;
  date: string;
  startTime: string;
  status?: WeddingStatus;
  remarks: string;
  tables?: Table[];
  userId: number;
  user?: User;
}

export interface WeddingSetters {
  setCouple: React.Dispatch<React.SetStateAction<string>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  setNumberOfGuests: React.Dispatch<React.SetStateAction<number>>;
  setCommittedToVenue: React.Dispatch<React.SetStateAction<number>>;
  setReservedSeats: React.Dispatch<React.SetStateAction<number>>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  setUserId: React.Dispatch<React.SetStateAction<number>>;
}

export type WeddingErrors = string[];

export interface WeddingErrorsSetters {
  setCoupleError: React.Dispatch<React.SetStateAction<string>>;
  setLocationError: React.Dispatch<React.SetStateAction<string>>;
  setNumberOfGuestsError: React.Dispatch<React.SetStateAction<string>>;
  setCommittedToVenueError: React.Dispatch<React.SetStateAction<string>>;
  setReservedSeatsError: React.Dispatch<React.SetStateAction<string>>;
  setDateError: React.Dispatch<React.SetStateAction<string>>;
  setStartTimeError: React.Dispatch<React.SetStateAction<string>>;
  setUserIdError: React.Dispatch<React.SetStateAction<string>>;
}
