import React, { FormEvent, useRef } from 'react';
import { FaFilter } from 'react-icons/fa';
import styled from 'styled-components';
import {
  TableFormInputs,
  StyledLink,
  StyledTableForm,
} from '../../../GlobalStyles';
import { LoopableTableFilters } from '../../ts/Table';
import { TableFilters, SetTableFilters } from '../../hooks/useTableFilter';
import Button from '../Button';
import Input from '../Input';
import SelectRelation from './SelectRelation';

interface FilterTablesProps {
  filters: TableFilters;
  setFilters: SetTableFilters;
  getFilteredTables: (e: FormEvent) => void;
  resetSearch: (setFilters: LoopableTableFilters) => void;
}

const FilterTables: React.FC<FilterTablesProps> = ({
  filters,
  setFilters,
  getFilteredTables,
  resetSearch,
}) => {
  const formRef = useRef<HTMLFormElement>(null!);

  const resetHandler = (): void => {
    formRef.current.reset();
    resetSearch(setFilters);
  };

  return (
    <StyledTableForm onSubmit={getFilteredTables} ref={formRef}>
      <h3>סינון שולחנות</h3>
      <InputsSide>
        <Input
          label='מספר שולחן:'
          value={filters.number || ''}
          onChange={setFilters.setNumber}
        />
        <Input
          label='שם שולחן:'
          value={filters.name || ''}
          onChange={setFilters.setName}
        />
      </InputsSide>
      <InputsSide>
        <Input
          label='שם אורח:'
          value={filters.guestName || ''}
          onChange={setFilters.setGuestName}
        />
        <SelectRelation
          relation={filters.relation}
          setRelation={setFilters.setRelation}
          text='צד האורח'
          isFiltering
        />
      </InputsSide>
      <StyledLink onClick={resetHandler}>איפוס סינון</StyledLink>
      <Button
        handleClick={getFilteredTables}
        text='סינון'
        icon={<FaFilter />}
        color='secLight'
        bgColor='mainLight'
        type='submit'
      />
    </StyledTableForm>
  );
};

const InputsSide = styled(TableFormInputs)`
  @media (min-width: 600px) {
    div {
      max-width: 10rem;
    }
  }
`;

export default FilterTables;
