import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { GlobalStyles } from './GlobalStyles';
import Footer from './shared/components/Footer';
import Nav from './shared/components/Nav';
import Login from './Login';
import Admin from './Admin';
import Couple from './Couple';
import OngoingWeddings from './OngoingWeddings';
import ManageOngoingWeddings from './OngoingWeddings/Manage';
import Home from './Home';
import Discounts from './Couple/Discounts';

function App() {
  return (
    <div>
      <BrowserRouter>
        <GlobalStyles />
        <Nav />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/login' component={Login} />
          <Route path='/admin' component={Admin} />
          <Route path='/couple' component={Couple} />
          <Route path='/ongoing-weddings' component={OngoingWeddings} exact />
          <Route
            path='/ongoing-weddings/manage/:weddingId'
            component={ManageOngoingWeddings}
          />
          <Route path='/discounts' exact component={Discounts} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
