import React from 'react';
import styled from 'styled-components';
import { colorsVariables, GlobalPageInit } from '../GlobalStyles';

import { useParams } from 'react-router';
import useFetchWedding from '../shared/hooks/useFetchWedding';
import useIsTokenExpired from '../shared/hooks/useIsTokenExpired';
import useScrollTop from '../shared/hooks/useScrollTop';
import ManageTables from '../shared/components/Tables/ManageTables';
import Loader from '../shared/components/Loader';
import DisplayError from '../shared/components/DisplayError';
import WeddingInfo from '../shared/components/Wedding/WeddingInfo';
import WeddingSummary from './WeddingSummary';

const Wedding: React.FC = () => {
  useScrollTop();
  const { weddingId } = useParams<{ weddingId: string }>();
  // useAuthorizedRoles([UserRole.ADMIN], +weddingId);

  const { isLoading, error, wedding } = useFetchWedding(+weddingId);
  useIsTokenExpired(error);

  return (
    <StyledWeddingPage>
      {isLoading ? (
        <Loader message='טוען אירוע...' />
      ) : error ? (
        <DisplayError error={error} />
      ) : wedding?.id && !isLoading ? (
        wedding.status === 'UNBEGUN' ? (
          <StyledWeddingPanel>
            <Section>
              <h2>האירוע שלי:</h2>
              <WeddingInfo wedding={wedding} />
            </Section>
            <Section>
              <h2>שולחנות:</h2>
              {wedding?.id && (
                <ManageTables
                  weddingId={wedding?.id}
                  linkTo={`/couple/wedding/${weddingId}`}
                />
              )}
            </Section>
          </StyledWeddingPanel>
        ) : wedding.status === 'HAS_STARTED' ? (
          //TODO: MAKE A COMPONENT FOR THIS SITUATION
          <>
            <h2>זהו זה! סוף סוף היום הזה הגיע</h2>
            <p>
              זה הזמן שלכם ליהנות ולא להתעסק בניהול האירוע. תשאירו את זה לנו
            </p>
            <p>חזרו למסך הזה מחר כדי לקבל תמונת מצב על הגעת האורחים</p>
          </>
        ) : (
          <>
            <Section>
              <h2>האירוע שלי:</h2>
              <WeddingInfo wedding={wedding} />
              <StyledReccommendation>
                נהנתם? נשמח לחוות דעת
                <a href='https://www.mit4mit.co.il/writeReview/90972?rank=5'>
                  בקישור הזה
                </a>
              </StyledReccommendation>
            </Section>
            <WeddingSummary wedding={wedding} />
          </>
        )
      ) : (
        ''
      )}
    </StyledWeddingPage>
  );
};

const StyledWeddingPage = styled(GlobalPageInit)``;

const StyledWeddingPanel = styled.div`
  margin-top: 1rem;
`;

const Section = styled.div`
  &:first-of-type {
    margin-bottom: 0.2rem;
    border-bottom: 1px solid black;
    padding-bottom: 0.5rem;
  }

  &:not(:first-of-type) {
    margin-top: 3rem;
    h2 {
      margin-bottom: 0.2rem;
      border-bottom: 1px solid black;
      padding-bottom: 0.3rem;
    }
  }
`;

const StyledReccommendation = styled.h3`
  margin: 1rem 0;
  a {
    color: ${colorsVariables.link};
    border-bottom: 2px solid ${colorsVariables.link};
    margin-right: 0.3rem;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export default Wedding;
