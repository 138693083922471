import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
  deleteTable,
  editTable,
  getTables,
  postTable,
} from '../../../redux/tables/actions';
import styled from 'styled-components';
import { LoopableTableFilters, TableInfo } from '../../ts/Table';
import useIsTokenExpired from '../../hooks/useIsTokenExpired';
import useTableFilter from '../../hooks/useTableFilter';
import Tables from './Tables';
import NewTable from './NewTable';
import Button from '../Button';
import FilterTables from './FilterTables';
import Loader from '../Loader';
import DisplayError from '../DisplayError';
import { FaFilter, FaMinusSquare, FaPlusSquare } from 'react-icons/fa';
import calculateWeddingGuestsArrival from '../../helpers/calculateWeddingGuestsArrival';
import Pagination from '../Pagination';
import { useHistory, useParams } from 'react-router';
import { StyledLink } from '../../../GlobalStyles';
import calculateInsertedGuests from '../../helpers/calculateInsertedGuests';
import axios from 'axios';

interface ManageTablesProps {
  weddingId: number;
  linkTo: string;
}

const ManageTables: React.FC<ManageTablesProps> = ({ weddingId, linkTo }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { number, name, guest, relation, page } = useParams<{
    number: string;
    name: string;
    guest: string;
    relation: string;
    page: string;
  }>();

  const [isAddingTable, setIsAddingTable] = useState<boolean>(false);
  const [isFilteringTables, setIsFilteringTables] = useState<boolean>(false);
  const [totalGuests, setTotalGuests] = useState<number>();
  const [totalTables, setTotalTables] = useState<number>();
  const [filters, setFilters] = useTableFilter();
  const {
    isLoading: tablesIsLoading,
    error: tablesError,
    tables,
    pagination,
  } = useSelector((state: RootState) => state.tables);
  useIsTokenExpired(tablesError);

  useEffect(() => {
    calculateWeddingGuestsArrival(tables);
  }, [tables]);

  const {
    isLoading: tablePostPending,
    error: tablePostError,
    success: tablePostSuccess,
  } = useSelector((state: RootState) => state.tablePost);
  useIsTokenExpired(tablePostError);

  const {
    isLoading: tableEditPending,
    error: tableEditError,
    success: tableEditSuccess,
  } = useSelector((state: RootState) => state.tableEdit);
  useIsTokenExpired(tableEditError);

  const {
    isLoading: tableDeletePending,
    error: tableDeleteError,
    success: tableDeleteSuccess,
  } = useSelector((state: RootState) => state.tableDelete);
  useIsTokenExpired(tableDeleteError);

  const showFilters = (): void => {
    setIsFilteringTables(!isFilteringTables);
    setIsAddingTable(false);
  };

  const showAddTable = (): void => {
    setIsAddingTable(!isAddingTable);
    setIsFilteringTables(false);
  };

  const getFilteredTables = useCallback(
    (e?: FormEvent): void => {
      e?.preventDefault();
      if (filters.number && !/^[0-9]*$/.test(filters.number)) {
        return;
      }
      history.push(
        `${linkTo}/number=${filters.number}/name=${filters.name}/guest=${filters.guestName}/relation=${filters.relation}/page=1`
      );
    },
    [filters, history, linkTo]
  );

  const resetSearch = (setFilters: LoopableTableFilters): void => {
    for (let value in setFilters) {
      setFilters[value]('');
    }
    history.push(`${linkTo}/number=/name=/guest=/relation=/page=1`);
  };

  const postTableHandler = (tableInfo: TableInfo): void => {
    dispatch(postTable(+weddingId, tableInfo));
  };

  const editTableHandler = (tableId: number, editInfo: TableInfo): void => {
    dispatch(editTable(weddingId, tableId, editInfo));
  };

  const deleteTableHandler = (tableId: number): void => {
    dispatch(deleteTable(weddingId, tableId));
  };

  const getTotalGuests = useCallback(async (): Promise<void> => {
    const { data } = await axios.get(
      `/weddings/${weddingId}/tables?limit=200&page=${1}`
    );

    const allTables = data?.data;

    setTotalTables(allTables.length);
    setTotalGuests(calculateInsertedGuests(allTables));
  }, [weddingId]);

  useEffect(() => {
    dispatch(getTables(+weddingId, number, name, relation, guest, +page));
    getTotalGuests();
  }, [
    dispatch,
    number,
    name,
    relation,
    guest,
    page,
    weddingId,
    getTotalGuests,
  ]);

  useEffect(() => {
    if (tablePostSuccess) {
      dispatch({ type: 'TABLE_POST_RESET' });
      history.push(`${linkTo}/number=/name=/guest=/relation=/page=1`);
      dispatch(getTables(+weddingId, number, name, relation, guest, +page));
    }
  }, [
    dispatch,
    tablePostSuccess,
    weddingId,
    number,
    name,
    relation,
    guest,
    page,
    history,
    linkTo,
  ]);

  useEffect(() => {
    if (tableEditSuccess) {
      dispatch({ type: 'TABLE_EDIT_RESET' });
      dispatch(getTables(+weddingId, number, name, relation, guest, +page));
    }
  }, [
    tableEditSuccess,
    dispatch,
    weddingId,
    number,
    name,
    relation,
    guest,
    page,
  ]);

  useEffect(() => {
    if (tableDeleteSuccess) {
      dispatch({ type: 'TABLE_DELETE_RESET' });
      dispatch(getTables(+weddingId, number, name, relation, guest, +page));
    }
  }, [
    dispatch,
    tableDeleteSuccess,
    weddingId,
    number,
    name,
    relation,
    guest,
    page,
  ]);

  return (
    <>
      <h4>מספר שולחנות: {totalTables}</h4>
      <h4>מספר אורחים: {totalGuests}</h4>
      <StyledLink onClick={getTotalGuests}>עדכון נתונים</StyledLink>
      <StyledActions>
        <Button
          text={isAddingTable ? 'הסתרת הוספה' : 'הוספת שולחן'}
          icon={isAddingTable ? <FaMinusSquare /> : <FaPlusSquare />}
          color='mainDark'
          bgColor='secDark'
          handleClick={showAddTable}
        />
        <Button
          text={isFilteringTables ? 'הסתרת סינון' : 'סינון שולחנות'}
          icon={<FaFilter />}
          color='mainDark'
          bgColor='secDark'
          handleClick={showFilters}
        />
      </StyledActions>
      {isAddingTable && (
        <NewTable
          tablePostPending={tablePostPending}
          postTableHandler={postTableHandler}
          tablePostError={tablePostError}
          tablePostSuccess={tablePostSuccess}
        />
      )}
      {isFilteringTables && (
        <FilterTables
          filters={filters}
          setFilters={setFilters}
          getFilteredTables={getFilteredTables}
          resetSearch={resetSearch}
        />
      )}
      {tablesError ? (
        <DisplayError error={tablesError} />
      ) : !tablesIsLoading && tables?.length >= 1 ? (
        <Tables
          tables={tables}
          deleteHandler={deleteTableHandler}
          editHandler={editTableHandler}
          isLoadingTables={tablesIsLoading}
          tableEditPending={tableEditPending}
          tableDeletePending={tableDeletePending}
          tableEditError={tableEditError}
          tableDeleteError={tableDeleteError}
        />
      ) : !tablesIsLoading && tables?.length === 0 ? (
        <StyledNotFound>
          לא נמצאו שולחנות תחת החיפוש הנוכחי
          <StyledLink onClick={() => resetSearch(setFilters)}>
            איפוס סינון
          </StyledLink>
        </StyledNotFound>
      ) : (
        <Loader message='טוען שולחנות...' />
      )}
      {pagination && pagination.totalPages > 1 && (
        <Pagination
          totalPages={pagination.totalPages}
          link={`${linkTo}/number=${filters.number}/name=${filters.name}/guest=${filters.guestName}/relation=${filters.relation}/`}
        />
      )}
    </>
  );
};

const StyledNotFound = styled.h3`
  margin-top: 0.5rem;
`;

const StyledActions = styled.div`
  button {
    display: block;
  }

  button + button {
    margin-top: 0.5rem;
  }

  @media (min-width: 600px) {
    button {
      display: initial;
    }
    button + button {
      margin-right: 0.5rem;
    }
  }
`;

export default ManageTables;
