import React from 'react';
import styled from 'styled-components';
import piechart from '../images/piechart.png';
import couple from '../images/couple.jpg';
import weddingseats from '../images/weddingseats.jpg';

const Cards: React.FC = () => {
  return (
    <StyledCards>
      <Card>
        <ImageWrapper>
          <img src={couple} alt='couple' />{' '}
        </ImageWrapper>
        <TextWrapper>
          <Text>
            <h3>כל כסא חשוב</h3>
            ניהול הושבה זה לא רק הושבת אורחים. אנו אחראיים על ניהול מקצועי של
            תפוסת האולם והרזרבות.
          </Text>
        </TextWrapper>
      </Card>
      <Card>
        <ImageWrapper>
          <img src={piechart} alt='pie chart' />{' '}
        </ImageWrapper>
        <TextWrapper>
          <Text>
            <h3>ממשק FocusEvent להושבה</h3>
            לא עוד רשימות אקסל מסובכות. נהלו את האורחים שלכם בצורה חכמה.
          </Text>
        </TextWrapper>
      </Card>
      <Card>
        <ImageWrapper>
          <img src={weddingseats} alt='weddingseats' />{' '}
        </ImageWrapper>
        <TextWrapper>
          <Text>
            <h3>קבלת פנים יוקרתית</h3>
            דיילים מקצועיים ושירותיים. מערכת הושבה ממוחשבת מהמתקדמות בתחום.
          </Text>
        </TextWrapper>
      </Card>
    </StyledCards>
  );
};

const StyledCards = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem !important;
  margin-bottom: 8rem !important;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1200px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const Card = styled.div`
  position: relative;
  max-width: 18rem;

  &:not(:first-of-type) {
    margin-right: 2rem;
  }

  &:hover {
    div:nth-of-type(2) {
      transform: translateY(-80px);
    }
  }

  @media (max-width: 900px) {
    &:not(:first-of-type) {
      margin-right: 0;
      margin-top: 6rem;
    }
  }
`;

const ImageWrapper = styled.div`
  img {
    height: 18rem;
    border-radius: 15px;
    object-fit: cover;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 85%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 14rem;
  padding: 0.3rem;

  background: #fffdfd;
  color: black;
  box-shadow: 0 7px 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  transition: all 0.5s ease-out;
`;

const Text = styled.div`
  width: 100%;
  padding: 0.1rem 0.2rem 0.3rem;
  h3 {
    margin-bottom: 0.3rem;
    font-size: 1.1.rem;
  }
`;

export default Cards;
