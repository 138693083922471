export enum UserRole {
  ADMIN = 'ADMIN',
  COUPLE = 'COUPLE',
  EMPLOYEE = 'EMPLOYEE',
  TEST = 'TEST',
}

export interface User {
  username: string;
  role: UserRole;
  email: string;
  weddingId?: number | null;
  id: number;
  resetPasswordToken?: string | null;
  permittedWeddings: number[];
}
