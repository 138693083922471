import { Action, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction } from 'redux-thunk';
import {
  InitialLoginState,
  loginReducer,
  registerReducer,
} from './redux/auth/reducers';
import {
  usersReducer,
  userDeleteReducer,
  userReducer,
  userEditReducer,
} from './redux/users/reducers';
import {
  weddingPostReducer,
  weddingReducer,
  weddingsReducer,
  weddingEditReducer,
  weddingDeleteReducer,
} from './redux/weddings/reducers';
import {
  tablesReducer,
  tableEditReducer,
  tableDeleteReducer,
  tablePostReducer,
} from './redux/tables/reducers';
import { User } from './shared/ts/User';
import { guestsDeleteReducer, guestsReducer } from './redux/guests/reducers';

const combinedReducers = combineReducers({
  userLogin: loginReducer,
  userRegister: registerReducer,
  users: usersReducer,
  user: userReducer,
  userEdit: userEditReducer,
  userDelete: userDeleteReducer,
  weddings: weddingsReducer,
  weddingPost: weddingPostReducer,
  wedding: weddingReducer,
  weddingEdit: weddingEditReducer,
  weddingDelete: weddingDeleteReducer,
  tables: tablesReducer,
  tableEdit: tableEditReducer,
  tableDelete: tableDeleteReducer,
  tablePost: tablePostReducer,
  guests: guestsReducer,
  guestDelete: guestsDeleteReducer,
});

const loggedUserJson = localStorage.getItem('loggedUser');
const storedUser: User = loggedUserJson ? JSON.parse(loggedUserJson) : null;

const initialState = {
  userLogin: { ...InitialLoginState, loggedUser: storedUser },
};
const middleware = [thunk];
const store = createStore(
  combinedReducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type RootState = ReturnType<typeof combinedReducers>;
export default store;
