import axios from 'axios';
import { useCallback, useEffect } from 'react';
import calculateWeddingGuestsArrival from '../helpers/calculateWeddingGuestsArrival';

export default function useCountTotalArrival(
  weddingId: number,
  setTotalArrival: React.Dispatch<React.SetStateAction<number>>,
  shouldUpdate: boolean,
  setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const getTables = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/weddings/${weddingId}/tables?page=1&limit=150`
      );
      setTotalArrival(calculateWeddingGuestsArrival(data?.data));
    } catch (error) {}
  }, [weddingId, setTotalArrival]);

  useEffect(() => {
    if (shouldUpdate) {
      getTables();
      setShouldUpdate(false);
    }
  }, [getTables, shouldUpdate, setShouldUpdate]);
}
