import { useState } from 'react';
import {
  SetTableInfo,
  SetTableInfoErrors,
  Table,
  TableInfo,
  TableInfoErrors,
} from '../ts/Table';

export function useTableInfo(table: Table): [TableInfo, SetTableInfo] {
  const [number, setNumber] = useState<string>(table.number || null!);
  const [name, setName] = useState<string>(table.name || '');
  const [seats, setSeats] = useState<number>(table.seats || null!);
  const [relation, setRelation] = useState<string | undefined>(
    table.relation || ''
  );

  const tableInfo = {
    number,
    seats,
    relation,
    name,
  };

  const setTableInfo = {
    setNumber,
    setSeats,
    setRelation,
    setName,
  };

  return [tableInfo, setTableInfo];
}

export function useTableErrors(): [TableInfoErrors, SetTableInfoErrors] {
  const [numberError, setNumberError] = useState<string>('');
  const [seatsError, setSeatsError] = useState<string>('');
  const [relationError, setRelationError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');

  const tableInfoErrors = [numberError, seatsError, relationError, nameError];

  const setTableInfoErrors = {
    setNumberError,
    setSeatsError,
    setRelationError,
    setNameError,
  };

  return [tableInfoErrors, setTableInfoErrors];
}
