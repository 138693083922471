import { Wedding, WeddingSetters } from '../../shared/ts/Wedding';

export const Inputs = (
  wedding: Wedding,
  setWedding: WeddingSetters
): WeddingInput[] => {
  return [
    {
      label: 'שם הזוג:',
      placeholder: 'שם הזוג',
      onChange: setWedding.setCouple,
      value: wedding.couple,
      required: true,
    },
    {
      label: 'מיקום האירוע:',
      placeholder: 'מיקום',
      onChange: setWedding.setLocation,
      value: wedding.location,
      required: true,
    },
    {
      label: 'מספר האורחים:',
      placeholder: 'מספר אורחים',
      onChange: setWedding.setNumberOfGuests,
      value: wedding.numberOfGuests,
      type: 'number',
      required: true,
    },
    {
      label: 'התחייבות לאולם:',
      placeholder: 'התחייבות',
      onChange: setWedding.setCommittedToVenue,
      value: wedding.committedToVenue,
      type: 'number',
      required: true,
    },
    {
      label: 'כסאות רזרבה:',
      placeholder: 'רזרבה',
      onChange: setWedding.setReservedSeats,
      value: wedding.reservedSeats,
      type: 'number',
      required: true,
    },
    {
      label: 'תאריך האירוע:',
      placeholder: '01/01/2001',
      onChange: setWedding.setDate,
      value: wedding.date,
      required: true,
    },
    {
      label: 'זמן תחילת האירוע:',
      placeholder: '19:30',
      onChange: setWedding.setStartTime,
      value: wedding.startTime,
      required: true,
    },
  ];
};

export interface WeddingInput {
  label: string;
  placeholder: string;
  onChange:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<number>>;
  value: string | number;
  required: boolean;
  type?: 'text' | 'number';
}
