import axios from 'axios';
import { useCallback, useState } from 'react';
import { Guest } from '../ts/Guest';

export default function useFetchTableGuests(
  weddingId: number,
  tableId: number
): [Guest[], boolean, string, () => Promise<void>] {
  const [guests, setGuests] = useState<Guest[]>([]);
  const [guestsError, setGuestsError] = useState<string>('');
  const [isLoadingGuests, setIsLoadingGuests] = useState<boolean>(false);

  const getTableGuests = useCallback(async () => {
    try {
      setIsLoadingGuests(true);
      const { data } = await axios.get(
        `/weddings/${weddingId}/tables/${tableId}/guests`
      );
      setGuests(data);
      setIsLoadingGuests(false);
    } catch (error) {
      setGuestsError(error?.response?.data?.message);
    }
  }, [tableId, weddingId]);

  return [guests, isLoadingGuests, guestsError, getTableGuests];
}
