import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store';
import { UserRole } from '../ts/User';

export default function useAuthorizedRoles(
  roles: UserRole[],
  weddingId?: number
) {
  const { loggedUser } = useSelector((state: RootState) => state.userLogin);

  const history = useHistory();
  const userOwnsWedding = useCallback((): void | boolean => {
    if (loggedUser?.weddingId === weddingId) {
      return true;
    }
    return history.replace('/');
  }, [loggedUser, weddingId, history]);

  useEffect(() => {
    if (weddingId) {
      userOwnsWedding();
    }
    if (!loggedUser || (roles.indexOf(loggedUser?.role) === -1 && !weddingId)) {
      return history.replace('/');
    }
  }, [history, roles, loggedUser, userOwnsWedding, weddingId]);
  return loggedUser;
}
