import { Table } from '../ts/Table';
import calculateTotalGuests from './calculateTotalGuests';

export default function calculateInsertedGuests(tables: Table[]): number {
  let sum: number = 0;

  tables.forEach((table) => (sum += calculateTotalGuests(table?.guests)));

  return sum;
}
