import React from 'react';
import styled from 'styled-components';
import { colorsVariables, GlobalPageInit } from '../GlobalStyles';
import kit from '../images/kit.jpg';
import bar from '../images/bar.jpg';
import welcome from '../images/welcome.jpg';
import useScrollTop from '../shared/hooks/useScrollTop';

const Discounts: React.FC = () => {
  useScrollTop();

  const cards = [
    {
      header: 'Focus-Day',
      subHeader: 'ניהול יום אירוע',
      image: welcome,
      price: '1,600₪',
      discountedPrice: '1,400₪',
    },
    {
      header: 'Focus-Bar',
      subHeader: 'חלוקת אלכוהול בחתונה',
      image: bar,
      price: '350₪',
      discountedPrice: '300₪',
    },
    {
      header: 'Focus-Fresh',
      subHeader: 'ערכות התרעננות',
      image: kit,
      price: '289₪',
      discountedPrice: '250₪',
    },
  ];

  return (
    <GlobalPageInit>
      <StyledHeader>
        הטבות מיוחדות לזוגות שסגרו סידור הושבה של FocusEvent
      </StyledHeader>
      <Cards>
        {cards.map((card) => (
          <Card>
            <CardHeader>
              <h4>{card.header}</h4>
              <p>{card.subHeader}</p>
            </CardHeader>
            <CardImage>
              <img src={card.image} alt={card.header} />
            </CardImage>
            <CardPricing>
              <DiscountedPrice>{card.discountedPrice}</DiscountedPrice> במקום{' '}
              <OriginalPrice>{card.price}</OriginalPrice>
            </CardPricing>
          </Card>
        ))}
      </Cards>
      <StyledHeader>
        לשריון ההטבה לחתונה שלכם לסמס\להתקשר אורי – 0546837937
      </StyledHeader>
      <div style={{ textAlign: 'center' }}>
        <SiteLink
          href='https://focusevent.co.il'
          target='_blank'
          rel='noreferrer'
        >
          מידע ופרטים נוספים ניתן למצוא באתר האינטרנט שלנו www.focusevent.co.il
        </SiteLink>
      </div>
    </GlobalPageInit>
  );
};

export default Discounts;

const StyledHeader = styled.h2`
  margin: 1rem 0;
  text-align: center;
`;

const Cards = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

const CardHeader = styled.div``;

const CardImage = styled.div``;

const CardPricing = styled.div``;

const Card = styled.div`
  max-width: 20rem;
  text-align: center;
  border: 1px solid black;
  padding: 1rem 0;

  h4,
  p {
    margin-bottom: 0.3rem;
  }

  img {
    border-radius: 3px;
    margin-bottom: 0.3rem;
  }

  @media (max-width: 1100px) {
    margin-bottom: 1rem;
  }
`;

const OriginalPrice = styled.p`
  color: ${colorsVariables.error};
  text-decoration: line-through;
  display: inline-block;
`;

const DiscountedPrice = styled.p`
  display: inline-block;
  text-decoration: underline;
`;

const SiteLink = styled.a`
  color: ${colorsVariables.link};
  font-weight: bold;
`;
