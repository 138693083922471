import { User } from '../../shared/ts/User';
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
} from './actions';

interface registerState {
  isLoading: boolean;
  registeredUser: User;
  error: string;
  success: boolean;
}

export const initialRegisterState = {
  isLoading: false,
  registeredUser: {} as User,
  error: '',
  success: false,
};

type RegisterAction =
  | USER_REGISTER_REQUEST
  | USER_REGISTER_SUCCESS
  | USER_REGISTER_FAIL
  | USER_REGISTER_RESET;

export const registerReducer = (
  state: registerState = initialRegisterState,
  action: RegisterAction
): registerState => {
  switch (action.type) {
    case 'USER_REGISTER_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_REGISTER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        registeredUser: action.payload,
      };
    case 'USER_REGISTER_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'USER_REGISTER_RESET':
      return {
        isLoading: false,
        error: '',
        registeredUser: {} as User,
        success: false,
      };
    default:
      return state;
  }
};

interface LoginState {
  isLoading: boolean;
  loggedUser: User;
  error: string;
}

export const InitialLoginState = {
  isLoading: false,
  loggedUser: {} as User,
  error: '',
};

type LoginAction =
  | USER_LOGIN_REQUEST
  | USER_LOGIN_SUCCESS
  | USER_LOGIN_FAIL
  | USER_LOGOUT_REQUEST
  | USER_LOGOUT_SUCCESS
  | USER_LOGIN_RESET;

export const loginReducer = (
  state: LoginState = InitialLoginState,
  action: LoginAction
) => {
  switch (action.type) {
    case 'USER_LOGIN_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        isLoading: false,
        loggedUser: action.payload,
      };
    case 'USER_LOGIN_FAIL':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'USER_LOGOUT_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_LOGOUT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        loggedUser: null!,
      };
    case 'USER_LOGIN_RESET':
      return {
        ...state,
        isLoading: false,
        error: 'פג תוקף ההתחברות. למען בטיחות המשתמש יש להתבחר מחדש',
        loggedUser: null!,
      };
    default:
      return state;
  }
};
