import { User, UserRole } from '../../shared/ts/User';
import axios from 'axios';
import { AppDispatch } from '../../store';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export type USERS_GET_REQUEST = {
  type: 'USERS_GET_REQUEST';
};

export type USERS_GET_SUCCESS = {
  type: 'USERS_GET_SUCCESS';
  payload: User[];
  pagination: { totalPages: number };
};

export type USERS_GET_FAIL = {
  type: 'USERS_GET_FAIL';
  payload: string;
};

export const getUsers =
  (
    role: UserRole | string = '',
    name: string = '',
    pageNumber: number = 1,
    limit = 100
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'USERS_GET_REQUEST' });
      const { data } = await axios.get(
        `/users?limit=${limit}&page=${pageNumber}&role=${role}&name=${name}`
      );

      dispatch({
        type: 'USERS_GET_SUCCESS',
        payload: data.data,
        pagination: { totalPages: data.totalPages },
      });
    } catch (error) {
      dispatch({
        type: 'USERS_GET_FAIL',
        payload: error.response.data.message,
      });
    }
  };

export type USER_GET_REQUEST = {
  type: 'USER_GET_REQUEST';
};

export type USER_GET_SUCCESS = {
  type: 'USER_GET_SUCCESS';
  payload: User;
};

export type USER_GET_FAIL = {
  type: 'USER_GET_FAIL';
  payload: string;
};

export const getUser = (userId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'USER_GET_REQUEST' });
    const { data } = await axios.get(`/users/${userId}`);

    dispatch({ type: 'USER_GET_SUCCESS', payload: data });
  } catch (error) {
    dispatch({
      type: 'USER_GET_FAIL',
      payload: error.response.data.message ?? 'התרחשה תקלה',
    });
  }
};

export type USER_EDIT_REQUEST = {
  type: 'USER_EDIT_REQUEST';
};

export type USER_EDIT_SUCCESS = {
  type: 'USER_EDIT_SUCCESS';
  payload: User;
};

export type USER_EDIT_FAIL = {
  type: 'USER_EDIT_FAIL';
  payload: string;
};

export type USER_EDIT_RESET = {
  type: 'USER_EDIT_RESET';
};

export const editUser =
  (
    userId: number,
    username: string,
    role: string | undefined,
    permittedWeddings: number[],
    password?: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: 'USER_EDIT_REQUEST' });
      const { data } = await axios.patch(
        `/users/${userId}`,
        { username, role, permittedWeddings, password },
        config
      );

      dispatch({ type: 'USER_EDIT_SUCCESS', payload: data });
    } catch (error) {
      dispatch({
        type: 'USER_EDIT_FAIL',
        payload: error.response.data.message ?? 'התרחשה תקלה',
      });
    }
  };

export type USER_DELETE_REQUEST = {
  type: 'USER_DELETE_REQUEST';
};

export type USER_DELETE_SUCCESS = {
  type: 'USER_DELETE_SUCCESS';
};

export type USER_DELETE_FAIL = {
  type: 'USER_DELETE_FAIL';
  payload: string;
};

export type USER_DELETE_RESET = {
  type: 'USER_DELETE_RESET';
};

export const deleteUser = (userId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'USER_DELETE_REQUEST' });
    await axios.delete(`/users/${userId}`);

    dispatch({ type: 'USER_DELETE_SUCCESS' });
  } catch (error) {
    dispatch({
      type: 'USER_DELETE_FAIL',
      payload: error.response.data.message ?? 'התרחשה תקלה',
    });
  }
};
