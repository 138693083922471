import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { cssVariables } from '../GlobalStyles';
import { Wedding } from '../shared/ts/Wedding';

interface OngoingWeddingCardProps {
  wedding: Wedding;
}

const OngoingWeddingCard: React.FC<OngoingWeddingCardProps> = ({ wedding }) => {
  return (
    <StyledWeddingCard>
      <Link
        to={`/ongoing-weddings/manage/${wedding.id}/guests/number=/name=/guest=/relation=/page=1`}
      >
        <p>
          <span>שם הזוג:</span> {wedding.couple}
        </p>
        <p>
          <span>מיקום:</span> {wedding.location}
        </p>
        <p>
          <span>תאריך:</span> {wedding.date}
        </p>
      </Link>
    </StyledWeddingCard>
  );
};

const StyledWeddingCard = styled.div`
  box-shadow: ${cssVariables.boxShadow};
  padding: 1rem;
  p span {
    font-weight: bold;
  }

  p + p {
    margin-top: 0.5rem;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export default OngoingWeddingCard;
