import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import styled from 'styled-components';
import Button from '../Button';

interface ActionButtonsProps {
  isEditing: boolean;
  setIsEditing: (value: React.SetStateAction<boolean>) => void;
  setIsAwaitingDeletion: (value: React.SetStateAction<boolean>) => void;
  guestDeletionPending: boolean;
  guestEditPending: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  isEditing,
  setIsEditing,
  setIsAwaitingDeletion,
  guestDeletionPending,
  guestEditPending,
}) => {
  return (
    <StyledActionButtons>
      <Button
        color='secDark'
        bgColor='white'
        icon={<FaEdit />}
        handleClick={() => setIsEditing(!isEditing)}
        disabled={guestEditPending}
      />
      <Button
        color='error'
        bgColor='white'
        icon={<FaTrash />}
        disabled={guestDeletionPending}
        handleClick={() => setIsAwaitingDeletion(true)}
      />
    </StyledActionButtons>
  );
};

const StyledActionButtons = styled.div`
  display: flex;
  margin-left: 1rem;
  button {
    padding: 0.2rem;
    box-shadow: none;
    :hover {
      padding: 0.2rem;
    }
    svg {
      margin: 0;
      vertical-align: top;
    }
  }

  button + button {
    margin-right: 1rem;
  }
`;

export default ActionButtons;
