import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Button from '../../shared/components/Button';

interface ArrivalButtonsProps {
  guestId: number;
  updateGuestArrival?: (
    guestId: number,
    status: 'arrived' | 'notarrived',
    totalArrival?: number
  ) => void;
  guestArrivalLoading?: boolean;
  guestArrivalError?: string;
  totalArrival: number;
  setTotalArrival: React.Dispatch<React.SetStateAction<number>>;
}

const ArrivalButtons: React.FC<ArrivalButtonsProps> = ({
  guestId,
  updateGuestArrival,
  guestArrivalLoading,
  guestArrivalError,
  totalArrival,
  setTotalArrival,
}) => {
  const guestNotArrived = (): void => {
    updateGuestArrival && updateGuestArrival(guestId, 'notarrived');
    setTotalArrival(1);
  };
  return (
    <>
      <Button
        icon={<FaCheckCircle />}
        handleClick={
          updateGuestArrival
            ? () => updateGuestArrival(guestId, 'arrived', totalArrival)
            : ''
        }
        color='white'
        bgColor='arrived'
        disabled={guestArrivalLoading}
      />
      <Button
        icon={<FaTimesCircle />}
        handleClick={updateGuestArrival ? () => guestNotArrived() : ''}
        color='white'
        bgColor='error'
        disabled={guestArrivalLoading}
      />
    </>
  );
};

export default ArrivalButtons;
