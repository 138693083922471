import { useEffect, useState } from 'react';
import {
  Wedding,
  WeddingErrors,
  WeddingErrorsSetters,
  WeddingSetters,
} from '../ts/Wedding';

export function useWeddingInfo(
  currentWedding?: Wedding
): [Wedding, WeddingSetters] {
  const [couple, setCouple] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [numberOfGuests, setNumberOfGuests] = useState<number>(null!);
  const [committedToVenue, setCommittedToVenue] = useState<number>(null!);
  const [reservedSeats, setReservedSeats] = useState<number>(null!);
  const [date, setDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [remarks, setRemarks] = useState<string>(' ');
  const [userId, setUserId] = useState<number>(null!);

  const wedding: Wedding = {
    couple,
    location,
    numberOfGuests,
    committedToVenue,
    reservedSeats,
    date,
    startTime,
    remarks,
    userId,
  };

  const weddingSetters = {
    setCouple,
    setLocation,
    setNumberOfGuests,
    setCommittedToVenue,
    setReservedSeats,
    setDate,
    setStartTime,
    setRemarks,
    setUserId,
  };

  useEffect(() => {
    if (currentWedding) {
      setCouple(currentWedding?.couple);
      setLocation(currentWedding?.location);
      setNumberOfGuests(currentWedding?.numberOfGuests);
      setCommittedToVenue(currentWedding?.committedToVenue);
      setReservedSeats(currentWedding?.reservedSeats);
      setDate(currentWedding?.date);
      setStartTime(currentWedding?.startTime);
      setRemarks(currentWedding?.remarks);
      setUserId(currentWedding?.userId);
    }
  }, [currentWedding]);
  return [wedding, weddingSetters];
}

export function useWeddingErrors(): [WeddingErrors, WeddingErrorsSetters] {
  const [coupleError, setCoupleError] = useState<string>('');
  const [locationError, setLocationError] = useState<string>('');
  const [numberOfGuestsError, setNumberOfGuestsError] = useState<string>('');
  const [committedToVenueError, setCommittedToVenueError] = useState<string>(
    ''
  );
  const [reservedSeatsError, setReservedSeatsError] = useState<string>('');
  const [dateError, setDateError] = useState<string>('');
  const [startTimeError, setStartTimeError] = useState<string>('');
  const [userIdError, setUserIdError] = useState<string>('');

  const weddingErrors = [
    coupleError,
    locationError,
    numberOfGuestsError,
    committedToVenueError,
    reservedSeatsError,
    dateError,
    startTimeError,
    userIdError,
  ];

  const weddingErrorsSetters = {
    setCoupleError,
    setLocationError,
    setNumberOfGuestsError,
    setCommittedToVenueError,
    setReservedSeatsError,
    setDateError,
    setStartTimeError,
    setUserIdError,
  };

  return [weddingErrors, weddingErrorsSetters];
}
