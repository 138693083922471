import React from 'react';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import styled from 'styled-components';

interface ManageAccessProps {
  isManagingPermissions: boolean;
  setIsManagingPermissions: (value: React.SetStateAction<boolean>) => void;
}

const ManageAccess: React.FC<ManageAccessProps> = ({
  isManagingPermissions,
  setIsManagingPermissions,
}) => {
  return (
    <StyledText
      onClick={() => setIsManagingPermissions(!isManagingPermissions)}
    >
      ניהול גישה
      {isManagingPermissions ? (
        <FaArrowAltCircleUp />
      ) : (
        <FaArrowAltCircleDown />
      )}
    </StyledText>
  );
};

const StyledText = styled.h3`
  cursor: pointer;
  width: 100%;
  svg {
    margin-right: 0.3rem;
    font-size: 0.9rem;
  }
`;
export default ManageAccess;
