import React from 'react';
import { Route, Switch } from 'react-router';
import useAuthorizedRoles from '../shared/hooks/useAuthorizedRoles';
import { UserRole } from '../shared/ts/User';
import Wedding from './Wedding';

const Couple: React.FC = () => {
  useAuthorizedRoles([UserRole.ADMIN, UserRole.COUPLE]);
  return (
    <Switch>
      <Route
        path='/couple/wedding/:weddingId/number=:number?/name=:name?/guest=:guest?/relation=:relation?/page=:page'
        component={Wedding}
      />
    </Switch>
  );
};

export default Couple;
